import {
  ProfessionalBasicDataFragment,
  ProfessionalsByEmailDocument,
  ProfessionalsByEmailQuery,
  ProfessionalsByEmailQueryVariables,
  SearchProfessionalDataFragment,
  SearchProfessionalsDocument,
  SearchProfessionalsQuery,
  SearchProfessionalsQueryVariables,
} from '../generated/graphql';

import { useSearch } from './useSearch';
import { validateEmail } from '../utils';
import { useCallback, useMemo } from 'react';
import { useProfile } from '../contexts/profile.context';

function filterByActiveLicense(p: ProfessionalBasicDataFragment): boolean {
  if ('activeLicense' in p) {
    return !!p.activeLicense;
  }
  return true;
}

export const useSearchProfessionals = (
  toExclude?: Pick<ProfessionalBasicDataFragment, 'id'>[],
  extraVars?: Omit<SearchProfessionalsQueryVariables, 'name'>,
  onlyActiveLicenses = true,
) => {
  const { actingAs } = useProfile();
  const opts: any = useMemo(() => ({
    searchDocument: SearchProfessionalsDocument,
    dataKey: 'professionals',
    toExclude: [
      ...(toExclude || []),
      { id: actingAs?.id } as Pick<ProfessionalBasicDataFragment, 'id'>,
    ].filter(Boolean),
    paginated: true,
    variables: extraVars,
  }), [extraVars, toExclude, actingAs]);
  const searchResult = useSearch<SearchProfessionalDataFragment, SearchProfessionalsQuery, SearchProfessionalsQueryVariables>(opts);

  if (onlyActiveLicenses) {
    searchResult.options = searchResult.options.filter(filterByActiveLicense);
  }

  return searchResult;
}

export const useSearchProfessionalsByEmail = (onlyActiveLicenses = true) => {
  const canSubmit = useCallback(v => validateEmail(v) ? v : false, []);
  const opts: any = useMemo(() => ({
    searchDocument: ProfessionalsByEmailDocument,
    dataKey: 'professionalsByEmail',
    searchKey: 'email',
    canSubmit,
  }), [canSubmit]);
  const searchResult = useSearch<ProfessionalBasicDataFragment, ProfessionalsByEmailQuery, ProfessionalsByEmailQueryVariables>(opts);

  if (onlyActiveLicenses) {
    searchResult.options = searchResult.options.filter(filterByActiveLicense);
  }

  return searchResult;
}
