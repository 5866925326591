import { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { Theme, Typography } from '@mui/material';

const whiteB = 'rgba(255,255,255,0.5)';

const useStyles = makeStyles((theme: Theme) => ({
  '@keyframes progress': {
    '0%': {
      backgroundPosition: '0 0',
    },
    '100%': {
      backgroundPosition: '-70px 0',
    },
  },
  dragOverlay: {
    animation: '$progress 2s linear infinite !important',
    // eslint-disable-next-line max-len
    backgroundImage: `repeating-linear-gradient(-45deg, ${whiteB}, ${whiteB} 25px, ${theme.palette.divider} 25px, ${theme.palette.divider} 50px)`,
    backgroundSize: '150% 100%',
    backgroundColor: whiteB,
    borderColor: theme.palette.grey.A200,
    borderWidth: 4,
    borderStyle: 'dashed',
    position: 'absolute',
    left: 0,
    bottom: 0,
    right: 0,
    top: 0,
    zIndex: 9,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dragOverlayText: {
    textAlign: 'center',
    padding: theme.spacing(3),
  },
}));

export const DragOverlay: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.dragOverlay}>
      <Typography variant="h5" className={classes.dragOverlayText}>
        {t('drop-here')}
      </Typography>
    </div>
  )
}
