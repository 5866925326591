import { FC, ReactNode } from 'react';
import { Box, Grid, Typography } from '@mui/material';

export interface PageHeaderProps {
  title: string | ReactNode;
  subtitle?: string;
}

export const PageHeader: FC<PageHeaderProps> = ({ children, title, subtitle }) => {

  const titleNode = typeof title === 'string' ? (
    <Box display="flex" alignItems="center" justifyContent="flex-start" height="100%">
      <Typography variant="h4">
        {title}
        {subtitle && (
          <Typography variant="body2">
            {subtitle}
          </Typography>
        )}
      </Typography>
    </Box>
  ) : title;

  return (
    <Box pb={2}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          {titleNode}
        </Grid>
        <Grid item>
          {children}
        </Grid>
      </Grid>
    </Box>
  )
}
