import { useQuery } from '@apollo/client';
import {
  PatientProfessionalDocument as Document,
  PatientProfessionalQuery as Query,
  PatientProfessionalQueryVariables as QueryVariables,
  PatientProfessionalBetweenQuery as PPBetweenQuery,
  PatientProfessionalBetweenQueryVariables as PPBetweenQueryVariables,
  PatientProfessionalBetweenDocument as PPBetweenDocument,
} from '../../generated/graphql';
import { useMemo } from 'react';
import { useProfile } from '../../contexts';

export const usePatientProfessional = (id: string) => {
  const { data, ...rest } = useQuery<Query,QueryVariables>(Document, {
    variables: {
      id,
    },
    skip: !id,
    errorPolicy: 'ignore',
    fetchPolicy: 'cache-and-network',
  });

  return useMemo(() => ({
    patientProfessional: data?.patientProfessional,
    ...rest,
  }), [
    data,
    rest,
  ]);
}


export const usePatientProfessionalWithPatient = (patientId: string) => {
  const { actingAs } = useProfile();
  const { data, ...rest } = useQuery<PPBetweenQuery, PPBetweenQueryVariables>(PPBetweenDocument, {
    variables: {
      patientId,
      medicId: actingAs?.id || '',
    },
    skip: !actingAs?.id || !patientId,
    errorPolicy: 'ignore',
    fetchPolicy: 'cache-and-network',
  });

  return useMemo(() => ({
    patientProfessional: data?.patientProfessionalBetween,
    ...rest,
  }), [
    data,
    rest,
  ]);
}
