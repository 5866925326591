import { capitalize, parseCf } from '@hu-care/react-utils';
import { TFunction } from 'i18next';
import {
  AdministratorBasicDataFragment,
  AssistantBasicDataFragment, Maybe,
  MedicBasicDataFragment,
  MedicType, NetworkBasicDataFragment,
  OperatorBasicDataFragment,
  OperatorProfession, OrganizationDataFragment,
  OrganizationSaBasicDataFragment,
  PatientDataFragment,
  ProfessionalBasicDataFragment,
  ProfessionalDataFragment,
  ProfessionalRole, SearchProfessionalDataFragment,
} from '../generated/graphql';

import { Person } from '@mui/icons-material';
import { OrganizationIcon, DoctorIcon, PatientIcon, AssistantIcon, NurseIcon } from './icons';
import { getRoleColor } from '../themes';
import { arrayFromEnum } from './enum';

export const getDoctorPrefix = (taxId: string, t: TFunction) => {
  const parsedCf = parseCf(taxId);
  const isFemale = parsedCf?.gender?.toLowerCase() === 'f';

  return isFemale
    ? t('professional:doctor-she')
    : t('professional:doctor-he')
}

export type BaseProfessionalData = Pick<ProfessionalBasicDataFragment, '__typename' | 'id' | 'user'> & {
  fullName?: Maybe<string>;
  name?: string;
  surname?: string;
};

export const getProfessionalPrefix = (professional: Pick<BaseProfessionalData, 'user' | '__typename'>, t: TFunction, addSpace = true) => {
  if (professional?.__typename === 'Medic') {
    return professional.user?.traits.taxId
      ? `${getDoctorPrefix(professional.user.traits.taxId, t)}${addSpace ? ' ' : ''}`
      // If no taxId we consider as a male, there is no "parità dei sessi" here
      : `${t('professional:doctor-he')}${addSpace ? ' ' : ''}`;
  } else {
    return '';
  }
}

export const getProfessionalFullName = (professional: Pick<BaseProfessionalData, 'fullName' | 'name' | 'surname' | '__typename'>, t: TFunction) => {
  const fullName = professional.fullName || `${professional.name || ''} ${professional.surname || ''}`;
  return `${getProfessionalPrefix(professional, t)}${fullName}`;
}

export const getPatientFullName = (patient: Pick<BaseProfessionalData, 'name' | 'surname'>) => {
  return capitalize(`${patient.surname} ${patient.name}`);
}

export const getProfileIcon = (profile: { __typename?: string }) => {
  return getRoleIcon(profile.__typename)
}

export const getRoleIcon = (role?: string) => {
  switch (role) {
    case ProfessionalRole.Medic: return DoctorIcon;
    case ProfessionalRole.Assistant: return AssistantIcon;
    case ProfessionalRole.OrganizationSa: return OrganizationIcon;
    case ProfessionalRole.Operator: return NurseIcon;
    case 'PatientUser': return PatientIcon;
    default: return Person;
  }
}

export const getProfileColor = (profile: { __typename?: string }) => {
  switch (profile?.__typename) {
    case ProfessionalRole.Medic:
    case ProfessionalRole.Assistant:
    case ProfessionalRole.Operator:
    case ProfessionalRole.OrganizationSa: return getRoleColor(profile.__typename).main;
    case 'PatientUser': return '#D99EF7';
    default: return 'lightgrey';
  }
}

export function isMedic(profile: { __typename?: string } | null): profile is MedicBasicDataFragment {
  return profile?.__typename === ProfessionalRole.Medic;
}

export function isAssistant(profile: { __typename?: string } | null): profile is AssistantBasicDataFragment {
  return profile?.__typename === ProfessionalRole.Assistant;
}

export function isOperator(profile: { __typename?: string } | null): profile is OperatorBasicDataFragment {
  return profile?.__typename === ProfessionalRole.Operator;
}

export function isAdministrator(profile: { __typename?: string } | null): profile is AdministratorBasicDataFragment {
  return profile?.__typename === ProfessionalRole.Administrator;
}

export function isPatient(profile: { __typename?: string } | null): profile is PatientDataFragment {
  return profile?.__typename === 'Patient';
}

export function isOrganizationSa(profile: { __typename?: string } | null): profile is OrganizationSaBasicDataFragment {
  return profile?.__typename === ProfessionalRole.OrganizationSa;
}

export function isOrganization(data: { __typename?: string } | null): data is OrganizationDataFragment {
  return data?.__typename === 'Organization';
}

export enum FreelanceType {
  freelancer = 'freelancer',
}

// Object use to order entities
export const orderScore = {
  [ProfessionalRole.Medic as string]: 1,
  [ProfessionalRole.Operator as string]: 2,
  [ProfessionalRole.Assistant as string]: 3,
  [ProfessionalRole.OrganizationSa as string]: 4,
  [ProfessionalRole.Administrator as string]: 5,
};

export const getOrderScore: (arg0: string | undefined) => number = (typename) => {
  if (!typename) {
    return 0;
  }
  return orderScore[typename] || 0;
};

export type SupportedProfessionalTypes = MedicType | OperatorProfession | FreelanceType;

export const getProfessionalType = (professional: ProfessionalBasicDataFragment) => {
  if (isMedic(professional)) {
    if (professional.type) {
      return professional.type; // MedicType.Mmg or MedicType.Pls
    } else if (professional.freeLancer) {
      return FreelanceType.freelancer;
    }
  } else if (isOperator(professional)) {
    return professional.profession;
  }
}

const operatorProfessionsArray = arrayFromEnum(OperatorProfession);

export const getTranslationLabel = (type: string) => {
  switch (type) {
    case MedicType.Mmg: return 'professional:medic-type-mmg';
    case MedicType.Pls: return 'professional:medic-type-pls';
    case FreelanceType.freelancer: return 'professional:medic-type-freelancer';
  }
  if (operatorProfessionsArray.includes(type)) {
    return `professional:operator-types.${type.toLowerCase()}`;
  }
}

export const getProfessionalLabel = (professional: ProfessionalBasicDataFragment) => {
  const type = getProfessionalType(professional);
  const defaultLabel = `professional:${professional.__typename}`;

  if (isOrganizationSa(professional)) {
    return professional.name;
  }
  return type
    ? getTranslationLabel(type) || defaultLabel
    : defaultLabel;
}

export const CREATE_PROFILE_TYPES = [
  MedicType.Mmg,
  MedicType.Pls,
  FreelanceType.freelancer,
  ...arrayFromEnum(OperatorProfession),
].map(value => ({
  value,
  label: getTranslationLabel(value),
}));

export const professionalHasCompletedProfile = (professional: ProfessionalDataFragment) => {
  if (isMedic(professional)) {
    const specializationsOk = true; // professional.freeLancer ? true : professional.specializations.length > 0;

    return specializationsOk
      && !!professional.registerNumber
      && !!professional.registerDistrictId
  }
  return true;
}

export function shouldCompleteProfile(professional: ProfessionalDataFragment) {
  // Check basic profile info
  if (!professionalHasCompletedProfile(professional)) return false;
  if (!isAssistant(professional) && !isAdministrator(professional)) {
    // If medic or operator, check provisions
    if ((isMedic(professional) || isOperator(professional)) && professional.provisions.length === 0) {
      return true;
    }
    // If not assistant, check clinics
    return professional.clinics.length === 0;
  }
  // All ok
  return false;
}

export function getProfessionalRole(profile: ProfessionalBasicDataFragment, t: TFunction) {
  let role: string | undefined;
  if (isMedic(profile) && profile.type) {
    role = profile.type.toUpperCase();
  }
  if (isOperator(profile) && profile.profession) {
    const transLabel = getTranslationLabel(profile.profession);
    role = transLabel && t<string>(transLabel);
  }
  return role || t(`professional:${profile.role}`);
}

export function getProfessionalSpecializationWithCount(profile: SearchProfessionalDataFragment) {
  if (!('specializations' in profile) || profile.specializations.length === 0) return null;
  const firstSpec = profile.specializations[0];
  const count = profile.specializations.length - 1;
  return count > 0 ? `${firstSpec.name} +${count}` : firstSpec.name;
}

export function canChat(profile?: Pick<ProfessionalBasicDataFragment, '__typename' | 'user'> | null): boolean {
  if (!profile) return false;

  const hasUser = Boolean(profile?.user?.id);
  if (isMedic(profile) || isOperator(profile)) {
    return hasUser && profile.availability?.chat;
  }
  return hasUser;
}

export function currentProfessionalCanChat(
  currentP?: Pick<ProfessionalBasicDataFragment, '__typename' | 'user'> | null,
  p?: Pick<ProfessionalBasicDataFragment, '__typename' | 'user'> | null,
): boolean {
  return canChat(currentP) && canChat(p);
}

export function currentProfessionalCanChatLabel(
  currentP: Pick<ProfessionalBasicDataFragment, '__typename' | 'user'> | null | undefined,
  p: Pick<ProfessionalBasicDataFragment, '__typename' | 'user' | 'role'> | null | undefined,
  t: TFunction,
): string {
  if (!canChat(currentP)) {
    return t('chat-disabled');
  }
  if (!canChat(p)) {
    return t('action-temporarily-disabled', {
      by: p ? t(`by_the_${p.role.toLowerCase()}`) : '',
    });
  }
  return t('menu:chat');
}

export function isOrgSaAdministrator(
  orgSa: { __typename?: string; id: string } | null,
  admin: { __typename?: string; id: string; networks: NetworkBasicDataFragment[] } | null,
) {
  if (!isOrganizationSa(orgSa)) return false;
  if (!isAdministrator(admin)) return false;

  return admin.networks.some(n => n.inverse.id === orgSa.id && n.canImpersonate > 0);
}
