import { FC, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Box, Button, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { validateEmail } from '../../../utils';
import { ControlledTextField, SubmitButton } from '../form';

export interface InviteUserForm {
  email: string;
  // name?: string;
  // surname?: string;
}

export interface InviteUserProps {
  onConfirm: (user: InviteUserForm) => void;
  suggestedEmail?: string;
  onCancel: () => void;
  loading: boolean;
}

export const InviteUser: FC<InviteUserProps> = ({ onConfirm, suggestedEmail, onCancel, loading }) => {
  const { t } = useTranslation();
  const { control, handleSubmit, setValue } = useForm<InviteUserForm>({
    defaultValues: {
      email: suggestedEmail,
    },
  });

  useEffect(() => {
    if (suggestedEmail) {
      setValue('email', suggestedEmail);
    }
  }, [suggestedEmail, setValue]);

  return (
    <form onSubmit={handleSubmit(onConfirm)} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography>{t('invite:user-info')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name="email"
            type="email"
            label={t('email')}
            required
            rules={{
              validate: validateEmail,
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="space-between" pt={1}>
            <Button
              type="button"
              variant="outlined"
              onClick={onCancel}
            >
              {t('cancel')}
            </Button>
            <SubmitButton loading={loading}>
              {t('invite:send-request')}
            </SubmitButton>
          </Box>
        </Grid>
      </Grid>
    </form>
  )
}
