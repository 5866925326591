import { memo, useCallback, useEffect, useRef } from 'react';
import clsx from 'clsx';

import { createStyles, makeStyles } from '@mui/styles';
import { Layout as HuCareLayout, NavBarProps, useLayout } from '@hu-care/react-layout';

import { NavBar } from './nav-bar';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useMeasure } from 'react-use';
import { useSearchParams } from 'react-router-dom';
import { useChat } from '@hu-care/chat-client/dist/react';
import { useChatPopup } from '../../../contexts';

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      flexDirection: 'column',
      height: '100%',
    },
    content: {
      zIndex: 0,
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    container: {
      padding: 0,
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'auto',
    },
  }),
);

const landscapeToolbarKey = '@media (min-width:0px) and (orientation: landscape)';
const desktopToolbarKey = '@media (min-width:600px)';

export const Layout = memo(({ children }) => {
  const classes = useStyles();
  const [search] = useSearchParams();
  const { ready } = useChat();
  const { open } = useChatPopup();

  const action = search.get('action');

  useEffect(() => {
    if (ready && open && action) {
      const [_action, dest] = action.split('|');
      if (_action === 'chat') {
        open({ id: dest });
      }
    }
  }, [action, open, ready]);

  // Track the navbar height, its height can vary because the impersonate bar is not always present
  // We need to add a dynamic "spacer" element to avoid the bar going on top of the content
  const [navBarRef, { height }] = useMeasure<HTMLDivElement>();
  const spacerRef = useRef<HTMLDivElement>(null);

  const navBar = useCallback((props: NavBarProps) => (
    <NavBar {...props} ref={navBarRef}/>
  ), [navBarRef]);

  const { containerProps } = useLayout<{ containerProps?: any }>();
  const theme = useTheme();
  // Calculate the current Toolbar height
  // HucareLayout renders a default Toolbar spacer, we have our spacer
  // we need to subtract the current toolbar height from our spacer
  const toolbarMixin: any = theme.mixins.toolbar;
  const defaultMinHeight = toolbarMixin.minHeight;
  const isLandscape = useMediaQuery(landscapeToolbarKey);
  const isDesktop = useMediaQuery(desktopToolbarKey);

  const toolbarMinHeight = isDesktop
    ? toolbarMixin[desktopToolbarKey].minHeight
    : isLandscape ? toolbarMixin[landscapeToolbarKey].minHeight
      : defaultMinHeight;

  useEffect(() => {
    if (spacerRef.current) {
      spacerRef.current.style.marginBottom = `${height - toolbarMinHeight}px`;
    }
  }, [height, toolbarMinHeight]);

  return (
    <HuCareLayout
      showEnvBar={false}
      navBar={navBar}
      mainClassName={classes.content}
      rootClassName={classes.root}
      maxWidth="xl"
      {...containerProps}
      className={clsx(classes.container, containerProps?.className)}
    >
      <div ref={spacerRef}/>
      {children}
      <Box pt={10}/>
    </HuCareLayout>
  );
});
