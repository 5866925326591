import { FC, useCallback } from 'react';
import { InviteDataFragment, InviteType, ProfessionalDataFragment } from '../../../generated/graphql';
import { Box, Button } from '@mui/material';
import { getPrimary } from './pending-invites-list';
import { useTranslation } from 'react-i18next';
import { CheckCircleOutline } from '@mui/icons-material';
import { useAcceptAssistantInvite } from '../../../hooks/invite/useAcceptAssistantInvite';
import { useRedirect } from '../../../hooks/useRedirect';
import { useProfile } from '../../../contexts/profile.context';
import { RoutesKeys } from '../../../routes';
import { useAcceptOrganizationInvite } from '../../../hooks/invite/useAcceptOrganizationInvite';

export const SinglePendingInvite: FC<{
  invite: InviteDataFragment;
  orgAcceptableProfile: ProfessionalDataFragment;
}> = ({ invite, orgAcceptableProfile }) => {
  const { t } = useTranslation();
  const { acceptInvite: acceptAssistantInvite, loading: aLoading } = useAcceptAssistantInvite();
  const { acceptInvite: acceptOrgInvite, loading: oLoading } = useAcceptOrganizationInvite();
  const redirect = useRedirect();
  const { setSelectedProfile } = useProfile();

  const acceptInvite = useCallback((invite: InviteDataFragment) => {
    if (invite.type === InviteType.Assistant) {
      return acceptAssistantInvite(invite.id);
    }
    return acceptOrgInvite(invite.id, orgAcceptableProfile.id);
  }, [acceptAssistantInvite, acceptOrgInvite, orgAcceptableProfile]);

  const onClick = useCallback(() => {
    acceptInvite(invite)
      .then(profile => {
        if (profile) {
          setSelectedProfile(profile as any);
          setTimeout(() => redirect(RoutesKeys.profiles))
        }
      });
  }, [acceptInvite, redirect, invite, setSelectedProfile]);

  return (
    <Box p={2}>
      <div style={{ fontSize: 20 }} dangerouslySetInnerHTML={{ __html: getPrimary(invite, t) }} />
      <Box pt={2}>
        <Button
          onClick={onClick}
          disabled={aLoading || oLoading || !!invite.invitedUserId}
          startIcon={<CheckCircleOutline/>}
          color="primary"
          variant="contained"
        >
          {t('accept')}
        </Button>
      </Box>
    </Box>
  )
}
