import { useMutation } from '@apollo/client';
import { DeleteRequestDocument, DeleteRequestMutation, DeleteRequestMutationVariables } from '../../generated/graphql';
import { useCallback, useMemo } from 'react';
import { useToast } from '@hu-care/react-ui-store';
import { useTranslation } from 'react-i18next';
import { useHandleError } from '../useHandleError';

export const useDeleteRequest = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const handleError = useHandleError(true);

  const [
    deleteMutation,
    rest,
  ] = useMutation<DeleteRequestMutation, DeleteRequestMutationVariables>(DeleteRequestDocument, {
    update: (cache, mutationResult) => {
      if (!mutationResult.data?.deleteRequest) {
        return;
      }
      cache.evict({
        id: cache.identify({ __typename: 'Request', id: mutationResult.data.deleteRequest }),
        broadcast: true,
      });
      cache.gc();
    },
  });

  const deleteRequest = useCallback(async (id: string) => {
    try {
      const result = await deleteMutation({
        variables: {
          id,
        },
      });
      toast.success(t('request:delete-request-success'));

      return result.data?.deleteRequest;
    } catch (e) {
      handleError(e, 'errors:cannot-delete-request');
    }
  }, [deleteMutation, handleError, t, toast]);

  return useMemo(() => ({
    deleteRequest,
    ...rest,
  }), [
    deleteRequest,
    rest,
  ])
}
