import {
  PatientProfessionalDataFragment,
  UpdatablePatientProfessionalStatus,
  UpdatePatientProfessionalStatusDocument,
  UpdatePatientProfessionalStatusMutation,
  UpdatePatientProfessionalStatusMutationVariables,
} from '../../generated/graphql';
import { useProfile } from '../../contexts/profile.context';
import { useMutation } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { useHandleError } from '../useHandleError';
import { useToast } from '@hu-care/react-ui-store';
import { useTranslation } from 'react-i18next';

export const useUpdatePatientProfessionalStatus = (patientProfessional: PatientProfessionalDataFragment) => {
  const { actingAs } = useProfile();
  const handleError = useHandleError();
  const toast = useToast();
  const { t } = useTranslation();

  const [
    updateMutation,
    { loading },
  ] = useMutation<
    UpdatePatientProfessionalStatusMutation,
    UpdatePatientProfessionalStatusMutationVariables
  >(UpdatePatientProfessionalStatusDocument, {
    update: (cache, mutationResult) => {
      if (!mutationResult.data?.updatePatientProfessionalStatus) {
        return;
      }
      cache.modify({
        id: cache.identify(mutationResult.data.updatePatientProfessionalStatus),
        fields: {
          status: value => {
            return mutationResult.data?.updatePatientProfessionalStatus.status || value;
          },
        },
      });
    },
  });

  const onUpdateStatus = useCallback(async (status: UpdatablePatientProfessionalStatus) => {
    if (!actingAs) return;
    const action = status === UpdatablePatientProfessionalStatus.Approved ? 'approve' : 'deny';
    try {
      const result = await updateMutation({
        variables: {
          id: patientProfessional.id,
          medicId: actingAs.id,
          status,
        },
      });
      toast.success(t(`patient:status-${action}-success`));
      return result.data?.updatePatientProfessionalStatus;
    } catch (e) {
      handleError(e, `errors:cannot-${action}-patient-professional`);
    }
  }, [updateMutation, patientProfessional, actingAs, t, toast, handleError]);

  return useMemo(() => ({
    onUpdateStatus,
    loading,
  }), [
    onUpdateStatus,
    loading,
  ]);
}
