import { useMutation } from '@apollo/client';
import {
  ClinicDataFragment,
  ReportBasicDataFragment,
  UpdateReportDocument, UpdateReportInput,
  UpdateReportMutation,
  UpdateReportMutationVariables,
} from '../../generated/graphql';
import { useFieldArray, useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo } from 'react';
import { useHandleError } from '../useHandleError';
import { useTranslation } from 'react-i18next';
import { useToast } from '@hu-care/react-ui-store';
import { UploadDestinations } from '../../utils/constants';

export interface UpdateReportForm {
  title: string;
  description: string;
  reportDate: Date;
  clinicId: string;
  files: { file: File }[];
}

export const useUpdateReport = (report?: ReportBasicDataFragment) => {
  const handleError = useHandleError();
  const { t } = useTranslation();
  const toast = useToast();
  const [
    updateMutation,
    { loading },
  ] = useMutation<UpdateReportMutation, UpdateReportMutationVariables>(UpdateReportDocument);

  const form = useForm<UpdateReportForm>({
    defaultValues: {
      title: report?.title || '',
      description: report?.description || '',
      reportDate: report?.reportDate || new Date(),
      clinicId: report?.meta?.clinic?.id || '',
    },
  });

  const reset = form.reset;

  const files = useFieldArray({
    control: form.control,
    name: 'files',
  });

  useEffect(() => {
    if (report) {
      reset({
        title: report.title,
        description: report.description || '',
        reportDate: report.reportDate,
        clinicId: report.meta?.clinic?.id || '',
      });
    }
  }, [report, reset]);

  const onSubmit = useCallback(async (data: Omit<UpdateReportForm, 'clinicId'> & { meta?: { clinic: Omit<ClinicDataFragment, 'rooms'> }  }) => {
    if (!report) {
      return;
    }
    try {
      const payload: UpdateReportInput = {
        title: data.title,
        description: data.description,
        attachments: files.fields.length ? files.fields.map(({ file }) => ({
          file,
          destination: UploadDestinations.Reports,
        })) : undefined,
        ...data.meta && {
          meta: data.meta,
        },
      };
      const result = await updateMutation({
        variables: {
          id: report.id,
          report: payload,
        },
      });
      toast.success(t('report:update-report-success'));
      return result.data?.updateReportAsProfessional;
    } catch (e) {
      handleError(e, 'errors:cannot-update-report');
    }
  }, [updateMutation, handleError, t, toast, files, report]);

  return useMemo(() => ({
    onSubmit,
    ...form,
    loading,
    files,
  }), [
    onSubmit,
    form,
    loading,
    files,
  ]);
}
