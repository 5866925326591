import { memo } from 'react';
import { Box, BoxProps, Container, ContainerProps } from '@mui/material';

export interface PageLayoutProps extends Omit<BoxProps, 'maxWidth'> {
  maxWidth?: ContainerProps['maxWidth'];
}

export const PageLayout = memo<PageLayoutProps>(({ children, maxWidth = 'lg', ...props }) => {
  return (
    <Box py={2} {...props}>
      <Container maxWidth={maxWidth}>
        <>
          {children}
        </>
      </Container>
    </Box>
  )
});
