import { Avatar, AvatarProps, Theme } from '@mui/material';
import { FC, useMemo } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { makeStyles, createStyles } from '@mui/styles';


export type UserAvatarProps = AvatarProps & {
  picture?: string;
  name?: string;
  surname?: string;
  border?: boolean;
  size?: 'small' | 'medium' | 'large'
  insideDashboard?: boolean;
}

const useStyles = makeStyles((theme: Theme) => createStyles({
  avatarSmall: ({ border }: { border: boolean }) => ({
    height: 35,
    width: 35,
    fontSize: '15px !important',
    border: border ? '1.5px solid white' : 'none',
  }),
  avatarMedium: ({ border }: { border: boolean }) => ({
    border: border ? '1.5px solid white' : 'none',
  }),
  avatarLarge: ({ border }: { border: boolean }) => ({
    fontSize: '21px !important',
    height: 55,
    width: 55,
    border: border ? '1.5px solid white' : 'none',
  }),
}))

export const UserAvatar: FC<UserAvatarProps> = ({ picture, name, surname, border = false, size = 'medium', insideDashboard, ...props }) => {
  const classes = useStyles({ border });

  const sxClass = useMemo(() => {
    switch(size) {
      case 'small':
        return classes.avatarSmall;
      case 'medium':
        return classes.avatarMedium;
      case 'large':
        return classes.avatarLarge;
    }
  }, [classes, size])

  const initials = useMemo(() => {
    if (name && surname) {
      return `${name.charAt(0)}${surname.charAt(0)}`;
    } else if (name) {
      const nameAsArray = name.split(' ');
      return nameAsArray.length > 1
        ? `${nameAsArray[0].charAt(0)}${nameAsArray[nameAsArray.length - 1].charAt(0)}`
        : `${nameAsArray[0].charAt(0)}`
    }
  },[name, surname]);

  return(
    <>
    {
      initials
      ? <Avatar
        src={insideDashboard ? undefined : picture}
        className={sxClass}
        sx={{ bgcolor: getGoodColor(`${name} ${surname}`) }}
        {...props}
      >
        {initials}
      </Avatar>
      : <Avatar
        className={sxClass}
        {...props}
      >
        <AccountCircleIcon fontSize={size === 'large' ? 'large' : 'medium'} />
      </Avatar>
    }
    </>
  )
}

function getGoodColor(str: string) {
  // Colore di riferimento
  const refColor = [241, 184, 15];
  // Sfumature da evitare
  const avoidTints = [
    [255, 192, 203], // rosa chiaro
    [255, 240, 245], // rosa pastello
    [176, 224, 230], // azzurro polvere
    [135, 206, 235], // azzurro cielo
    [70, 130, 180], // azzurro acciaio
  ];
  // Convertiamo la stringa in un numero intero
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  // Generiamo un colore basato sul numero intero
  const color = [(hash & 0xFF0000) >> 16, (hash & 0x00FF00) >> 8, hash & 0x0000FF];
  // Calcoliamo la distanza dal colore di riferimento
  const distance = Math.sqrt(
    color.reduce((acc, c, i) => acc + (c - refColor[i]) ** 2, 0),
  );
  // Verifichiamo che il colore non sia una sfumatura da evitare
  const isGoodColor = avoidTints.every(
    tint => Math.sqrt(
      color.reduce((acc, c, i) => acc + (c - tint[i]) ** 2, 0),
    ) > 50,
  );
  // Se il colore è buono, lo restituiamo; altrimenti, lo scuriamo finché non lo diventa
  if (distance < 200 && isGoodColor) {
    //const contrastColor = color.reduce((acc, c) => acc + c, 0) / 3 > 128 ? [0, 0, 0] : [255, 255, 255];
    return `rgb(${color.join(',')})`;
  } else {
    const darkColor = color.map(c => Math.max(0, c - 50));
    //const contrastColor = darkColor.reduce((acc, c) => acc + c, 0) / 3 > 128 ? [0, 0, 0] : [255, 255, 255];
    return `rgb(${darkColor.join(',')})`;
  }
}