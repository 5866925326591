import {
  ClonedPatientProfessionalOwnerDocument,
  ClonedPatientProfessionalOwnerQuery,
  ClonedPatientProfessionalOwnerQueryVariables,
  PatientProfessional, Professional,
  SharedPatientProfessionalOwnerDocument,
  SharedPatientProfessionalOwnerQuery,
  SharedPatientProfessionalOwnerQueryVariables,
} from '../../generated/graphql';
import { useLazyQuery } from '@apollo/client';
import { useCallback, useEffect } from 'react';
import { useProfile } from '../../contexts/profile.context';

export const usePatientProfessionalOwner = (
  patientProfessional?: Pick<PatientProfessional, 'id' | 'professionalId' | 'organizationProfessionalId'>,
  ownerStringWithOrganization = true,
) => {
  const { actingAs } = useProfile();
  const [sharedQuery, { loading: sharedLoading, data: sharedData, error: sharedError, called: sharedCalled }] = useLazyQuery<
    SharedPatientProfessionalOwnerQuery,
    SharedPatientProfessionalOwnerQueryVariables
  >(SharedPatientProfessionalOwnerDocument);

  const [clonedQuery, { loading: clonedLoading, data: clonedData, error: clonedError, called: clonedCalled }] = useLazyQuery<
    ClonedPatientProfessionalOwnerQuery,
    ClonedPatientProfessionalOwnerQueryVariables
  >(ClonedPatientProfessionalOwnerDocument);

  const getOwner = useCallback(() => {
    if (!actingAs || !patientProfessional) {
      return;
    }

    if (patientProfessional.professionalId && patientProfessional.professionalId !== actingAs.id) {
      sharedQuery({ variables: { professionalId: patientProfessional.professionalId } });
    } else if (patientProfessional.organizationProfessionalId) {
      clonedQuery({ variables: { id: patientProfessional.id } });
    }
  }, [clonedQuery, sharedQuery, actingAs, patientProfessional]);

  const data = clonedData || sharedData;
  const error = clonedError || sharedError;

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  const ownerString = !data
    ? null
    : 'professional' in data
      ? orgSaFullName(data.professional, ownerStringWithOrganization)
      : data.patientProfessional.organizationProfessional?.organization.name;

  return {
    data,
    ownerString,
    error,
    loading: clonedLoading || sharedLoading,
    called: clonedCalled || sharedCalled,
    getOwner,
  }
}

export const orgSaFullName = (professional: SharedPatientProfessionalOwnerQuery['professional'], ownerStringWithOrganization: Boolean) => {
  let str = professional.fullName;
  if (ownerStringWithOrganization && 'managedOrganization' in professional) {
    str += ` (${professional.managedOrganization.name})`;
  }
  return str;
}

export const isPatientProfessionalShared = (
  patientProfessional: Pick<PatientProfessional, 'professional' | 'professionalId' | 'organizationProfessionalId'>,
  currentProfessional?: Pick<Professional, 'id'> |  null,
) => {
  const ppProfessionalId = patientProfessional.professional?.id || patientProfessional.professionalId;
  return ppProfessionalId !== currentProfessional?.id || !!patientProfessional.organizationProfessionalId;
}
