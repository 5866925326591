import ReactGA from 'react-ga';

export const GA = new Proxy(ReactGA, {
  get: (target, p) => {
    if (process.env.NODE_ENV === 'production') {
      return (target as any)[p];
    }

    return (...args: any[]) => {
      //
    }
  },
});
