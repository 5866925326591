import { memo, Suspense, useCallback, useEffect, useState } from 'react';
import { Routes, useLocation, Route } from 'react-router-dom';
import { ApolloClient, ApolloProvider } from '@apollo/client';
import { Loading, LayoutProvider } from '@hu-care/react-layout';
import MainContainer from './views/pages/main.container';
import AuthCallback from './views/pages/auth-callback.page';
import { makeClient } from './apollo/client';
import { logger } from './utils/logger';
import { SharedDialog } from '@hu-care/react-ui-store';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { commonRoutes } from './routes/common.routes';
import { AlertsProvider, ProfileProvider, SkipAllError, useAuth } from './contexts';
import { GA } from './utils/ga';
import { useOfflineSnackbar } from './hooks/useOfflineSnackbar';
import { useIdleTimer } from 'react-idle-timer';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK!);

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, [pathname]);

  return null;
}

export const App = memo(() => {
  const { ready, getToken, refreshToken, token, getUser, logout } = useAuth();
  const [apollo, setApollo] = useState<ApolloClient<any> | null>(null);
  const [errored, setErrored] = useState<Error | boolean>(false);
  const { setIsOffline } = useOfflineSnackbar();

  if (errored) {
    // Big error, catch by global Error boundary, user needs to reload the page
    throw errored;
  }

  useEffect(() => {
    GA.initialize('UA-204562378-1');
  }, []);

  const user = getUser();

  useEffect(() => {
    if (user) {
      GA.set({ userId: user.sub });

      logger.addContext('user', {
        id: user.sub,
        email: user.email,
      });
    }
  }, [user]);

  const onRefreshFail = useCallback((err?: Error) => {
    if (err && err instanceof SkipAllError) {
      setErrored(err);
    } else {
      logout();
    }
  }, [logout, setErrored]);

  useEffect(() => {
    if (!ready) {
      return;
    }
    setApollo(
      makeClient({
        tokenGetter: getToken,
        refreshToken: () => refreshToken(token()?.refreshToken),
        onRefreshFail: onRefreshFail,
        onNetworkError: (err) => {
          // This works in most cases, "sometimes" it is not updated in time
          if (navigator.onLine) {
            console.error(err);
            setErrored(new Error('Internal server error'));
          } else {
            setIsOffline(true);
          }
        },
      }),
    );
  }, [ready, getToken, refreshToken, token, onRefreshFail, setErrored, setIsOffline]);

  useIdleTimer({
    onIdle: logout,
    timeout: 3_600_000, // 1 hour
  })

  if (!apollo) {
    return null;
  }

  return (
    <ApolloProvider client={apollo}>
      <Elements stripe={stripePromise}>
        <ScrollToTop />
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route path="/callback" element={<AuthCallback/>}/>
            <Route path="*" element={
              <AlertsProvider>
                <ProfileProvider>
                  <LayoutProvider routes={commonRoutes}>
                    <MainContainer />
                  </LayoutProvider>
                  <SharedDialog />
                </ProfileProvider>
              </AlertsProvider>
            }/>
          </Routes>
        </Suspense>
      </Elements>
    </ApolloProvider>
  );
});
