import { useMutation } from '@apollo/client';
import {
  RequestResult,
  ResolveRequestDocument,
  ResolveRequestMutation,
  ResolveRequestMutationVariables,
} from '../../generated/graphql';
import { useCallback, useMemo } from 'react';
import { useToast } from '@hu-care/react-ui-store';
import { useTranslation } from 'react-i18next';

import { useHandleError } from '../useHandleError';

export const useResolveRequest = (requestId: string) => {
  const toast = useToast();
  const { t } = useTranslation();

  const [
    resolveRequest,
    { loading },
  ] = useMutation<ResolveRequestMutation, ResolveRequestMutationVariables>(ResolveRequestDocument, {
    update: (cache, mutationResult) => {
      if (!mutationResult.data?.resolveRequest) {
        return null;
      }
      cache.modify({
        id: cache.identify({ __typename: 'Request', id: requestId }),
        fields: {
          result: () => {
            return mutationResult.data?.resolveRequest.result;
          },
        },
      });
    },
  });

  const handleError = useHandleError(false);

  const onResolve = useCallback(async (result?: RequestResult | '' | null, shouldShowToast = true) => {
    try {
      await resolveRequest({
        variables: {
          requestId,
          result: result || null,
        },
      });
      if (shouldShowToast) {
        toast.success(t('request:resolve-request-success'));
      }
    } catch (e) {
      handleError(e, 'errors:cannot-resolve-request');
    }
  }, [resolveRequest, handleError, requestId, t, toast])

  return useMemo(() => ({
    loading,
    onResolve,
    resolveRequest,
  }), [
    loading,
    resolveRequest,
    onResolve,
  ]);
}
