import { useCallback } from 'react';
import { useNavigate, matchPath } from 'react-router-dom';
import { allRoutes, RoutesKeys } from '../routes';

export const useRedirect = () => {
  const navigate = useNavigate();

  return useCallback((to: string, exclude: RoutesKeys[] = []) => {
    if (window.location.pathname === to) {
      return;
    }

    const excludeRoutes = allRoutes.filter(r => exclude.includes(r.key))

    for (const excludeRoute of excludeRoutes) {
      const { path, exact } = excludeRoute;

      const match = matchPath({
        path,
        end: exact,
      }, window.location.pathname);

      if (match) {
        return;
      }
    }

    navigate(to, { replace: true });
  }, [navigate]);
}
