import { PatientProfessionalDataFragment } from '../../../generated/graphql';
import { useProfile } from '../../../contexts';
import { useDialog } from '@hu-care/react-ui-store';
import { useIsMobile } from '../../useIsMobile';
import { useMemo } from 'react';
import { getEmailablePatientUsers } from '../../../utils/patients';
import { SendMail } from '../../../views/components/send-mail';
import { Box, Typography } from '@mui/material';
import { MailIcon } from '../../../utils/icons';
import { getPatientFullName } from '../../../utils/profiles';

export const useOpenSendMail = (patientProfessional: PatientProfessionalDataFragment) => {
  const { actingAs } = useProfile();
  const dialog = useDialog();
  const isMobile = useIsMobile();

  return useMemo(() => {
    const emailablePatientUsers = getEmailablePatientUsers(patientProfessional.patient);
    const patientUserIds = emailablePatientUsers?.map(u => u.id) || [];

    if (!patientUserIds.length || !actingAs) {
      return null;
    }

    return () => {
      dialog.open({
        body: (
          <SendMail
            onSend={dialog.close}
            onCancel={dialog.close}
            professional={actingAs}
            recipientIds={patientUserIds}
          />
        ),
        title: (
          <Box display="flex" alignItems="center">
            <Box mr={2} display="flex"><MailIcon /></Box>
            <Typography>{getPatientFullName(patientProfessional.patient)}</Typography>
          </Box>
        ),
        maxWidth: 'md',
        fullWidth: true,
        fullScreen: isMobile,
      })
    }
  }, [actingAs, dialog, isMobile, patientProfessional]);
}
