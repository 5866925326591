import { FC, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import { ChatBubble, Clear, Mail } from '@mui/icons-material';
import { useProfile, useChatPopup } from '../../../contexts';
import { ImpersonateSelector } from '../impersonate-selector';
import { canChat, getProfessionalFullName, getProfileColor } from '../../../utils/profiles';
import { getRoute, RoutesKeys } from '../../../routes';
import { useDialog } from '@hu-care/react-ui-store';
import { SendMail } from '../send-mail';
import { useTranslation } from 'react-i18next';
import { MailIcon } from '../../../utils/icons';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { makeSxProps } from '../../../themes/sx';

const sxProps = makeSxProps({
  root: {
    zIndex: 100,
    paddingLeft: theme => theme.spacing(1),
    background: theme => theme.palette.background.paper,
    paddingTop: theme => theme.spacing(0.5),
    paddingBottom: theme => theme.spacing(0.5),
  },
  info: {
    color: theme => theme.palette.secondary.main,
  },
  username: {
    fontWeight: theme => theme.typography.fontWeightBold,
  },
  actionIcon: {
    padding: theme => theme.spacing(1),
  },
});

export const ImpersonateBar: FC = () => {
  const {
    setImpersonatedProfile,
    availableImpersonate,
    actingAs,
    isImpersonating,
    selectedProfile,
  } = useProfile();
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const { open: openChat } = useChatPopup();
  const dialog = useDialog();

  const openMail = useCallback(() => {
    const onClose = () => dialog.close();
    if (!isImpersonating || !actingAs?.userId || !selectedProfile) {
      return;
    }
    dialog.open({
      title: (
        <Box display="flex" alignItems="center">
          <Box mr={2} display="flex"><MailIcon /></Box>
          <Typography>{getProfessionalFullName(actingAs, t)}</Typography>
        </Box>
      ),
      body: <SendMail
        onSend={onClose}
        onCancel={onClose}
        professional={selectedProfile}
        recipientIds={[actingAs.userId]}
      />,
      onClose,
      maxWidth: 'md',
      fullWidth: true,
      fullScreen: isMobile,
    });
  }, [dialog, actingAs, selectedProfile, t, isMobile, isImpersonating]);

  const stopImpersonate = useCallback(() => {
    setImpersonatedProfile(null);
    navigate(getRoute(RoutesKeys.dashboard));
  }, [setImpersonatedProfile, navigate]);

  return (isImpersonating && actingAs) ? (
    <>
      <Grid container justifyContent="space-between" alignItems="center" sx={sxProps.root}>
        <Grid item>
          <Grid container alignItems="center">
            <Grid item>
              <Box pr={1}>
                <ImpersonateSelector options={availableImpersonate} />
              </Box>
            </Grid>
            <Grid item>
              <IconButton sx={sxProps.actionIcon} onClick={openMail}>
                <Mail />
              </IconButton>
            </Grid>
            <Grid item>
              {canChat(actingAs) && actingAs.user && (
                <IconButton sx={sxProps.actionIcon} onClick={() => {
                  if (actingAs?.user) {
                    openChat(actingAs.user);
                  }
                }}>
                  <ChatBubble />
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <IconButton onClick={stopImpersonate}>
            <Clear />
          </IconButton>
        </Grid>
      </Grid>
      <Divider style={{ height: 4, opacity: 0.6, backgroundColor: getProfileColor(actingAs) }} />
    </>
  ) : null;
}
