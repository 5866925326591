import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useProfile } from '../contexts/profile.context';
import { PlatformAlertsDocument, PlatformAlertsQuery, PlatformAlertsQueryVariables } from '../generated/graphql';
import { useEffectOnce } from 'react-use';
import { isSameDay, startOfDay } from 'date-fns/esm';

export const usePlatformAlerts = () => {
  const [day, setDay] = useState(startOfDay(new Date()));
  const { actingAs } = useProfile();
  const { data, loading } = useQuery<PlatformAlertsQuery, PlatformAlertsQueryVariables>(PlatformAlertsDocument, {
    variables: {
      roles: actingAs ? [actingAs.role] : [],
      day,
    },
    skip: !actingAs,
    pollInterval: 60000,
  });

  useEffectOnce(() => {
    const int = setInterval(() => {
      setDay(oldDay => {
        const newDay = startOfDay(new Date());
        return isSameDay(oldDay, newDay) ? oldDay : newDay;
      });
    }, 3600000);

    return () => {
      clearInterval(int);
    }
  });

  return {
    loading,
    platformAlerts: data?.platformAlerts,
  }
}
