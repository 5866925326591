import { useMemo } from 'react';
import { useProfile } from '../contexts/profile.context';
import { isMedic } from '../utils/profiles';
import { MedicDataFragment, PatientProfessionalDataFragment, ProfessionalSharesType } from '../generated/graphql';
import { parseISO } from 'date-fns';
import { usePersonalMedicTransfers } from './usePersonalMedicTransfers';

export const useGroupMedicine = () => {
  const { actingAs } = useProfile();

  return useMemo(() => {
    return actingAs && isMedic(actingAs)
      ? (actingAs as MedicDataFragment).professionalsSharedWithMe
        .find(p => p.type === ProfessionalSharesType.GroupMedicine)
      : null
  }, [actingAs]);
}

export const useInviteInGroupMedicine = (patientProfessional?: PatientProfessionalDataFragment) => {

  const myGroupMedicine = useGroupMedicine();
  const { actingAs } = useProfile();
  const { pendingTransfers: personalMedicTransfers, loading: pmtLoading } = usePersonalMedicTransfers(
    patientProfessional?.patient.id,
  );

  const inviteToGroupMedicine = useMemo(() => {
    return patientProfessional
      && patientProfessional.__typename === 'PatientProfessional'
      && patientProfessional.professionalTransfers
      && patientProfessional.professionalTransfers.length > 0
      && ([...patientProfessional.professionalTransfers]).sort((a, b) => {
        const aStart = a.acceptedAt ? parseISO(a.acceptedAt).getTime() : Infinity;
        const bStart = b.acceptedAt ? parseISO(b.acceptedAt).getTime() : Infinity;
        // first those not accepted yet, then those accepted ordered by acceptedAt DESC
        return aStart > bStart ? -1 : 1
      })[0]
  }, [patientProfessional]);

  const personalMedicTransfer = useMemo(() => {
    return personalMedicTransfers.length
      ? personalMedicTransfers[0]
      : null
  }, [personalMedicTransfers]);

  const canAddToGroupMedicine = useMemo(() => {
    return myGroupMedicine
      && patientProfessional
      && patientProfessional.patient.personalMedicId === actingAs?.id
      && patientProfessional.professionalId !== myGroupMedicine.professional.id
      && !(inviteToGroupMedicine && inviteToGroupMedicine.acceptedAt);
  }, [myGroupMedicine, inviteToGroupMedicine, patientProfessional, actingAs]);

  return useMemo(() => {
    return {
      inviteToGroupMedicine,
      personalMedicTransfer,
      canAddToGroupMedicine,
      myGroupMedicine,
      pmtLoading,
    }
  }, [inviteToGroupMedicine, personalMedicTransfer, canAddToGroupMedicine, myGroupMedicine, pmtLoading]);
}
