import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import {
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { getProfessionalFullName, getProfileIcon } from '../../../utils/profiles';

import { OrganizationProfessionalPersonalDataFragment } from '../../../generated/graphql';
import { propsAsTrue } from '../../../utils/constants';
import { OrganizationProfessionalActionsDial } from './organization-professional-actions-dial';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    profileIcon: {
      fill: theme.palette.text.secondary,
      width: 20,
    },
  }),
);

export type OrganizationProfessionalItemProps = {
  professional: OrganizationProfessionalPersonalDataFragment;
  divider?: boolean;
  onClick?: () => void;
  action?: ReactNode;
}

export const OrganizationProfessionalItem: FC<OrganizationProfessionalItemProps> = ({
  professional: organizationProfessional,
  divider,
  action,
  onClick,
 }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const Icon = getProfileIcon(organizationProfessional.professional);
  const primary = getProfessionalFullName(organizationProfessional.professional, t);
  const secondary = t(`organization:role.${organizationProfessional.role}`) as string;

  const defaultAction = <OrganizationProfessionalActionsDial
    professional={organizationProfessional}
    collapsed={true}
  />;

  const truthyProps = propsAsTrue({
    'button': Boolean(onClick),
  });

  return (
    <ListItem onClick={onClick} divider={divider} {...truthyProps}>
      <ListItemIcon>
        <Icon className={classes.profileIcon} />
      </ListItemIcon>
      <ListItemText
        primary={primary}
        secondary={secondary}
      />
      <ListItemSecondaryAction>
        {action === undefined ? defaultAction : action}
      </ListItemSecondaryAction>
    </ListItem>
  )

}
