import { FC, useCallback } from 'react';
import { InvitePatientForm, useInvitePatient } from '../../../hooks/patients/useInvitePatient';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { PatientProfessionalDataFragment } from '../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { validateEmail } from '../../../utils';
import { ControlledTextField, SubmitButton } from '../form';

export interface InvitePatientDialogProps {
  patientProfessional: PatientProfessionalDataFragment;
  onClose: () => void;
}

export const InvitePatientDialog: FC<InvitePatientDialogProps> = ({ patientProfessional, onClose }) => {
  const { onInvite, loading, handleSubmit, control } = useInvitePatient(patientProfessional);
  const { t } = useTranslation();

  const onSubmit = useCallback((data: InvitePatientForm) => {
    onInvite(data)
      .then(() => onClose());
  }, [onInvite, onClose]);

  return (
    <Box component="form" pb={2} onSubmit={handleSubmit(onSubmit)} noValidate>
      <Box>
        <Box pb={2}>
          <Typography variant="h6">
            {t('invite:insert-patient-email')}
          </Typography>
        </Box>
        <ControlledTextField
          control={control}
          name="email"
          type="email"
          required
          rules={{
            validate: validateEmail,
          }}
          label={t('email')}
        />
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center" pt={2}>
        <Button onClick={onClose} type="button">
          {t('cancel')}
        </Button>
        <SubmitButton
          loading={loading}
          startIcon={loading && <CircularProgress size="1em" color="inherit"/>}
        >
          {t('invite')}
        </SubmitButton>
      </Box>
    </Box>
  )
}
