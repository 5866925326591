import { ContainerProps } from '@mui/material';
import { Route } from 'react-router-dom';

export enum RoutesKeys {
  dashboard = 'dashboard',
  patients = 'patients',
  patientDetail = 'patientDetail',
  patientProfile = 'patientProfile',
  patientImport = 'patientImport',
  agenda = 'agenda',
  appointment = 'appointment',
  network = 'network',
  professionalDetail = 'professionalDetail',
  reports = 'reports',
  requests = 'requests',
  requestDetail = 'requestDetail',
  reportDetail = 'reportDetail',
  invoices = 'invoices',
  pageNotFound = 'pageNotFound',
  notifications = 'notifications',
  profile = 'profile',
  profiles = 'profiles',
  createProfile = 'createProfile',
  professionalRegister = 'professionalRegister',
  payment = 'payment',
  paymentSuccess = 'paymentSuccess',
  paymentRenew = 'paymentRenew',
  assistants = 'assistants',
  pendingInvites = 'pendingInvites',
  singlePendingInvites = 'singlePendingInvites',
  sentInvites = 'sentInvites',
  agendaConfigure = 'agendaConfigure',
  agendaList = 'agendaList',
  agendaOverride = 'agendaOverride',
  organization = 'organization',
  invites = 'invites',
}

export const DOC_LINK = 'https://www.manula.com/manuals/hu-care/casadellasaluteweb/1/it/topic/introduzione';

export interface RouteType<K = RoutesKeys, CK = any> {
  key: K;
  path: string;
  component: any;
  exact?: boolean;
  children?: RouteType<CK>[];
  config: {
    breadcrumbs?: boolean,
    back?: boolean,
    title: string,
    scopes?: string[];
    args?: Record<string, any>;
    containerProps?: Partial<Omit<ContainerProps, 'children'>>;
    navBarType?: 'default' | 'conditional' | 'external';
    [key: string]: any;
  };
  parent?: RoutesKeys;    // used for the activation of the selected tab
}

export const RETURN_TO_KEY = 'ref';

export const renderRoutes = (routes: RouteType[], ensureNotAbsolute = false) => {
  return routes.map(({ component: C, path, children }) => {
    const ensuredPath = (ensureNotAbsolute && path.startsWith('/')) ? path.substring(1) : path;

    if (!children || children.length === 0) {
      return <Route key={path} path={ensuredPath} element={<C/>}/>;
    }
    return (
      <Route key={path} path={ensuredPath} element={<C/>} index={path === '' || path === '/'}>
        {renderRoutes(children, true)}
      </Route>
    )
  });
}
