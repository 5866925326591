import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { useReport } from '../../../hooks/reports/useReport';
import { getRoute, RETURN_TO_KEY, RoutesKeys } from '../../../routes';
import {
  Box,
  Dialog, DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  Link,
  Button,
  CircularProgress,
  Tooltip,
  MenuItem,
  Alert, Skeleton, Theme,
} from '@mui/material';

import { makeStyles } from '@mui/styles';
import { AttachFile, Block, Close } from '@mui/icons-material';
import { AttachmentsPreview, getIcon } from '../attachments-preview';
import { DownloadIcon } from '../../../utils/icons';
import { Link as RouteLink } from 'react-router-dom';
import { useProfile } from '../../../contexts';
import { useDeleteReport } from '../../../hooks/reports/useDeleteReport';
import { UpdateReportForm, useUpdateReport } from '../../../hooks/reports/useUpdateReport';
import { ControlledEditableInput } from '../editable-input';
import { ACCEPTED_FILES_HUMAN, canHandleFiles, convertBytesToMbsOrKbs, MAX_FILE_SIZE } from '../../../utils/upload';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useToast } from '@hu-care/react-ui-store';
import { DragOverlay } from '../drag-overlay';
import { Controller } from 'react-hook-form';
import { formatDate } from '@hu-care/react-utils';
import { ReportPdf } from './report-pdf';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { HtmlEditor } from '../draft-editor/draft-editor';
import { useAcceptShare, useDeclineShare } from '../../../hooks/reports/useAcceptDeclineShare';
import { useClinics } from '../../../hooks/clinic/useClinics';

import { omit } from 'lodash-es';
import { ControlledKeyboardDatePicker, ControlledTextField, SubmitButton } from '../form';
import { Error } from '@hu-care/react-layout';

const useStyles = makeStyles((theme: Theme) => ({
  attachmentLink: {
    color: 'inherit',
  },
  errorButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.common.white,
  },
  form: {
    width: '100%',
    // paddingTop: theme.spacing(1),
    // paddingBottom: theme.spacing(1),
    // position: 'relative',
    '&:focus': {
      outline: 'none',
    },
  },
}));

const MAX_FILES = 5;

export const ReportDetailDialog: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const toast = useToast();
  const isMobile = useIsMobile();
  const { actingAs } = useProfile();
  const { id } = useParams<'id'>();
  const [open, setOpen] = useState(true);
  const { sharedReport, loading, error } = useReport(id!);

  const [search] = useSearchParams();
  const { onAccept, loading: acceptLoading } = useAcceptShare();

  const returnTo = search.get(RETURN_TO_KEY);

  const onClose = useCallback(() => {
    setOpen(false);
    if (returnTo) {
      navigate(returnTo);
    }
  }, [setOpen, navigate, returnTo]);

  useEffect(() => {
    const int = setTimeout(() => {
      if (!open) {
        const currentQuery = `?${search.toString()}`;
        const reportsRoute = `${getRoute(RoutesKeys.reports)}${currentQuery}`;
        navigate(reportsRoute);
      }
    });
    return () => {
      clearTimeout(int);
    }
  }, [open, search, navigate]);

  const patientProfessionalId = sharedReport?.patientProfessionalId;
  const patientDetailRoute = patientProfessionalId ? `${getRoute(RoutesKeys.patients)}/${patientProfessionalId}` : '';
  const report = sharedReport?.report;
  const isOwner = !!((report && actingAs) && report.professionalId === actingAs?.id);

  const { deleteReportWithConfirm } = useDeleteReport(report);
  const {
    onSubmit,
    handleSubmit,
    control,
    loading: uLoading,
    files: {
      fields,
      append,
      remove,
    },
  } = useUpdateReport(report);

  const { clinics, loading: cLoading } = useClinics();

  const onFormSubmit = useCallback((data: UpdateReportForm) => {
    if (!clinics) {
      return;
    }
    const clinic = clinics.find(c => c.id === data.clinicId);
    const payload = {
      ...data,
      ...clinic && {
        meta: { clinic: omit(clinic, 'rooms') },
      },
    };
    return onSubmit(payload);
  }, [clinics, onSubmit]);

  const onDrop = useCallback((newFiles: File[], reject: FileRejection[]) => {
    if (canHandleFiles({ newFiles, reject, t, showMessage: toast.error, maxFiles: MAX_FILES })) {
      append(newFiles.map(file => ({ file })));
    }
  }, [toast, t, append]);

  const onFileRemove = useCallback((idx: number | number[]) => {
    remove(idx);
  }, [remove]);

  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    maxFiles: 5,
    multiple: true,
    maxSize: MAX_FILE_SIZE,
  });

  const onDelete = () => {
    deleteReportWithConfirm()
      .then(() => navigate(getRoute(RoutesKeys.reports), { replace: true }))
      .catch(() => {/**/});
  }

  const reportPdf = useMemo(() => {
    // Generate PDF only when report is created by a Professional
    if (!report || !report.professionalId) {
      return null;
    }
    return <ReportPdf report={report}/>
  }, [report]);

  const isReportDeleted = report && !isOwner && !!report.patientDeletedAt;

  const { onDeclineWithConfirm, loading: declineLoading } = useDeclineShare();

  if (error) {
    return <Error error={error} />
  }

  const releasedBy = report?.medicName || report?.professional?.fullName || '-';
  const uploadedBy = `${report?.creator?.traits.name || ''} ${report?.creator?.traits.surname || ''}`;

  if (!sharedReport?.accepted && !loading) {
    return <Dialog
      open={open}
      scroll="paper"
      onClose={onClose}
      maxWidth="md"
      fullWidth
      fullScreen={isMobile}
      PaperProps={{
        square: true,
      }}
    >
      <Box p={2} display="flex" flexDirection="column" alignItems="center" margin="0 auto" textAlign="center">
        <Typography variant="h4">
          {t('report:confirm-accept')}
        </Typography>
      </Box>

      <Box px={1} pb={1}>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            onClick={onClose}
          >
            {t('cancel', 'Annulla')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => sharedReport && onAccept(sharedReport)}
            disabled={acceptLoading}
          >
            {
              acceptLoading
              ? <CircularProgress size="1rem" color="secondary" />
              : t('confirm', 'Conferma')
            }
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  }

  return (
    <Dialog
      open={open}
      scroll="paper"
      onClose={onClose}
      maxWidth="md"
      fullWidth
      fullScreen={isMobile}
      PaperProps={{
        square: true,
      }}
    >
      <form onSubmit={handleSubmit(onFormSubmit)} noValidate {...getRootProps({ className: classes.form })}>
        {isDragActive && <DragOverlay/>}
        <DialogTitle>
          <Box display="flex" alignItems="center" width="100%">
            <Box ml={1} mr="auto" display="flex" flexWrap="wrap" alignItems="flex-end">
              <Box pr={1}>
                {isOwner
                  ? t('report:update-report')
                  : t('report:view-report')
                }
              </Box>
            </Box>
            {reportPdf && (
              <PDFDownloadLink document={reportPdf} fileName="report.pdf">
                {({ loading }) =>
                  <Tooltip title={t<string>('download-pdf')}>
                    <IconButton>
                      {loading ? <CircularProgress/> : <DownloadIcon/>}
                    </IconButton>
                  </Tooltip>
                }
              </PDFDownloadLink>
            )}
            <IconButton onClick={onClose}>
              <Close/>
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>

            {isReportDeleted && (
              <Grid item xs={12}>
                <Alert
                  severity="warning"
                  action={
                    <Button
                      startIcon={<Block/>}
                      color="inherit"
                      size="small"
                      disabled={declineLoading}
                      onClick={() => {
                        if (sharedReport) {
                          onDeclineWithConfirm(sharedReport)
                            .then(() => onClose());
                        }
                      }}
                    >
                      {t('delete')}
                    </Button>
                  }
                >
                  {t('report:patient-request-deletion')}
                </Alert>
              </Grid>
            )}

            <Grid item xs={12} sm={6}>
              <Typography variant={'caption'} color={'textSecondary'}>
                {t('agenda:patient-name')}
              </Typography>
              {loading ? (
                <Skeleton variant="text" width="100%" />
              ) : (
                <RouteLink to={patientDetailRoute} className={classes.attachmentLink}>
                  <Typography variant={'body1'}>
                    {report && `${report.patient.name} ${report.patient.surname}`}
                  </Typography>
                </RouteLink>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant={'caption'} color={'textSecondary'}>
                {t('taxId')}
              </Typography>
              {loading ? (
                <Skeleton variant="text" width="100%" />
              ) : (
                <Typography variant={'body1'}>
                  {report?.patient.taxId}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant={'caption'} color={'textSecondary'}>
                {t('report:uploaded-by')}
              </Typography>
              {loading ? (
                <Skeleton variant="text" width="100%" />
              ) : (
                <Typography variant={'body1'}>
                  {uploadedBy}
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant={'caption'} color={'textSecondary'}>
                {t('report:released-by')}
              </Typography>
              {loading ? (
                <Skeleton variant="text" width="100%" />
              ) : (
                <Typography variant={'body1'}>
                  {releasedBy}
                </Typography>
              )}
            </Grid>

            {report?.patient.address && <Grid item xs={12}>
              <Typography variant={'caption'} color={'textSecondary'}>
                {t('address:address')}
              </Typography>
              <Typography variant={'body1'}>
                {
                  [
                    [
                      report.patient.address?.address,
                      report.patient.address?.cap,
                    ].filter(Boolean).join(', '),
                    [
                      report.patient.address?.municipalityName,
                      report.patient.address?.districtName
                        ? `(${report.patient.address?.districtName})`
                        : '',
                    ].filter(Boolean).join(' '),
                  ].join(' - ')
                }
              </Typography>
            </Grid>}

            {isOwner && (
              <Grid item xs={12}>
                <Box pb={1}>
                  <Typography variant="subtitle2">{t('report:gdpr-warning.line-1')}</Typography>
                </Box>
              </Grid>
            )}

            {clinics && (
              <Grid item xs={12}>
                {isOwner ? (
                  <ControlledTextField
                    control={control}
                    name="clinicId"
                    required
                    select
                    label={t('report:select-clinic')}
                    disabled={cLoading}
                  >
                    {(clinics || []).map(clinic => (
                      <MenuItem value={clinic.id} key={clinic.id}>
                        {clinic.name}
                      </MenuItem>
                    ))}
                  </ControlledTextField>
                ) : (
                  <div/>
                )}
              </Grid>
            )}

            <Grid item xs={12} sm={6}>
              {loading ? (
                <Skeleton variant="text" width={200} />
              ) : (
                <ControlledEditableInput
                  name="title"
                  editMode={isOwner}
                  control={control}
                  valueProps={{
                    variant: isOwner ? 'h4' : 'h6',
                  }}
                  variant="outlined"
                  fullWidth
                  label={t('title')}
                  hideLabel={isOwner}
                  rules={{
                    required: true,
                  }}
                  required
                />
              )}
            </Grid>

            <Grid item xs={12} sm={6}>
              {loading ? (
                <Skeleton variant="text" width={100} />
              ) :
                isOwner ? (
                  <>
                    <ControlledKeyboardDatePicker
                      control={control}
                      name="reportDate"
                      label={t('report:date')}
                    />
                  </>
                ) : (
                  <>
                    <Typography variant={'caption'} color={'textSecondary'}>
                      {t('report:date')}
                    </Typography>
                    <Typography variant={'body1'}>
                      {report && formatDate(report.reportDate)}
                    </Typography>
                  </>
                )
              }
            </Grid>

            <Grid item xs={12}>
              <Box pt={1}>
                {loading ? (
                  <Skeleton variant="text" width="100%" height={80}/>
                ) : (
                  <Box minHeight={80}>
                    <Box pb={1}>
                      <Typography variant={'caption'} color={'textSecondary'}>
                        {t('description')}
                      </Typography>
                    </Box>
                    {report && (
                      isOwner
                        ? <Controller
                          control={control}
                          name="description"
                          render={({ field: { onChange } }) => (
                            <HtmlEditor
                              initialValue={report.description || ''}
                              onChange={onChange}
                            />
                          )}
                        />
                        : <div dangerouslySetInnerHTML={{ __html: report.description || '-' }} />
                    )}
                  </Box>
                )}
              </Box>
            </Grid>

            {report?.notes && <Grid item xs={12}>
              <Box pt={1}>
                {loading ? (
                  <Skeleton variant="text" width="100%" height={80} />
                ) : (
                  <Box minHeight={80}>
                    <Typography variant={'caption'} color={'textSecondary'}>
                      {t('notes')}
                    </Typography>
                    <div dangerouslySetInnerHTML={{ __html: report.notes }} />
                  </Box>
                )}
              </Box>
            </Grid>}

            {isOwner && (
              <Grid item xs={12}>
                <Grid container spacing={1} alignItems="center">
                  <Grid item xs={12}>
                    <AttachmentsPreview attachments={fields} onRemove={onFileRemove}/>
                  </Grid>
                  <Grid item>
                    <label htmlFor="request-attachments">
                      <Button startIcon={<AttachFile/>} component="span">
                        {t('add-attachment')}
                      </Button>
                      <input id="request-attachments" {...getInputProps()}/>
                    </label>
                    <Typography variant="caption">
                      {
                        t('max-file-size', {
                          size: convertBytesToMbsOrKbs(MAX_FILE_SIZE),
                          formats: ACCEPTED_FILES_HUMAN.join(', '),
                        })
                      }
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        {(report?.attachments?.length || 0) > 0 && (
          <DialogActions>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box px={2} pb={1}>
                  <Typography variant={'caption'} color={'textSecondary'}>
                    {t('attachments')}
                  </Typography>
                  <List dense>
                    {report?.attachments?.map(attachment => (
                      <ListItem
                        component={Link}
                        dense
                        button
                        divider
                        disableGutters
                        key={attachment.id}
                        href={attachment.url}
                        target="_blank"
                        className={classes.attachmentLink}
                      >
                        <ListItemIcon>
                          {getIcon(attachment.mimeType)}
                        </ListItemIcon>
                        <ListItemText
                          primary={attachment.name}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            component={Link}
                            href={attachment.url}
                            target="_blank"
                          >
                            <DownloadIcon/>
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Grid>
            </Grid>
          </DialogActions>
        )}
        {report && isOwner && (
          <DialogActions>
            <Box display="flex" justifyContent="space-between" width="100%" px={1} pb={1}>
              <Button
                type="button"
                variant="contained"
                className={classes.errorButton}
                onClick={onDelete}
                disableElevation
              >
                {t('delete')}
              </Button>

              <Box ml="auto">
                <SubmitButton
                  loading={uLoading}
                  endIcon={uLoading && <CircularProgress size={16}/>}
                  disableElevation
                >
                  {t('save')}
                </SubmitButton>
              </Box>
            </Box>
          </DialogActions>
        )}
      </form>
    </Dialog>
  )
}
