import { useQuery } from '@apollo/client';
import {
  RequestDataFragment,
  RequestDocument,
  RequestQuery,
  RequestQueryVariables,
} from '../../generated/graphql';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Action } from '../../views/components/actions-dial/actions-dial';
import { useNavigate } from 'react-router-dom';
import { Visibility } from '@mui/icons-material';
import { getRoute, RoutesKeys } from '../../routes';

export const useRequest = (id: string) => {
  const { data, ...rest } = useQuery<RequestQuery, RequestQueryVariables>(RequestDocument, {
    variables: { id },
  });

  return useMemo(() => ({
    request: data?.request,
    ...rest,
  }), [
    data,
    rest,
  ]);
}

export const useRequestActions = (request: RequestDataFragment, withConfirm?: boolean) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  // const confirmify = useCallback((title, action) => {
  //   if (withConfirm) {
  //     return () => dialog.confirm({
  //       onConfirm: action,
  //       title,
  //     });
  //   } else {
  //     return action;
  //   }
  // }, [withConfirm, dialog]);

  const actions: Action[] = useMemo(() => {
    // const canResolve = actingAs && !isPatient(request.recipient) && request.recipient.id === actingAs.id;

    return [
      // ...(
      //   request.result || !canResolve
      //   ? []
      //   : [
      //       {
      //       icon: <DoneIcon />,
      //       name: t('request:accept'),
      //       callback: confirmify(t('request:accept-confirm'), () => onResolve(RequestResult.Ok)),
      //       },
      //       {
      //         icon: <BlacklistIcon />,
      //         name: t('request:refuse'),
      //         callback: confirmify(t('request:refuse-confirm'), () => onResolve(RequestResult.Ko)),
      //       },
      //     ]
      // ),
      {
        icon: <Visibility />,
        name: t('request:view'),
        callback: () => navigate(`${getRoute(RoutesKeys.requests)}/${request.id}`),
      },
    ];
  }, [
    // confirmify,
    t,
    // actingAs,
    navigate,
    request,
    // onResolve,
  ]);

  return actions;
}

