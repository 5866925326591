import { RoutesKeys, RouteType } from './utils';
import { asyncLoad } from '@hu-care/react-utils';
import { invoiceRoutes } from './invoice.routes';
import { agendaRoutes } from './agenda.routes';
import { RequestDetailDialog } from '../views/components/requests/request-detail';
import { ReportDetailDialog } from '../views/components/reports/report-detail';

const withExternalNavBarRoutes: RouteType[] = [
  {
    key: RoutesKeys.singlePendingInvites,
    component: asyncLoad(() => import('../views/pages/invites/single-pending-invites.page')),
    path: '/invites/:id',
    exact: true,
    config: {
      title: 'pending invites',
      navBarType: 'conditional',
    },
    parent: RoutesKeys.pendingInvites,
  },
  {
    key: RoutesKeys.invites,
    component: asyncLoad(() => import('../views/pages/invites/pending-invites.page')),
    path: '/invites',
    exact: true,
    config: {
      title: 'invites',
      navBarType: 'conditional',
    },
  },
  /* {
    key: RoutesKeys.pendingInvites,
    component: asyncLoad(() => import('../views/pages/invites/pending-invites.page')),
    path: '/invites',
    exact: true,
    config: {
      title: 'invites',
      navBarType: 'conditional',
    },
  }, */
  /* {
    key: RoutesKeys.sentInvites,
    component: asyncLoad(() => import('../views/pages/invites/sent-invites.page')),
    path: '/sent-invites',
    exact: true,
    config: {
      title: 'sent invites',
      navBarType: 'conditional',
    },
    parent: RoutesKeys.pendingInvites,
  }, */
  {
    key: RoutesKeys.createProfile,
    component: asyncLoad(() => import('../views/pages/profile/create-profile.page')),
    path: '/profiles-new',
    exact: true,
    config: {
      title: 'create profile',
      navBarType: 'external',
    },
    parent: RoutesKeys.profiles,
  },
  {
    key: RoutesKeys.profiles,
    component: asyncLoad(() => import('../views/pages/profile/profiles.page')),
    path: '/profiles',
    exact: true,
    config: {
      title: 'profiles',
      navBarType: 'conditional',
    },
  },
  {
    key: RoutesKeys.paymentRenew,
    component: asyncLoad(() => import('../views/pages/payment/payment-renew.page')),
    path: '/payment/:professionalId/renew',
    exact: true,
    config: {
      title: 'payment renew',
      navBarType: 'external',
    },
  },
  {
    key: RoutesKeys.payment,
    component: asyncLoad(() => import('../views/pages/payment/payment.page')),
    path: '/payment/:professionalId',
    exact: true,
    config: {
      title: 'payment',
      navBarType: 'external',
    },
  },
  {
    key: RoutesKeys.paymentSuccess,
    component: asyncLoad(() => import('../views/pages/payment/payment-success.page')),
    path: '/payment/:professionalId/success',
    exact: true,
    config: {
      title: 'payment success',
      navBarType: 'external',
    },
  },
  {
    key: RoutesKeys.professionalRegister,
    component: asyncLoad(() => import('../views/pages/profile/professional-register.page')),
    path: '/professional-register/:medicId',
    exact: true,
    config: {
      title: 'professional register',
      navBarType: 'external',
    },
  },
];

export const commonRoutes: RouteType[] = [
  ...withExternalNavBarRoutes,
  {
    key: RoutesKeys.dashboard,
    component: asyncLoad(() => import('../views/pages/dashboard/dashboard.page')),
    path: '/',
    exact: true,
    config: {
      title: 'dashboard',
    },
  },
  {
    key: RoutesKeys.profile,
    component: asyncLoad(() => import('../views/pages/profile/profile.page')),
    path: '/profile',
    exact: true,
    config: {
      title: 'profile',
    },
  },
  /* {
    key: RoutesKeys.appointment,
    component: asyncLoad(() => import('../views/pages/appointment/appointment-detail.page')),
    path: '/appointments/:appointmentId',
    exact: true,
    config: {
      title: 'appointment',
    },
  }, */
  {
    key: RoutesKeys.agenda,
    component: asyncLoad(() => import('../views/pages/agenda/agenda.page')),
    path: '/agenda',
    exact: true,
    config: {
      title: 'agenda',
    },
    children: agendaRoutes,
  },
  /* This obj is a naive solution to the problem of the side-bar menu.
    The side menu options to accepts only RoutesKeys element, you cannot set the prop "to" in RoutesKeys | AgendaRoutesKeys
    because to get the routeConfig there are 2 diff. funcs: (getRoute for RoutesKeys and getAgendaRoute for AgendaRoutesKeys)*/
    /*
    {
    key: RoutesKeys.agendaConfigure,
    component: asyncLoad(() => import('../views/pages/agenda/pages/agenda-configure.page')),
    path: '/agenda/configure',
    exact: true,
    config: {
      title: 'agenda configure',
    },
    parent: RoutesKeys.agenda,
  },*/

  // deprecated use /agenda/configure
  /* {
    key: RoutesKeys.agendaList,
    component: asyncLoad(() => import('../views/pages/agenda/pages/agenda-list.page')),
    path: '/configure-agenda/:agendaId',
    exact: true,
    config: {
      title: 'agenda list',
    },
  },
  // deprecated
  {
    key: RoutesKeys.agendaOverride,
    component: asyncLoad(() => import('../views/pages/agenda/pages/agenda-override.page')),
    path: '/override-agenda/:date',
    exact: true,
    config: {
      title: 'agenda override',
    },
  }, */
  {
    key: RoutesKeys.network,
    component: asyncLoad(() => import('../views/pages/network/network.page')),
    path: '/network',
    exact: false,
    config: {
      title: 'network',
    },
  },
  {
    key: RoutesKeys.professionalDetail,
    component: asyncLoad(() => import('../views/pages/professional/professional-profile.page')),
    path: '/professional/:id',
    exact: true,
    config: {
      title: 'professional',
    },
    parent: RoutesKeys.network,
  },
  {
    key: RoutesKeys.patientImport,
    component: asyncLoad(() => import('../views/pages/patients/patient-upload-import.page')),
    path: '/patients/import',
    exact: true,
    config: {
      title: 'patient import',
    },
    parent: RoutesKeys.patients,
  },
  {
    key: RoutesKeys.patientDetail,
    component: asyncLoad(() => import('../views/pages/patients/patient-detail.page')),
    path: '/patients/:patientId',
    exact: true,
    config: {
      title: 'patient',
    },
    parent: RoutesKeys.patients,
  },
  {
    key: RoutesKeys.patientProfile,
    component: asyncLoad(() => import('../views/pages/patients/patient-profile.page')),
    path: '/patient-profile/:patientId',
    exact: true,
    config: {
      title: 'patient',
    },
    parent: RoutesKeys.patients,
  },
  {
    key: RoutesKeys.patients,
    component: asyncLoad(() => import('../views/pages/patients/patients.page')),
    path: '/patients',
    exact: false,
    config: {
      title: 'patients',
    },
  },
  {
    key: RoutesKeys.reports,
    component: asyncLoad(() => import('../views/pages/reports/reports.page')),
    path: '/reports',
    // exact: true,
    config: {
      title: 'reports',
    },
    children: [
      {
        key: RoutesKeys.reportDetail,
        component: ReportDetailDialog,
        path: '/:id',
        config: {
          title: 'report-detail',
        },
      },
    ],
  },
  {
    key: RoutesKeys.requests,
    component: asyncLoad(() => import('../views/pages/requests/requests.page')),
    path: '/requests',
    // exact: true,
    config: {
      title: 'requests',
    },
    children: [
      {
        key: RoutesKeys.requestDetail,
        component: RequestDetailDialog,
        path: '/:id',
        config: {
          title: 'request-detail',
        },
      },
    ],
  },
  /*{
    key: RoutesKeys.notifications,
    component: asyncLoad(() => import('../views/pages/notifications/notifications.page')),
    path: '/notifications',
    exact: true,
    config: {
      title: 'notifications',
    },
  }, */
  {
    key: RoutesKeys.assistants,
    component: asyncLoad(() => import('../views/pages/assistants/assistants.page')),
    path: '/assistants',
    exact: true,
    config: {
      title: 'assistants',
    },
  },
  {
    key: RoutesKeys.invoices,
    component: asyncLoad(() => import('../views/pages/invoices/invoices.page')),
    path: '/invoices',
    exact: false,
    config: {
      title: 'invoices',
    },
    children: invoiceRoutes,
  },
  {
    key: RoutesKeys.organization,
    component: asyncLoad(() => import('../views/pages/organization/dashboard-organization.page')),
    path: '/organization/:id',
    exact: true,
    config: {
      title: 'organization',
      navBarType: 'external',
    },
  },
];
