import { FC } from 'react';

import { ActionsDial, ActionsDialProps } from '../actions-dial/actions-dial';
import { OrganizationProfessionalPersonalDataFragment } from '../../../generated/graphql';
import { useOrganizationProfessionalsActions } from '../../../hooks/useManagedOrganizations';

export interface OrganizationProfessionalActionsDialProps extends Omit<ActionsDialProps, 'actions'> {
  professional: OrganizationProfessionalPersonalDataFragment;
  withConfirm?: Boolean;
}

export const OrganizationProfessionalActionsDial: FC<OrganizationProfessionalActionsDialProps> = ({ professional, withConfirm = true, ...rest }) => {
  const actions = useOrganizationProfessionalsActions(professional, withConfirm);

  return <ActionsDial actions={actions} {...rest} />
};

