import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useToast } from '@hu-care/react-ui-store';

export const useHandleError = (shouldThrow = false) => {
  const { t } = useTranslation();
  const toast = useToast();

  return useCallback((e: any, defaultMessage?: string) => {
    console.error(e);

    let customMessage;
    if (typeof e === 'object') {
     customMessage = e.graphQLErrors?.length > 0
      && e.graphQLErrors[0].extensions?.errorCode?.length === 6
        ? e.message
        : null
    }

    const message = customMessage
      || (defaultMessage && t(defaultMessage));

    if (message) {
      toast.error(message);
    }
    if (shouldThrow) {
      throw e;
    }
  }, [t, toast, shouldThrow]);
}
