import type { Configuration } from 'rollbar';

async function getDefaultErrorBoundary() {
  return {
    ErrorBoundary: await import('../views/components/errors/error.boundary').then(m => m.ErrorBoundary),
    props: null,
  }
}

export let rollbarInstance: import('rollbar') | undefined;

export async function setupErrorReport(): Promise<{
  ErrorBoundary: any;
  props: any;
}> {
  const rollbarToken = process.env.REACT_APP_ROLLBAR_TOKEN;
  if (!rollbarToken || false) {
    return getDefaultErrorBoundary();
  }

  const ErrorBoundary = await import('../views/components/errors/rollbar-error-boundary').then(m => m.RollbarErrorBoundary);

  const config: Configuration = {
    accessToken: rollbarToken,
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: process.env.REACT_APP_ENV || 'development',
  }

  const Rollbar = await import('rollbar').then(m => m.default);

  rollbarInstance = new Rollbar(config);

  return {
    ErrorBoundary,
    props: { instance: rollbarInstance },
  }
}
