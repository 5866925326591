import { FC, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Hidden,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { useProfile } from '../../../contexts';
import { useRequest } from '../../../hooks/requests/useRequest';
import { getRequestActors, getRequestAuthor, getRequestIcon, getRequestType } from '../../../utils/request';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { getRoute, RoutesKeys } from '../../../routes';
import { Close, DeleteForever } from '@mui/icons-material';
import { FileRejection, useDropzone } from 'react-dropzone';
import { makeStyles } from '@mui/styles';
import { useToast } from '@hu-care/react-ui-store';
import { AttachmentsPreview } from '../attachments-preview';
import { DragOverlay } from '../drag-overlay';
import { canHandleFiles, MAX_FILE_SIZE } from '../../../utils/upload';
import { useConfirmDialog } from '../../../hooks/useConfirmDialog';
import { useDeleteRequest } from '../../../hooks/requests/useDeleteRequest';
import { AnswerRequest } from './answer-request';
import { RequestMessageBubble } from './request-message-bubble';
import { isPatient } from '../../../utils/profiles';
import { RequestStatus } from '../../../utils/constants';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassFullIcon from '@mui/icons-material/HourglassFull';

const useStyles = makeStyles(() => ({
  dragRoot: {
    position: 'relative',
    '&:focus': {
      outline: 'none',
    },
  },
}));

const MAX_FILES = 5;

export const RequestDetailDialog: FC = () => {
  const classes = useStyles();
  const toast = useToast();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const { id } = useParams<'id'>();
  const { request, error } = useRequest(id!);
  const { actingAs } = useProfile();
  const [open, setOpen] = useState(true);
  const dialog = useConfirmDialog();

  const [files, setFiles] = useState<File[] | null>(null);

  const requestTypeIcon = request && getRequestIcon(request);
  const [, counterPart] = getRequestActors(actingAs?.id, request);
  const type = getRequestType(actingAs?.id, request);
  const transKey = type === 'sent' ? 'to' : 'from';

  const counterPartAuthor = counterPart && getRequestAuthor(counterPart, t);

  const { loading: dLoading, deleteRequest } = useDeleteRequest();

  const onDelete = useCallback((e: MouseEvent<{}>) => {
    e.preventDefault();
    if (!request) {
      return;
    }
    dialog.confirm({
      onConfirm: () => deleteRequest(request.id).then(() => dialog.close()),
      title: t('request:confirm-delete', { name: request.title }),
    });
  }, [t, deleteRequest, request, dialog]);

  useEffect(() => {
    if (error) {
      navigate(getRoute(RoutesKeys.requests), { replace: true });
    }
  }, [error, navigate]);

  const onClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const [search] = useSearchParams();

  useEffect(() => {
    const int = setTimeout(() => {
      if (!open) {
        const currentQuery = `?${search.toString()}`;
        const requestsRoute = `${getRoute(RoutesKeys.requests)}${currentQuery}`;
        navigate(requestsRoute);
      }
    });
    return () => {
      clearTimeout(int);
    }
  }, [open, navigate, search]);

  const onDrop = useCallback((newFiles: File[], reject: FileRejection[]) => {
    if (canHandleFiles({ newFiles, reject, t, showMessage: toast.error, maxFiles: MAX_FILES })) {
      setFiles(oldFiles => {
        return [
          ...(oldFiles || []),
          ...newFiles,
        ];
      });
    }
  }, [toast, t, setFiles]);

  const onFileRemove = useCallback((idx: number | number[]) => {
    const indexes = Array.isArray(idx) ? idx : [idx];
    setFiles(oldFiles => {
      return (oldFiles || []).slice().filter((_, i) => !indexes.includes(i));
    });
  }, [setFiles]);

  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    maxFiles: MAX_FILES,
    multiple: true,
    maxSize: MAX_FILE_SIZE,
    disabled: Boolean(request?.result),
  });

  const deleteButton = request
    && request.sender.id === actingAs?.id
    && !request.result
    && (
      <Button
        disabled={dLoading}
        variant="outlined"
        fullWidth={isMobile}
        size="large"
        startIcon={<DeleteForever />}
        onClick={onDelete}>
        {t('request:delete')}
      </Button>
    )


  const status = useMemo(() => request?.result === 'OK'
    ? RequestStatus.Approved
    : request?.result === 'KO'
      ? RequestStatus.Denied
      : RequestStatus.Pending
  ,[request]);

  const color = useMemo(() =>
    status === RequestStatus.Approved ? 'success' : status === RequestStatus.Denied ? 'error' : undefined
  ,[status]);

  const statusIcon = useMemo(() =>
    status === RequestStatus.Approved
    ? <CheckCircleIcon color="success" />
    : status === RequestStatus.Denied
      ? <CancelIcon color="error" />
      : <HourglassFullIcon color="secondary" />
  ,[status]);

  const requestType = request
    ? t(`request:types.${request.type.toLowerCase()}`)
    : '';

  if (!request) {
    return null;
  }

  const isPatientSender = isPatient(request?.sender);
  const showAnswerAlertMsg = !isPatientSender && (request.result || request.sender.id === actingAs?.id);

  return (
    <Dialog
      open={open}
      scroll="paper"
      onClose={onClose}
      maxWidth="md"
      fullWidth
      fullScreen={isMobile}
      PaperProps={{
        square: true,
        ...getRootProps({ className: classes.dragRoot }),
      }}
    >
      {(isDragActive) && <DragOverlay/>}

      <DialogTitle sx={{ backgroundColor: color === 'success' ? '#EFF7EE' : color === 'error' ? '#FAEEED' : undefined }}>
        <Box display="flex" justifyContent="space-between" alignItems="start" width="100%">
          <Box display="flex" alignItems="center">
            <Tooltip title={`${t(`request:status.${status.toLowerCase()}`)}`} >{ statusIcon }</Tooltip>
            <Box pl={2}>
              <Box display="flex">
                <Typography variant="h6" pr={1}>{request?.title}</Typography>
                <Box display="flex" alignItems="center" pl={2}>
                  |<Typography variant="h6" fontWeight={400} px={1}> {requestType}</Typography>
                  {requestTypeIcon}
                </Box>

              </Box>
              {counterPartAuthor && (
                <Typography variant="body1">{t(`request:${transKey}`, counterPartAuthor)}</Typography>
              )}
            </Box>
          </Box>
          <IconButton onClick={onClose}>
            <Close/>
          </IconButton>
        </Box>
      </DialogTitle>

      {!!files && files.length
        ? (
        <DialogContent dividers>
          <AttachmentsPreview
            attachments={files.map(file => ({ file }))}
            onRemove={onFileRemove}/>
          <AnswerRequest request={request} files={files} onSubmit={() => setFiles(null)}/>
        </DialogContent>
        )
        : (
          <>
            <DialogContent dividers>
              {request?.messages.map((message, i) => (
                <RequestMessageBubble key={message.id}
                  message={message}
                  showDateTime={request.messages[i + 1]?.author?.id !== message?.author?.id}
                  showAuthor={i === 0 || request.messages[i-1]?.author?.id !== message?.author?.id}
                  float={message.author?.id !== request.sender.id ? 'right' : 'left'}
                />
              ))}
            </DialogContent>

            <DialogActions>
              <input id="request-attachment" {...getInputProps()}/>
              <Box width="100%" p={1}>
                {!request.result && <Box pb={1} width="100%" textAlign="right">
                  <Typography variant="caption" align="right">
                    {t('request:drop-file-info')}
                  </Typography>
                </Box>}

                {request.result && (
                  <Box pb={2}>
                    <Typography variant="h6">{t(`request:result-${request.result.toLowerCase()}`)}</Typography>
                    {(showAnswerAlertMsg) && (
                      <Typography variant="caption" color="textSecondary">
                        {t('request:result-patient-info')}
                      </Typography>
                    )}
                  </Box>
                )}

                <>
                  <AnswerRequest request={request} onSubmit={() => setFiles(null)}/>
                  <Box pt={2}>
                    <Hidden xsDown>
                      <Grid container justifyContent="space-between" spacing={2}>
                        <Grid item>
                          {deleteButton}
                        </Grid>
                      </Grid>
                    </Hidden>
                    <Hidden xsUp>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          {deleteButton}
                        </Grid>
                      </Grid>
                    </Hidden>
                  </Box>
                </>
              </Box>
            </DialogActions>
          </>
        )}
    </Dialog>
  )
}
