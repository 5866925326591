import { memo, Suspense, useEffect, useMemo } from 'react';
import { Routes, useNavigate, useLocation } from 'react-router-dom';
import { getRoute, renderCommonRoutes, renderErrorRoutes, RoutesKeys } from '../../routes';
import { Layout } from '../components/layout';
import { ChatPopupProvider, useProfile, useAlerts } from '../../contexts';
import { useLocalStorage } from 'react-use';
import { shouldCompleteProfile } from '../../utils/profiles';
import { CenteredLoading } from '../components/centered-loading';
import { Box } from '@mui/material';
import { AlertMessage } from '../components/alert-message';
import { GA } from '../../utils/ga';
import { usePlatformAlerts } from '../../hooks/usePlatformAlerts';
import { platformAlertToAlertMessage } from '../../utils/platform-alerts';
import { usePendingInvites } from '../../hooks/invite/useInvites';
import { useDialog } from '@hu-care/react-ui-store';
import { useTranslation } from 'react-i18next';
import { SinglePendingInvite } from '../components/invites/single-pending-invite';
import { PendingInvitesList } from '../components/invites/pending-invites-list';

const MainContainer = memo(() => {
  const { t } = useTranslation();
  const routes = useMemo(() => renderCommonRoutes([]).concat(renderErrorRoutes()), []);
  const { loading: profileLoading, actingAs, orgAcceptableProfile } = useProfile();
  const [firstConfigCheck, setFirstConfigCheck] = useLocalStorage<boolean>('@medic:firstConfig');
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { addManyMessages, messages, closeMessage } = useAlerts();
  const { loading: pLoading, platformAlerts = [] } = usePlatformAlerts();

  const { pendingInvites, loading: iLoading } = usePendingInvites();
  const { open } = useDialog();

  const loading = profileLoading || iLoading;

  useEffect(() => {
    if (pendingInvites.length) {
      const invites = pendingInvites.length === 1
        ? <SinglePendingInvite
          invite={pendingInvites[0]}
          orgAcceptableProfile={orgAcceptableProfile!}
        />
        : <PendingInvitesList
          invites={pendingInvites}
          loading={false}
          orgAcceptableProfile={orgAcceptableProfile}
        />

      open({
        title: t('pending-invites'),
        body: invites,
        maxWidth: 'sm',
        fullWidth: true,
      });
    }
  }, [pendingInvites, open, orgAcceptableProfile, t]);

  useEffect(() => {
    if (!('Pace' in window)) {
      return;
    }
    if (pLoading) {
      window.Pace.start();
    } else {
      window.Pace.stop();
    }
  }, [pLoading]);

  useEffect(() => {
    if (platformAlerts.length) {
      addManyMessages(platformAlerts.map(platformAlertToAlertMessage));
    }
  }, [addManyMessages, platformAlerts]);

  useEffect(() => {
    GA.pageview(pathname + search);
  }, [pathname, search]);

  useEffect(() => {
    if (actingAs && shouldCompleteProfile(actingAs) && !firstConfigCheck) {
      setFirstConfigCheck(true);
      navigate(getRoute(RoutesKeys.profile), { replace: true });
    }
  }, [actingAs, setFirstConfigCheck, firstConfigCheck, navigate]);

  const content = loading ? <CenteredLoading/> : (
    <Suspense fallback={<CenteredLoading/>}>
      <Routes>
        {routes}
      </Routes>
    </Suspense>
  );

  return (
    <ChatPopupProvider>
      <Layout>
        <Box pt={messages.length ? 1 : 0} px={2}>
          {messages.map(message => (
            <AlertMessage message={message} onClose={closeMessage} key={message.id}/>
          ))}
        </Box>
        {content}
      </Layout>
    </ChatPopupProvider>
  );
});

export default MainContainer;
