import { useMutation } from '@apollo/client';
import {
  AcceptAssistantInviteDocument,
  AcceptAssistantInviteMutation,
  AcceptAssistantInviteMutationVariables,
  ProfessionalProfilesDocument,
} from '../../generated/graphql';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDialog, useToast } from '@hu-care/react-ui-store';
import { Box, Typography, Button } from '@mui/material';
import { assetUrl } from '../../utils/assets';
import { useHandleError } from '../useHandleError';

export const useAcceptAssistantInvite = () => {
  const { t } = useTranslation();
  const toast = useToast();
  const dialog = useDialog();
  const handleError = useHandleError(false);

  const [
    acceptMutation,
    rest,
  ] = useMutation<AcceptAssistantInviteMutation, AcceptAssistantInviteMutationVariables>(AcceptAssistantInviteDocument, {
    refetchQueries: [{
      query: ProfessionalProfilesDocument,
    }],
    awaitRefetchQueries: true,
  });

  const _acceptInvite = useCallback(async (id: string) => {
    try {
      const result = await acceptMutation({
        variables: {
          id,
          tos: true,
        },
        update: (cache, mutationResult) => {
          if (!mutationResult.data?.acceptAssistantInvite) {
            return;
          }
          cache.evict({
            id: cache.identify({ __typename: 'Invite', id }),
            broadcast: true,
          });
        },
      });
      toast.success(t('invite:accept-invite-success'));
      return result.data?.acceptAssistantInvite;
    } catch (e) {
      handleError(e, 'errors:cannot-accept-invite');
    }
  }, [acceptMutation, toast, t, handleError]);

  const acceptInvite = useCallback(async (id: string) => {
    return new Promise<any>((resolve, reject) => {
      const onConfirm = () => {
        _acceptInvite(id)
          .then(res => {
            if (res) {
              dialog.close();
            }
            resolve(res);
          })
          .catch(reject);
      }

      dialog.open({
        title: t('privacy:tc-needed'),
        maxWidth: 'sm',
        fullWidth: true,
        body: (
          <Box>
            <Box px={1} pb={2}>
              <Typography variant="subtitle2">
                {t('privacy:accept-tc.before')}{' '}
                <a
                  href={assetUrl('/static/T_C_Segretarie.pdf')}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('privacy:accept-tc.link')}
                </a>
                {' '}{t('privacy:accept-tc.after')}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Button onClick={() => dialog.close()}>
                {t('cancel')}
              </Button>
              <Button onClick={onConfirm}>
                {t('accept')}
              </Button>
            </Box>
          </Box>
        ),
      });
    })
  }, [_acceptInvite, t, dialog]);

  return useMemo(() => ({
    acceptInvite,
    ...rest,
  }), [
    acceptInvite,
    rest,
  ]);
}
