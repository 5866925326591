import { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useQuery, useLazyQuery } from '@apollo/client';
import { Typography, Box } from '@mui/material';

import { useDialog } from '@hu-care/react-ui-store';

import { useIsMobile } from './useIsMobile';

import {
  ProfessionalQuery,
  ProfessionalQueryVariables,
  ProfessionalDocument,
  NetworkStatus,
  ProfessionalRole,
  ProfessionalProfileQuery,
  ProfessionalProfileQueryVariables,
  ProfessionalProfileDocument,
  ProfessionalBasicDataFragment,
  ProvisionsByProviderIDsQuery,
  ProvisionsByProviderIDsQueryVariables,
  ProvisionsByProviderIDsDocument,
  CurrentProfessionalDocument,
  CurrentProfessionalQuery,
  CurrentProfessionalQueryVariables, ProvisionDataFragment,
} from '../generated/graphql';

import { useProfile, useChatPopup } from '../contexts';
import {
  currentProfessionalCanChat,
  currentProfessionalCanChatLabel,
  getProfessionalFullName,
  isOrganizationSa,
} from '../utils/profiles';
import { ChatIcon, MailIcon } from '../utils/icons';
import { SendMail } from '../views/components/send-mail';
import { uniqBy } from 'lodash-es';

export const useCurrentProfessional = (id?: string) => {
  const { data, ...rest } = useQuery<CurrentProfessionalQuery, CurrentProfessionalQueryVariables>(CurrentProfessionalDocument, {
    variables: {
      id: id!,
    },
    skip: id === undefined,
    fetchPolicy: 'network-only',
  });

  return useMemo(() => ({
    professional: data?.professional,
    ...rest,
  }), [
    data,
    rest,
  ]);
}

export const useProfessional = (id?: string) => {
  const { data, ...rest } = useQuery<ProfessionalQuery, ProfessionalQueryVariables>(ProfessionalDocument, {
    variables: {
      id: id!,
    },
    skip: id === undefined,
    fetchPolicy: 'network-only',
  });

  return useMemo(() => ({
    professional: data?.professional,
    ...rest,
  }), [
    data,
    rest,
  ]);
}

export const useProvisionsByProviderIDs = (ids?: string[]) => {
  const { data, ...rest } = useQuery<
    ProvisionsByProviderIDsQuery,
    ProvisionsByProviderIDsQueryVariables
  >(ProvisionsByProviderIDsDocument, {
    variables: {
      ids,
    },
    errorPolicy: 'all',
  });

  const provisions = useMemo(() => {
    return uniqBy(data?.professionals.items.flatMap(pp => pp.provisions.flatMap(p => p.provision).filter(Boolean)), 'id') as ProvisionDataFragment[];
  }, [data]);

  return useMemo(() => ({
    provisions,
    providers: data?.professionals.items,
    ...rest,
  }), [
    provisions,
    data,
    rest,
  ]);
}

export const useProfessionalProfile = (id?: string) => {
  const { data, ...rest } = useQuery<ProfessionalProfileQuery, ProfessionalProfileQueryVariables>(ProfessionalProfileDocument, {
    variables: {
      id: id!,
    },
    skip: id === undefined,
    errorPolicy: 'all',
  });

  return useMemo(() => ({
    professional: data?.professional,
    ...rest,
  }), [
    data,
    rest,
  ]);
}

export const useLazyProfessional = () => {
  const [fetchProfessional, rest] = useLazyQuery<ProfessionalQuery, ProfessionalQueryVariables>(ProfessionalDocument);

  return useMemo(() => ({
    fetchProfessional,
    ...rest,
  }), [
    fetchProfessional,
    rest,
  ]);
}

export type ProfileAction = {
  icon: JSX.Element;
  name: string;
  callback: () => void;
};

export type ProfileDialActionListItem = {
  roles?: ProfessionalRole[];
  excludeIf?: Boolean;
  action: ProfileAction;
}

export const useProfessionalDetailActions = (p: ProfessionalBasicDataFragment, withConfirm?: Boolean) => {
  const { t } = useTranslation();
  const { isImpersonating, actingAs } = useProfile();
  const dialog = useDialog();
  const isMobile = useIsMobile();
  const { open } = useChatPopup();

  const openSendMail = useCallback(() => {
    if (!actingAs || !p.userId) {
      return;
    }
    dialog.open({
      body: (
        <SendMail
          onSend={dialog.close}
          onCancel={dialog.close}
          professional={actingAs}
          recipientIds={[p.userId]}
        />
      ),
      title: (
        <Box display="flex" alignItems="center">
          <Box mr={2} display="flex"><MailIcon /></Box>
          <Typography>{getProfessionalFullName(p, t)}</Typography>
        </Box>
      ),
      maxWidth: 'md',
      fullWidth: true,
      fullScreen: isMobile,
    })
  }, [actingAs, dialog, t, p, isMobile]);

  const allActions = useMemo<ProfileDialActionListItem[]>(() => [
    {
      excludeIf: isImpersonating,
      statuses: [NetworkStatus.Approved],
      action: {
        icon: <MailIcon />,
        name: t('menu:mail'),
        callback: openSendMail,
      },
    },
    {
      excludeIf: isImpersonating || isOrganizationSa(p),
      statuses: [NetworkStatus.Approved],
      action: {
        icon: <ChatIcon />,
        disabled: !currentProfessionalCanChat(actingAs, p),
        name: currentProfessionalCanChatLabel(actingAs, p, t),
        callback: () => open(p.user!),
      },
    },
  ], [t, isImpersonating, openSendMail, p, open, actingAs]);

  return useMemo(() => (
    allActions
      .filter(({ roles, excludeIf }) => {
        if (excludeIf) return false;

        const roleStatusOk = !roles
          || (roles as string[]).includes(p.__typename!);

        return roleStatusOk;
      })
      .map(a => a.action)
  ), [allActions, p])
}

