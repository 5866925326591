import {
  RequestAuthorDataFragment,
  RequestDataFragment, RequestMessageDataFragment,
  RequestType,
} from '../generated/graphql';
import { Event, Healing, Help, LocalHospital } from '@mui/icons-material';
import { TFunction } from 'i18next';
import { isPatient } from './profiles';
import { capitalize } from '@hu-care/react-utils';
import { DoctorIcon } from './icons';
import { CustomIcon } from '../views/components/custom-icon';

export interface MessageAuthor {
  role: string;
  fullName: string;
  userId?: string;
  roleCode: string;
  actualAuthor?: {
    role: string;
    fullName: string;
    userId?: string;
    roleCode: string;
  };
}

const requestIconMap = {
  [RequestType.DrugsExams]: <Healing/>,
  [RequestType.HealthAdvice]: <LocalHospital/>,
  [RequestType.Informations]: <Help/>,
  [RequestType.MedicRequest]: <CustomIcon Icon={DoctorIcon} />,
  [RequestType.AppointmentRequest]: <Event/>,
}

export function getRequestIcon(request: RequestDataFragment) {
  const type = request.type;
  return requestIconMap[type] || requestIconMap[RequestType.Informations];
}

export function getRequestAuthor(author: RequestAuthorDataFragment, t: TFunction): MessageAuthor {
  if (isPatient(author)) {
    return {
      role: capitalize(t('patient')),
      fullName: `${author.name} ${author.surname}`,
      roleCode: 'Patient',
    }
  }
  return {
    role: capitalize(t((author as any).role.toLowerCase())),
    fullName: 'user' in author && author.user ? `${author.user.traits.name} ${author.user.traits.surname}` : `${author.name} ${author.surname}`,
    userId: 'user' in author ? author.user?.id : undefined,
    roleCode: (author as any).role,
  }
}

export function getRequestSender(request: RequestDataFragment, t: TFunction) {
  return getRequestAuthor(request.sender, t);
}

export function getRequestRecipient(request: RequestDataFragment, t: TFunction) {
  return getRequestAuthor(request.recipient, t);
}

/**
 * Return a tuple of the request actors
 * First element is the current user actor
 * Second element is the counterpart:
 *
 * const [currentUser, counterpart] = getRequestActors(actingAs.id, request);
 * @param currentId
 * @param request
 */
export function getRequestActors(currentId?: string, request?: RequestDataFragment) {
  if (!currentId || !request) {
    return [undefined, undefined];
  }
  const { sender, recipient } = request;
  if (isPatient(sender)) {
    return [recipient, sender];
  }
  if (isPatient(recipient)) {
    return [sender, recipient];
  }
  if (recipient.id === currentId) {
    return [recipient, sender];
  }
  return [sender, recipient];
}

export function getRequestType(currentId?: string, request?: RequestDataFragment): 'sent' | 'received' {
  if (!currentId || !request) {
    return 'sent';
  }
  const { sender, recipient } = request;
  if (isPatient(sender)) {
    return 'received';
  }
  if (isPatient(recipient)) {
    return 'sent';
  }
  return recipient.id === currentId ? 'received' : 'sent';
}

export function getRequestMessageAuthor(message: RequestMessageDataFragment, t: TFunction): MessageAuthor | undefined {
  const { author, creator } = message;
  if (!author) {
    return undefined;
  }
  if (isPatient(author)) {
    return getRequestAuthor(author, t);
  }

  const messageAuthor = {
    role: capitalize(t((author as any).role.toLowerCase())),
    fullName: 'user' in author && author.user ? `${author.user.traits.name} ${author.user.traits.surname}` : `${author.name} ${author.surname}`,
    userId: 'user' in author && author.user ? author.user.id : undefined,
    roleCode: (author as any).role,
  }
  const actualAuthor = {
    role: capitalize(t('assistant')),
    fullName: `${creator?.traits.name || ''} ${creator?.traits.surname || ''}`,
    userId: creator?.id,
    roleCode: 'Assistant',
  }

  const isImpersonating = 'user' in author && creator?.id !== author.user?.id;

  return {
    ...messageAuthor,
    ...isImpersonating && { actualAuthor },
  }
}
