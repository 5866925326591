import { LazyQueryHookOptions, useLazyQuery } from '@apollo/client';
import { get } from 'lodash-es';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce, useEffectOnce } from 'react-use';
import { PaginationInfo } from '../generated/graphql';

type BaseDataFrag = {
  __typename?: string;
  id: string;
}

type BaseQuery = {
  __typename?: string;
}

type BaseVars = {
  [key: string]: any;
}

type BasePaginatedResponse<DataFrag extends BaseDataFrag> = BaseQuery & {
  items: DataFrag[];
  paginationInfo: PaginationInfo;
}

export interface UseSearchProps<Vars extends BaseVars, Query extends BaseQuery> {
  searchDocument: import('graphql').DocumentNode;
  variables?: Partial<Vars>;
  searchKey?: keyof Vars | (keyof Vars)[];
  dataKey: keyof Omit<Query, '__typename'>;
  paginated?: boolean;
  toExclude?: Pick<BaseDataFrag, 'id'>[];
  allowEmptySearch?: boolean;
  canSubmit?: (value: string) => boolean | string;
  runOnMount?: boolean;
  queryOptions?: LazyQueryHookOptions<Query, Vars>;
  clearOnEmpty?: boolean;
}

const paginatedKey = 'items';

export const useSearch = <DataFrag extends BaseDataFrag, Query extends BaseQuery, Vars extends BaseVars>(
  {
    searchDocument,
    dataKey,
    searchKey = 'name',
    variables,
    toExclude,
    paginated,
    canSubmit,
    runOnMount,
    queryOptions,
    allowEmptySearch: allowEmptySearchMaster,
    clearOnEmpty = true,
  }: UseSearchProps<Vars, Query>,
) => {
  const [options, setOptions] = useState<DataFrag[]>([]);
  const [search, setSearch] = useState<string>('');
  const [paginationInfo, setPaginationInfo] = useState<PaginationInfo | null>(null);

  const [
    searchQuery,
    { loading, data, ...rest },
  ] = useLazyQuery<Query, Vars>(searchDocument, queryOptions);

  const [localLoading, setLocalLoading] = useState(loading);

  useEffect(() => {
    setLocalLoading(loading);
  }, [loading, setLocalLoading]);

  useEffect(() => {
    if (!loading) {
      const fullKey = paginated ? `${dataKey}.${paginatedKey}` : dataKey;
      const foundData = data ? get(data, fullKey) as unknown as DataFrag[] || [] : [];
      const toExcludeIds = (toExclude || []).map(ex => ex.id);

      setLocalLoading(false);
      setOptions(foundData.filter(d => !toExcludeIds.includes(d.id)));

      if (paginated && data) {
        const pagInfo = (get(data, dataKey) as unknown as BasePaginatedResponse<DataFrag>)?.paginationInfo;
        pagInfo && setPaginationInfo(pagInfo);
      }
    }
  }, [data, loading, toExclude, paginated, dataKey, rest.variables, setPaginationInfo]);

  const searchFn = useCallback((allowEmptySearch?: boolean) => {
    if (search.trim() || allowEmptySearch) {
      let value = `%${search}%`;
      if (canSubmit) {
        const result = canSubmit(search);
        if (!result) {
          return;
        }
        if (typeof result === 'string') {
          value = result;
        }
      }

      const searchKeys = !Array.isArray(searchKey) ? [searchKey] : searchKey;

      const vars = searchKeys.reduce((acc, curr) => ({
        ...acc,
        [curr]: value,
      }), variables || {}) as Vars;

      searchQuery({
        variables: vars,
      });
    } else if (clearOnEmpty) {
      setOptions([]);
    }
  }, [search, canSubmit, searchKey, variables, searchQuery, clearOnEmpty]);

  useDebounce(() => {
    searchFn(allowEmptySearchMaster);
  }, 300, [searchFn, allowEmptySearchMaster]);

  useEffectOnce(() => {
    if (runOnMount) {
      searchFn(true);
    }
  });

  return useMemo(() => ({
    ...rest,
    loading: localLoading,
    options,
    search,
    setSearch,
    paginationInfo,
  }), [
    rest,
    options,
    setSearch,
    search,
    localLoading,
    paginationInfo,
  ]);
}
