import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom';
import './index.css'
import { App } from './App'
import { SnackbarProps, StoreProvider } from '@hu-care/react-ui-store';
import { RoleThemeProvider } from './themes';
import { AuthProvider } from './contexts';
import { RouteAdapter } from './routes/routes-adapter';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { LocalizationProvider } from '@mui/lab';
import { it } from 'date-fns/esm/locale';
import { setupLogger } from './utils/logger';
import './i18n';
import { CssBaseline } from '@mui/material';
import { QueryParamProvider } from 'use-query-params';
import { setupErrorReport } from './utils/error-report';
import { StyledEngineProvider } from '@mui/material/styles';

// Set this to override the auth and force this user to be used in all API calls
// window.userId = '4e4630aa-0431-458a-ba35-04ded360162f'; // Sejedjamaladdin Nekoofar
// window.userId = '06ba04e2-4abf-4f04-89c8-4821ea8d5088'; // GIACM dev
// window.userId = '1b6bbdea-a57c-4a58-b8be-e35b147a329a'; // Bonci dev
// window.userId = '38005c6c-bd81-4e06-8adf-cd9a3c7b8d84' // Andesign dev

const snackbarProps: SnackbarProps = {
  preventDuplicate: true,
  disableWindowBlurListener: true,
  anchorOrigin: {
    horizontal: 'right',
    vertical: 'bottom',
  },
};

setupLogger()
.then(setupErrorReport)
.then(({ ErrorBoundary, props }) => {
  ReactDOM.render(
    <ErrorBoundary {...props}>
      <StoreProvider snackbarProps={snackbarProps}>
        <StyledEngineProvider injectFirst>
          <RoleThemeProvider>
            <CssBaseline/>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={it}>
              <BrowserRouter basename="/">
                <QueryParamProvider ReactRouterRoute={RouteAdapter}>
                  <AuthProvider skipAll={!!window.userId && process.env.NODE_ENV === 'development'}>
                    <App/>
                  </AuthProvider>
                </QueryParamProvider>
              </BrowserRouter>
            </LocalizationProvider>
          </RoleThemeProvider>
        </StyledEngineProvider>
      </StoreProvider>
    </ErrorBoundary>,
    document.getElementById('root'),
  );
});

