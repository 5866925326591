import { FC, useEffect } from 'react';
import { format } from '@hu-care/react-utils';
import {
  Box,
  Typography,
  Grid,
  Chip,
  Tooltip,
  Link,
  Theme,
} from '@mui/material';
import { getRequestMessageAuthor } from '../../../utils/request';
import { useTranslation } from 'react-i18next';
import { useProfile, useAuth } from '../../../contexts';
import { useReadRequestMessage } from '../../../hooks/requests/useReadRequestMessage';
import { Check as CheckIcon, AttachFile as AttachFileIcon } from '@mui/icons-material';
import { createStyles, makeStyles } from '@mui/styles';
import {
  RequestMessageDataFragment,
} from '../../../generated/graphql';
import { getRoleColor } from '../../../themes';

export type RequestMessageBubbleProps = {
  message?: RequestMessageDataFragment;
  float?: 'left' | 'right';
  showAuthor?: boolean;
  showDateTime?: boolean;
}

export type RequestMessageBubbleStyleProps = {
  role?: string;
  float?: 'left' | 'right';
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    messageBox: (props: Partial<RequestMessageBubbleStyleProps>) => ({
      [props.float === 'left' ? 'borderLeft' : 'borderRight']: `3px solid ${getRoleColor(props.role!).main}`,
      float: props.float,
      width: 'fit-content',
      maxWidth: '90%',
      opacity: '1',
      minWidth: '40%',
      padding: '4pt 8pt',
      position: 'relative',
      whiteSpace: 'pre-line',
    }),
    messageAuthor: (props: Partial<RequestMessageBubbleStyleProps>) => ({
      fontWeight: theme.typography.fontWeightBold,
      textAlign: props.float,
      [props.float === 'right' ? 'marginLeft' : 'marginRight']: 'auto',
      opacity: '1',
      maxWidth: '80vw',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: getRoleColor(props.role!).main,
      whiteSpace: 'nowrap',
    }),
    transparentBg: (props: Partial<RequestMessageBubbleStyleProps>) => ({
      background: getRoleColor(props.role!).main,
      opacity: '0.2',
      width: '100%',
      position: 'absolute',
      height: '100%',
      display: 'inline-block',
      left: 0,
      top: 0,
      [props.float === 'left' ? 'borderTopRightRadius' : 'borderTopLeftRadius']: '4px',
      [props.float === 'left' ? 'borderBottomRightRadius' : 'borderBottomLeftRadius']: '4px',
    }),
    attachmentIcon: {
      padding: '2px 0',
      height: '14pt !important',
    },
    receivedAt: (props: Partial<RequestMessageBubbleStyleProps>) => ({
      display: 'flex',
      marginTop: theme.spacing(0.5),
      justifyContent: props.float === 'left' ? 'flex-start' : 'flex-end',
      [props.float === 'left' ? 'marginLeft' : 'marginRight']: theme.spacing(1),
      '& svg': {
        marginRight: theme.spacing(1),
        fontSize: '14pt',
      },
    }),
    chip: {
      '& .MuiChip-label': {
        maxWidth: '32ch',
        paddingLeft: '6px !important',
        paddingRight: '16px !important',
      },
    },
  }),
);

const formatDateTime = (dateAsString: string | null) => {
  if (!dateAsString) return null;
  return format(new Date(dateAsString), 'dd/MM/yyyy HH:mm');
}

export const RequestMessageBubble: FC<RequestMessageBubbleProps> = ({
    message,
    float = 'left',
    showAuthor = true,
    showDateTime = true,
  }) => {
  const classes = useStyles({ role: message?.author?.__typename, float });

  const { t } = useTranslation();
  const { actingAs } = useProfile();
  const { readMessage } = useReadRequestMessage();
  const author = (message?.author && actingAs) ? getRequestMessageAuthor(message, t) : undefined;
  const { getUser } = useAuth();
  const user = getUser();

  const isHimSelf = user?.sub === (author?.actualAuthor?.userId || author?.userId);

  const sentByKey = author?.actualAuthor ? 'message-sent-by-as' : 'message-sent-by';
  const transAuthor = {
    ...author,
    fullName: isHimSelf ? t('request:message-author-me') : author?.fullName,
    actualAuthor: {
      ...author?.actualAuthor,
      fullName: isHimSelf ? t('request:message-author-me') : author?.actualAuthor?.fullName,
    },
  };

  useEffect(() => {
    if (
      !actingAs
      || !message
      || (actingAs?.id === message?.author?.id)
      || message.readAt
    ) {
      return;
    }
    readMessage(message.id);
  }, [message, actingAs, readMessage]);

  return (<Box pt={0.5}>
    <Grid container>
      {showAuthor && <Grid item xs={12}>
        <Typography variant="subtitle2" className={classes.messageAuthor}>
          {t(`request:${sentByKey}`, transAuthor)}
        </Typography>
      </Grid>}

      <Grid item xs={12}>
        <Tooltip title={message ? (formatDateTime(message.createdAt) || '') : ''} placement="right">
           <Typography className={classes.messageBox}>
            <Box component="span" sx={{ position: 'relative', zIndex: 100 }}>{message?.message}</Box>
            <span className={classes.transparentBg}/>
          </Typography>
        </Tooltip>
      </Grid>

      { message?.attachments &&
        <Grid item xs={12}>
          <Box mt={1}>
            <Grid container spacing={1} justifyContent={float === 'left' ? 'flex-start' : 'flex-end'}>
              { message?.attachments?.map((attachment, i) => (
                  <Grid item key={i} >
                    <Link underline="hover" href={attachment.url} target="_blank">
                      <Chip className={classes.chip} avatar={<AttachFileIcon className={classes.attachmentIcon} />}
                        label={attachment.name}
                      />
                    </Link>
                  </Grid>
                ))
              }
            </Grid>
          </Box>
        </Grid>
      }

      {showDateTime && <Grid item xs={12}>
        <span className={classes.receivedAt}>
          <CheckIcon />
          <Typography variant="body2">
            {message?.createdAt && formatDateTime(message.createdAt)}
          </Typography>
        </span>
      </Grid>}
    </Grid>
  </Box>);
};
