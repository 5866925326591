import { useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  PendingInvitesDocument,
  PendingInvitesQuery,
  SentInvitesQuery,
  SentInvitesDocument,
  DeleteInviteMutation,
  DeleteInviteMutationVariables,
  DeleteInviteDocument,
  ProfessionalBasicDataFragment,
  InviteType,
} from '../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { useConfirmDialog } from '../useConfirmDialog';
import { useToast } from '@hu-care/react-ui-store';
import { useHandleError } from '../useHandleError';
import { useProfile } from '../../contexts';

export const usePendingInvites = () => {
  const { orgAcceptableProfile } = useProfile();
  const { data, ...rest } = useQuery<PendingInvitesQuery>(PendingInvitesDocument, {
    fetchPolicy: 'network-only',
  });

  const visibleInvites = useMemo(() => {
    const pendingInvites = data?.pendingInvites || [];
    return orgAcceptableProfile
      ? pendingInvites
      : pendingInvites.filter(i => i.type === InviteType.Assistant);
  }, [data, orgAcceptableProfile]);

  return useMemo(() => ({
    pendingInvites: visibleInvites,
    data,
    ...rest,
  }), [
    visibleInvites,
    data,
    rest,
  ]);
}

export const useSentInvites = (profile?: ProfessionalBasicDataFragment | null) => {
  const { data, ...rest } = useQuery<SentInvitesQuery>(SentInvitesDocument, {
    variables: {
      medicId: profile!.id,
    },
    skip: !profile,
    fetchPolicy: 'network-only',
  });

  return useMemo(() => ({
    sentInvites: data?.sentInvites || [],
    ...rest,
  }), [
    data,
    rest,
  ]);
}

export const useDeleteInvite = (id: string, withConfirm?: Boolean) => {
  const { t } = useTranslation();
  const dialog = useConfirmDialog();
  const toast = useToast();
  const handleError = useHandleError(false);

  const [deleteInvite] = useMutation<DeleteInviteMutation, DeleteInviteMutationVariables>(DeleteInviteDocument);

  return useMemo(() => {
    const action = async () => {
      try {
        await deleteInvite({
          variables: {
            id,
          },
          update: (cache, mutationResult) => {
            if (!mutationResult.data?.deleteInvite) {
              return;
            }
            cache.evict({
              id: cache.identify({ __typename: 'Invite', id }),
              broadcast: true,
            });
            cache.gc();
          },
        });
        toast.success(t('invite:delete-invite-success'));
      } catch (e) {
        handleError(e, 'errors:cannot-delete-invite');
      } finally {
        dialog.close()
      }
    };

    if (withConfirm) {
      return () => dialog.confirm({
        onConfirm: action,
        title: t<string>('invite:confirm-delete'),
      });
    } else {
      return action;
    }
  }, [deleteInvite, handleError, withConfirm, id, dialog, t, toast]);
}
