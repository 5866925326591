import { memo, useCallback } from 'react';
import { useChatPopup } from '../../../contexts';
import { ChatHeader } from './chat-header';
import { SingleChat } from './single-chat';
import { Paper, Portal, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useChat } from '@hu-care/chat-client/dist/react';
import { useSearchParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  popup: {
    position: 'fixed',
    bottom: 0,
    right: 10,
    height: 450,
    width: 300,
    backgroundColor: 'white',
    zIndex: 1000000,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      height: '100vh',
      width: '100vw',
      right: 0,
      left: 0,
      bottom: 0,
      top: 0,
    },
  },
  singleChat: {
    height: 450 - 48, // toolbar height
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vh - 48px)',
    },
  },
}))

export const ChatPopup = memo(() => {
  const { close, destUser, error } = useChatPopup();
  const { closeChat } = useChat();
  const classes = useStyles();
  const [search, setSearch] = useSearchParams();

  const action = search.get('action');

  const onCloseChat = useCallback(() => {
    close();
    closeChat();
    if (action && action.startsWith('chat|')) {
      const newQuery = new URLSearchParams(search);
      newQuery.delete('action');
      setSearch(newQuery, { replace: true });
    }
  }, [close, closeChat, action, search, setSearch]);

  if (!destUser) {
    return null;
  }

  return (
    <Portal container={document.body}>
      <Paper className={classes.popup}>
        <ChatHeader destUser={destUser} onClose={onCloseChat}/>
        <div className={classes.singleChat}>
          <SingleChat destUserId={destUser.id} error={error}/>
        </div>
      </Paper>
    </Portal>
  )
});
