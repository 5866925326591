import { PatientProfessionalDataFragment, PatientProfessionalStatus } from '../../../generated/graphql';
import { useDialog } from '@hu-care/react-ui-store';
import { useIsMobile } from '../../useIsMobile';
import { useMemo } from 'react';
import { InvitePatientDialog } from '../../../views/components/patients/invite-patient-dialog';
import { Box, Typography } from '@mui/material';
import { MailIcon } from '../../../utils/icons';
import { getPatientFullName } from '../../../utils/profiles';

export const useOpenInvitePatient = (patientProfessional: PatientProfessionalDataFragment) => {
  const dialog = useDialog();
  const isMobile = useIsMobile();

  return useMemo(() => {
    if (patientProfessional.status !== PatientProfessionalStatus.Inactive && patientProfessional.patient.users.length) {
      return null;
    }

    return () => {
      dialog.open({
        body: (
          <InvitePatientDialog
            patientProfessional={patientProfessional}
            onClose={() => dialog.close()}
          />
        ),
        title: (
          <Box display="flex" alignItems="center">
            <Box mr={2} display="flex"><MailIcon /></Box>
            <Typography>{getPatientFullName(patientProfessional.patient)}</Typography>
          </Box>
        ),
        maxWidth: 'sm',
        fullWidth: true,
        fullScreen: isMobile,
      })
    }
  }, [isMobile, dialog, patientProfessional]);
}
