import { useProfile } from '../../contexts/profile.context';
import { useHandleError } from '../useHandleError';
import { useMutation } from '@apollo/client';
import {
  AcceptReportDocument,
  AcceptReportMutation,
  AcceptReportMutationVariables, DeclineReportDocument, DeclineReportMutation, DeclineReportMutationVariables,
  ReportSharedWith,
} from '../../generated/graphql';
import { useCallback } from 'react';
import { useToast } from '@hu-care/react-ui-store';
import { useTranslation } from 'react-i18next';
import { useConfirmDialog } from '../useConfirmDialog';
import { useGroupMedicine } from '../useGroupMedicine';

export const useAcceptShare = () => {
  const { actingAs } = useProfile();
  const handleError = useHandleError();
  const myGroupMedicine = useGroupMedicine();

  const [
    acceptMutation,
    { loading },
  ] = useMutation<AcceptReportMutation, AcceptReportMutationVariables>(AcceptReportDocument);

  const onAccept = useCallback(async (sharedReport: Pick<ReportSharedWith, '__typename' | 'reportId' | 'patientProfessionalId'>) => {
    if (!actingAs) {
      return;
    }
    try {
      const result = await acceptMutation({
        variables: {
          professionalId: actingAs.id,
          reportId: sharedReport.reportId,
          groupMedicineId: myGroupMedicine?.professional?.id,
        },
      });
      return result.data?.acceptShare;
    } catch (e) {
      handleError(e);
    }
  }, [actingAs, acceptMutation, myGroupMedicine?.professional?.id, handleError]);

  return {
    onAccept,
    loading,
  }
}

export const useDeclineShare = () => {
  const { actingAs } = useProfile();
  const handleError = useHandleError();
  const toast = useToast();
  const { t } = useTranslation();
  const dialog = useConfirmDialog();
  const myGroupMedicine = useGroupMedicine();

  const [
    declineMutation,
    { loading },
  ] = useMutation<DeclineReportMutation, DeclineReportMutationVariables>(DeclineReportDocument);

  const onDecline = useCallback(async (sharedReport: Pick<ReportSharedWith, '__typename' | 'reportId' | 'patientProfessionalId'>) => {
    if (!actingAs) {
      return;
    }
    try {
      const result = await declineMutation({
        variables: {
          professionalId: actingAs.id,
          reportId: sharedReport.reportId,
          groupMedicineId: myGroupMedicine?.professional?.id,
        },
        update: (cache, mutationResult) => {
          if (!mutationResult.data?.declineShare) {
            return;
          }
          cache.evict({
            id: cache.identify(sharedReport),
            broadcast: true,
          });
        },
      });
      toast.success(t('report:decline-success'));
      return result.data?.declineShare;
    } catch (e) {
      handleError(e, 'errors:cannot-decline-report');
    }
  }, [actingAs, declineMutation, handleError, myGroupMedicine?.professional?.id, t, toast]);

  const onDeclineWithConfirm = useCallback((sharedReport: Pick<ReportSharedWith, '__typename' | 'reportId' | 'patientProfessionalId'>) => {
    return new Promise<any>((resolve, reject) => {
      dialog.confirm({
        title: t('report:confirm-revoke'),
        onConfirm: () => {
          onDecline(sharedReport)
            .then(res => {
              resolve(res);
            })
            .catch(() => {
              reject();
            })
            .finally(() => {
              dialog.close();
            });
        },
      });
    });
  }, [onDecline, dialog, t]);

  return {
    onDecline,
    onDeclineWithConfirm,
    loading,
  }
}
