import { ApolloClient, from, NormalizedCacheObject } from '@apollo/client';
import { typeDefs } from './localSchema';
import { authLink, httpLink } from './links';
import cache from './cache';
import { errorLink } from './links/error-link';

export interface MakeClientOptions {
  tokenGetter(): string;
  refreshToken(): Promise<any>;
  onRefreshFail: (err?: Error) => void;
  onNetworkError?: (err: Error) => void;
}

export function makeClient(options: MakeClientOptions): ApolloClient<NormalizedCacheObject> {
  console.log('EndPoint:', process.env.REACT_APP_API_ENDPOINT);

  const client = new ApolloClient({
    link: from([
      errorLink(options.refreshToken, options.onRefreshFail, options.onNetworkError),
      authLink(options.tokenGetter),
      httpLink(options.tokenGetter),
    ]),
    cache,
    typeDefs,
    resolvers: {},
    connectToDevTools: true,
  });

  return client;
}
