import { FC, useState, useCallback, useRef } from 'react';
import { Grid } from '@mui/material';
import {
  EditorProps,
  EditorState,
  ContentState,
  RichUtils,
  convertToRaw,
  convertFromRaw,
  convertFromHTML,
} from 'draft-js';

import {
  ItalicButton,
  BoldButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  UnorderedListButton,
  OrderedListButton,
} from 'draft-js-buttons';

import Editor from 'draft-js-plugins-editor';
import createHashtagPlugin from '@draft-js-plugins/hashtag';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import createToolbarPlugin, { Separator } from 'draft-js-static-toolbar-plugin';

import draftToHtml from 'draftjs-to-html';

import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/hashtag/lib/plugin.css';
import 'draft-js-static-toolbar-plugin/lib/plugin.css';

import './draft-editor.css';
import { useEffect } from 'react';

const hashtagPlugin = createHashtagPlugin();
const linkifyPlugin = createLinkifyPlugin();

export const DraftEditor: FC<EditorProps> = ({ editorState, onChange }) => {
  const handleKeyCommand = useCallback((command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  }, [onChange]);

  const editorRef = useRef<Editor | null>(null);

  const [[ plugins, Toolbar ]] = useState(() => {
    const toolbarPlugin = createToolbarPlugin();
    const { Toolbar } = toolbarPlugin;

    return [
      [
        linkifyPlugin,
        hashtagPlugin,
        toolbarPlugin,
      ],
      Toolbar,
    ];
  });

  return <div>
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item>
            <Toolbar>
              {
                (externalProps: any) => (
                  <>
                    <BoldButton {...externalProps} />
                    <ItalicButton {...externalProps} />
                    <Separator {...externalProps} />
                    <HeadlineOneButton {...externalProps} />
                    <HeadlineTwoButton {...externalProps} />
                    <HeadlineThreeButton {...externalProps} />
                    <Separator {...externalProps} />
                    <UnorderedListButton {...externalProps} />
                    <OrderedListButton {...externalProps} />
                  </>
                )
              }
            </Toolbar>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <div onClick={() => {
          editorRef.current?.focus();
        }}>
          <Editor
            ref={editorRef}
            editorState={editorState}
            onChange={onChange}
            plugins={plugins}
            handleKeyCommand={handleKeyCommand}
          />
        </div>

      </Grid>
    </Grid>

  </div>;
};

export const getValidEditorState = (initialRawEditorState?: any) => {
  try {
    return convertFromRaw(initialRawEditorState);
  } catch (e) {
    return undefined;
  }
};

export const getValidEditorStateFromHtml = (initialRawEditorState?: any) => {
  try {
    const blocksFromHTML = convertFromHTML(initialRawEditorState);
    return ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
  } catch (e) {
    return undefined;
  }
};

export const useEditorState = (validEditorState?: any) => {
  const [editorState, setEditorState] = useState(() => {
    if (validEditorState) {
      return EditorState.createWithContent(validEditorState);
    } else {
      return EditorState.createEmpty();
    }
  });

  const getRawEditorState = useCallback(() => {
    return convertToRaw(editorState.getCurrentContent());
  }, [editorState]);

  const getHtmlFromEditorState = useCallback(() => {
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    return draftToHtml(
      rawContentState,
    );
  }, [editorState]);

  return {
    editorState,
    setEditorState,
    getHtmlFromEditorState,
    getRawEditorState,
  };
};

export type HtmlEditorProps = {
  initialValue?: string;
  onChange: (content: string) => void;
}

export const HtmlEditor: FC<HtmlEditorProps> = ({ initialValue, onChange }) => {
  const { editorState, setEditorState, getHtmlFromEditorState } = useEditorState(
    getValidEditorStateFromHtml(initialValue || ''),
  );

  useEffect(() => {
    const newContent = getHtmlFromEditorState();
    onChange(newContent);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorState, getHtmlFromEditorState]);

  return <DraftEditor editorState={editorState} onChange={setEditorState} />
}
