import { useMutation } from '@apollo/client';
import {
  AddRequestMessageDocument,
  AddRequestMessageMutation,
  AddRequestMessageMutationVariables,
  RequestDataFragment,
  RequestResult,
} from '../../generated/graphql';

import { useFieldArray, useForm } from 'react-hook-form';
import { useCallback, useMemo } from 'react';
import { useProfile } from '../../contexts/profile.context';
import { useToast } from '@hu-care/react-ui-store';
import { useTranslation } from 'react-i18next';
import { UploadDestinations } from '../../utils/constants';
import { useResolveRequest } from './useResolveRequest';
import { useHandleError } from '../useHandleError';

export interface AnswerRequestForm {
  message: string;
  result: RequestResult | null | '';
  files: { file: File }[];
}

export const useAnswerRequest = (request: RequestDataFragment) => {
  const toast = useToast();
  const { t } = useTranslation();
  const { onResolve } = useResolveRequest(request.id)

  const [
    addMessage,
    { loading },
  ] = useMutation<AddRequestMessageMutation, AddRequestMessageMutationVariables>(AddRequestMessageDocument, {
    update: (cache, mutationResult) => {
      if (!mutationResult.data?.answerRequestAsProfessional) {
        return;
      }
      cache.modify({
        id: cache.identify({ __typename: 'Request', id: request.id }),
        fields: {
          messages: (value, details) => {
            if (!mutationResult.data?.answerRequestAsProfessional) {
              return value;
            }
            return [
              ...value,
              details.toReference(mutationResult.data.answerRequestAsProfessional),
            ];
          },
        },
      });
    },
  });

  const { actingAs } = useProfile();

  const form = useForm<AnswerRequestForm>({
    defaultValues: {
      message: '',
      result: request.result || '',
    },
    mode: 'onChange',
  });

  const files = useFieldArray({
    control: form.control,
    name: 'files',
  });

  const handleError = useHandleError(false);

  const answerRequest = useCallback(async (data: AnswerRequestForm) => {
    if (!actingAs) {
      return;
    }

    let hasSentMessage = false;

    if (data.message) {
      try {
        await addMessage({
          variables: {
            requestId: request.id,
            medicId: actingAs.id,
            message: {
              message: data.message,
              attachments: files.fields.length ? files.fields.map(({ file }) => ({
                file: file,
                destination: UploadDestinations.Requests,
              })) : undefined,
            },
          },
        });
        toast.success(t('request:add-request-message-success'));
        hasSentMessage = true;
      } catch (e) {
        handleError(e, 'errors:cannot-add-request-message');
      }
    }

    const dataResult = data.result || null;

    if (dataResult !== request.result) {
      onResolve(data.result, !hasSentMessage);
    }
  }, [actingAs, handleError, addMessage, onResolve, request, t, toast, files]);

  return useMemo(() => ({
    answerRequest,
    form,
    files,
    loading,
  }), [
    answerRequest,
    form,
    loading,
    files,
  ]);
}
