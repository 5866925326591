
export let logger: import('@datadog/browser-logs').Logger;

const stubLogger = new Proxy(Object.create(null), {
  get(): any {
    return function (...args: any[]) {/**/}
  },
});

export async function setupLogger() {
  if (process.env.REACT_APP_DD_TOKEN) {
    const { datadogLogs } = await import('@datadog/browser-logs');
    const { version } = await import('../../package.json');
    datadogLogs.init({
      clientToken: process.env.REACT_APP_DD_TOKEN!,
      site: 'datadoghq.eu',
      forwardErrorsToLogs: true,
      sampleRate: 100,
      env: process.env.NODE_ENV,
      service: 'csw3-medic',
      version,
    });
    logger = datadogLogs.logger;
  } else {
    logger = stubLogger;
  }
}

export default logger;
