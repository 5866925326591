import ReactHtmlParser from 'react-html-parser';
import { Text, View } from '@react-pdf/renderer';
import { cloneElement } from 'react';
import { splitStringIntoChunks } from './strings';

export const htmlToPdf = (taskDescription: string | null, maxLineLength?: number) => {
  const returnContentConst: any[] = [];
  if (taskDescription) {
    const parsedHtml = ReactHtmlParser(taskDescription);
    parsedHtml.forEach(element => {

      if (typeof element === 'string') {
        splitStringIntoChunks(element, maxLineLength).forEach(chunk => {
          returnContentConst.push(<Text>{chunk}</Text>);
        })
        return;
      }

      const type = element.type;

      if (type === 'br') {
        returnContentConst.push(<View/>);
        return;
      }

      if (!element.props?.children) {
        return;
      }

      element.props.children.forEach((_content: string | any, i: number) => {
        splitStringIntoChunks(_content, maxLineLength).forEach(content => {
          switch (type) {
            case 'h1':
              returnContentConst.push(<Text style={{
                fontSize: '24px',
                fontWeight: 'bold',
                marginBottom: '4px',
              }}>{content}</Text>);
              break;
            case 'h2':
              returnContentConst.push(<Text style={{
                fontSize: '20px',
                fontWeight: 'bold',
                marginBottom: '4px',
              }}>{content}</Text>);
              break;
            case 'h3':
              returnContentConst.push(<Text style={{
                fontSize: '16px',
                fontWeight: 'bold',
                marginBottom: '4px',
              }}>{content}</Text>);
              break;
            case 'ul':
              returnContentConst.push(<Text>- {content}</Text>);
              break;
            case 'ol':
              returnContentConst.push(<Text>{i + 1}. {content}</Text>);
              break;
            default:
              returnContentConst.push(<Text style={{
                fontWeight: _content.type === 'strong' ? 'bold' : undefined,
                fontStyle: _content.type === 'em' ? 'italic' : undefined,
                textDecoration: _content.type === 'ins' ? 'underline' : undefined,
              }}>{content}</Text>);
              break;
          }
        })
      })
    });

    return (
      <View>
        {returnContentConst.map((r, i) => cloneElement(r, { key: i }))}
      </View>
    );
  } else {
    return null;
  }
}
