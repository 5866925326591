import { Box, CircularProgress } from '@mui/material';
import { FC } from 'react';


type CircularLoadingProps = {
  minHeight?: number;
}

const CircularLoading: FC<CircularLoadingProps> = ({ minHeight }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      py={2}
      sx={{ minHeight: (minHeight)? minHeight : 'none' }}
    >
      <CircularProgress color="primary" />
    </Box>
  )
}

export default CircularLoading;