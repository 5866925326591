import { useProfile } from '../../contexts/profile.context';
import { useQuery } from '@apollo/client';
import { ClinicsDocument, ClinicsQuery, ClinicsQueryVariables } from '../../generated/graphql';

export const useClinics = () => {
  const { actingAs } = useProfile();
  return useClinicsByProfessionalId(actingAs?.id);
}

export const useClinicsByProfessionalId = (id?: string) => {
  const { data, loading, error } = useQuery<ClinicsQuery, ClinicsQueryVariables>(ClinicsDocument, {
    variables: {
      medicId: id!,
    },
    skip: !id,
  });

  if (error) {
    throw error;
  }

  return {
    clinics: data?.clinics,
    loading,
  }
}
