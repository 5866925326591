import { FC, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  FormControl,
  Button,
  Grid,
  Input,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Checkbox,
  Select,
  Typography,
  Theme, SelectChangeEvent,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import { OrganizationProfessionalsDataFragment, OrganizationProfessionalPersonalDataFragment } from '../../../generated/graphql';
import { OrganizationProfessionalItem } from './organization-professional-item';
import { uniq } from 'lodash-es';
import { getRoleIcon, isOrganizationSa } from '../../../utils/profiles';
import { InviteToOrganization } from './invite-to-organization';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { useDialog } from '@hu-care/react-ui-store';
import { Add } from '@mui/icons-material';
import { useProfile } from '../../../contexts';
import { getRoleColor } from '../../../themes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    centered: {
      alignSelf: 'center',
    },
    profileIcon: {
      fill: theme.palette.text.secondary,
      width: 20,
    },
    multiselect: {
      minWidth: 100,
    },
    listItemIcon: {
      minWidth: 32,
    },
    menuItem: {
      paddingLeft: 0,
    },
  }),
);

export type OrganizationProfessionalsProps = {
  organization: OrganizationProfessionalsDataFragment,
  getItemAction?: (p: OrganizationProfessionalPersonalDataFragment) => ReactNode;
  onClickItem?: (p: OrganizationProfessionalPersonalDataFragment) => void;
  filterItem?: (p: OrganizationProfessionalPersonalDataFragment) => Boolean | null | undefined;
  showInvite?: boolean;
  showTitle?: boolean;
}

export const OrganizationProfessionals: FC<OrganizationProfessionalsProps> = ({
  organization,
  getItemAction,
  filterItem,
  onClickItem,
  showInvite = true,
  showTitle = true,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [filteredRoles, setFilteredRoles] = useState<string[]>([]);
  const dialog = useDialog();
  const isMobile = useIsMobile();
  const { actingAs } = useProfile();

  const availableRoles = useMemo(() => {
    return uniq(
      organization.professionals
        .map(professional => professional.professional.__typename),
    )
      .filter(Boolean) as string[];
  }, [organization.professionals]);

  useEffect(() => {
    setFilteredRoles(availableRoles);
  }, [availableRoles])

  const handleChangeRole = (event: SelectChangeEvent<any>) => {
    setFilteredRoles(event.target.value as string[]);
  };

  const professionals = useMemo(() => {
    return organization.professionals
      .filter(professional => {
        return (!filterItem || filterItem(professional))
          && filteredRoles.includes(professional.professional.__typename as string);
      })
  }, [organization, filterItem, filteredRoles]);

  const showInviteDialog = useCallback(() => {
    // if (!actingAs) return;
    // if (!isOrganizationSa(actingAs)) return; // this should never happen

    dialog.open({
      title: t('invite:invite'),
      body: <InviteToOrganization organization={organization} organizationSa={isOrganizationSa(actingAs) ? actingAs : undefined} />,
      maxWidth: 'md',
      fullWidth: true,
      fullScreen: isMobile,
    });
  }, [dialog, t, actingAs, organization, isMobile]);

  const roleSelector = availableRoles.length > 1 && (
    <FormControl className={classes.multiselect}>
      <InputLabel id="role-checkbox-label">
        {t('professional:filter-roles') as string}
      </InputLabel>

      <Select
        fullWidth
        disableUnderline
        labelId="role-checkbox-label"
        id="role-checkbox"
        multiple
        value={filteredRoles}
        disabled={availableRoles.length < 2}
        onChange={handleChangeRole}
        input={<Input />}
        renderValue={(selected) => {
          if (availableRoles.length > 1 && (selected as string[]).length === availableRoles.length) {
            return t('professional:all-roles');
          } else {
            return (selected as string[])
              .map(role => t(`professional:${role}`) as string)
              .join(', ');
          }
        }}
      >
        {availableRoles.map((role) => {
          const Icon = getRoleIcon(role);

          return <MenuItem key={role} value={role} className={classes.menuItem}>
            <Checkbox checked={filteredRoles.indexOf(role) > -1} />
            <ListItemIcon className={classes.listItemIcon}>
              <Icon className={classes.profileIcon} style={{
                fill: getRoleColor(role).main,
              }} />
            </ListItemIcon>
            <ListItemText primary={t(`professional:${role}`)} />
          </MenuItem>
        })}
      </Select>
    </FormControl>
  )

  return <Box width="100%">
    <Grid container>
      <Grid item xs={12}>
        <Grid container justifyContent="space-between">
          {showTitle && <Grid item>
            <Typography gutterBottom variant="h4" color="primary">
              {t('organization:managed-profiles')}
            </Typography>
          </Grid>}
          <Grid item>
            {roleSelector}
          </Grid>
          {showInvite && <Grid item>
            <Button
              startIcon={<Add />}
              onClick={showInviteDialog}
              variant="contained"
              color="primary"
            >
              {t('invite:invite')}
            </Button>
          </Grid>}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <List>
          { professionals.length
            ? professionals.map((professional, i) => {
              return <OrganizationProfessionalItem
                key={professional.id}
                professional={professional}
                onClick={onClickItem ? () => onClickItem(professional) : undefined}
                action={getItemAction ? getItemAction(professional) : undefined}
                divider={i < professionals.length - 1}
              />
            })
            : (
              <ListItem>
                <ListItemText>
                  <Typography color="textSecondary" variant="h6">
                    {t('network:no-connections')}
                  </Typography>
                </ListItemText>
              </ListItem>
            )
          }
        </List>
      </Grid>
    </Grid>
  </Box>
}
