import { RoutesKeys, RouteType } from './utils';
import { asyncLoad, getRouteFactory } from '@hu-care/react-utils';
import { getRoute } from './index';

export enum AgendaRoutesKeys {
  view = 'view',
}

export const agendaRoutes: RouteType<AgendaRoutesKeys>[] = [
  {
    key: AgendaRoutesKeys.view,
    component: asyncLoad(() => import('../views/pages/agenda/pages/agenda-view.page')),
    path: '/',
    config: {
      title: 'view',
    },
  },
];

const routeByKey = agendaRoutes.reduce((acc, r) => {
  acc.set(r.key, r.path);
  return acc;
}, new Map<AgendaRoutesKeys, string>());

const _getAgendaRoute = getRouteFactory<AgendaRoutesKeys>(routeByKey);

export const getAgendaRoute = (key: AgendaRoutesKeys, replaces?: Record<string, any>) => {
  const agendaBaseRoute = getRoute(RoutesKeys.agenda);
  const agendaPath = _getAgendaRoute(key, replaces);
  return `${agendaBaseRoute}${agendaPath}`;
}
