import {
  CheckCircle,
  FolderShared,
  Mail,
  Event,
  Notifications,
  Home,
  ChatBubble,
  QuestionAnswer,
  Receipt,
  Description,
  Work,
  AccountBox,
  Business,
  Save,
  DeleteForever,
  ExpandMore,
  PersonPin,
  HelpOutline,
  Refresh,
  Done,
  PersonAdd,
  HourglassFull,
  Block,
  MoreHoriz,
  Visibility,
  Send,
  Payment,
  Launch,
  Archive,
  Edit,
  Lock,
  Phone,
  FiberManualRecord,
  Clear,
  Telegram,
  Replay,
  Publish,
  CloudDownload,
  RotateLeft,
  ArrowBackIos,
  Cancel,
  EventBusy,
  People,
  Image,
  EventAvailable as EventAvailableIcon,
  Undo,
  Article,
  Help,
  ChevronLeft,
  ChevronRight,
} from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { withStyles } from '@mui/styles';
import { PatientProfessionalDataFragment, PatientProfessionalStatus } from '../generated/graphql';
import { DOC_LINK } from '../routes';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from '../hooks/useIsMobile';

export const HomeIcon = Home;
export const OrganizationIcon = Business;
export const PatientsIcon = FolderShared;
export const NetworkIcon = People;
export const RequestsIcon = QuestionAnswer;
export const ReportsIcon = Description;
export const InvoicesIcon = Receipt;
export const MailIcon = Mail;
export const ChatIcon = ChatBubble;
export const AgendaIcon = Event;
export const NotificationIcon = Notifications;
export const ImpersonateIcon = PersonPin;
export const SaveIcon = Save;
export const DeleteIcon = DeleteForever;
export const ClearIcon = Clear;
export const ExpandIcon = ExpandMore;
export const ProvisionIcon = Work;
export const ProfileIcon = AccountBox;
export const ClinicIcon = Business;
export const SupportIcon = HelpOutline;
export const AddNetworkIcon = PersonAdd;
export const RefreshIcon = Refresh;
export const DoneIcon = Done;
export const PendingIcon = HourglassFull;
export const BlacklistIcon = Block;
export const SettingsIcon = MoreHoriz;
export const ViewIcon = Visibility;
export const SendIcon = Send;
export const PayIcon = Payment;
export const ExternalLinkIcon = Launch;
export const ArchiveIcon = Archive;
export const UnarchiveIcon = Undo;
export const InvitedPatientIcon = Telegram;
export const EditIcon = Edit;
export const LockIcon = Lock;
export const PhoneIcon = Phone;
export const ClinicRoomIcon = FiberManualRecord;
export const ResetIcon = RotateLeft;
export const ReactivateAgendaListIcon = Replay;
export const AgendaListDraftIcon = EventBusy;
export const AgendaListIcon = EventAvailableIcon;
export const ImageIcon = Image;
export const PublishAgendaListIcon = Publish;
export const DownloadIcon = CloudDownload;
export const BackIcon = ArrowBackIos;
export const RevokeIcon = Cancel;
export const DelegationIcon = Article;
export const DocumentationIcon = Help;
export const LeftIcon = ChevronLeft;
export const RightIcon = ChevronRight;

// export { ReactComponent as AssistantIcon } from '../assets/icons/user-friends.svg';
// export { ReactComponent as PatientIcon } from '../assets/icons/user-injured.svg';
export { ReactComponent as DoctorIcon } from '../assets/icons/user-md.svg';
export { ReactComponent as NurseIcon } from '../assets/icons/user-nurse.svg';

export { ReactComponent as OpenIcon } from '../assets/icons/availability/public.svg';
export { ReactComponent as AssistantIcon } from '../assets/icons/availability/assistant-solid-2.svg';
export { ReactComponent as PatientIcon } from '../assets/icons/availability/patient.svg';

// custom icon button with reduced padding for adornments
export const AdornmentIconButton = withStyles({
  root: {
    padding: 8,
  },
})(IconButton);

export const getPatientProfessionalIcon = (pp: PatientProfessionalDataFragment) => {
  if (!pp.patient.users.length) {
    return <RevokeIcon /> // patient inactive
  }
  switch (pp.status) {
    // FIXME differenziare
    case PatientProfessionalStatus.RequestedByPatient: return <PendingIcon />;
    case PatientProfessionalStatus.RequestedByMedic: return <PendingIcon />;
    case PatientProfessionalStatus.Archived: return <ArchiveIcon />;
    case PatientProfessionalStatus.Approved: return <CheckCircle />;
    default: return <div />;
  }
}

export const DocumentationLink = () => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  return (
    <a href={DOC_LINK} target="_blank" rel="noreferrer" style={{ color: 'white', textDecoration: 'none' }}>
      <IconButton>
        <DocumentationIcon style={{ color: 'white' }}/>
      </IconButton>
      {!isMobile && (
        <span color="inherit">
          {t('documentation')}
        </span>
      )}
    </a>
  )
}
