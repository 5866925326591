import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText, Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ProfessionalBasicDataFragment, SearchProfessionalDataFragment } from '../../../generated/graphql';
import { getProfessionalPrefix, getProfessionalRole, getProfileIcon } from '../../../utils/profiles';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) => ({
  professionalIcon: {
    height: 22,
    fill: theme.palette.text.secondary,
    width: 'auto',
  },
  specList: {
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(1),
    margin: 0,
  },
}));

type ProfessionalProfilesProps = {
  profile: ProfessionalBasicDataFragment | SearchProfessionalDataFragment;
}

export const ProfessionalCard: FC<ProfessionalProfilesProps> = ({ profile }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const Icon = profile
    ? getProfileIcon(profile)
    : null;

  const role = getProfessionalRole(profile, t);
  const city = 'address' in profile ? profile.address?.municipalityName : '';

  const specs = 'specializations' in profile ? profile.specializations : [];

  return (
    <Box>
      <List>
        <ListItem>
          <ListItemIcon>
            {Icon && <Icon className={clsx(classes.professionalIcon, 'MuiSvgIcon-root')} />}
          </ListItemIcon>

          <ListItemText
            primary={(
              <Typography gutterBottom variant="h5" component="h2">
                {`${getProfessionalPrefix(profile, t)}${profile.name} ${profile.surname}`}
              </Typography>
            )}
            secondary={(
              <Typography variant="body2" color="textSecondary">
                {role} {city && <>a <b>{city}</b></>}
              </Typography>
            )}
            disableTypography
          />

        </ListItem>
      </List>
      {specs.length > 0 && (
        <Box px={2}>
          <Typography variant="subtitle2">{t('specializations')}</Typography>
          <ul className={classes.specList}>
            {specs.map(spec => <li key={spec.id}><Typography variant="body2">{spec.name}</Typography></li>)}
          </ul>
        </Box>
      )}
    </Box>
  )
}
