import { FC, ReactNode, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouteLink, useLocation } from 'react-router-dom';
import {
  Link,
  Typography,
  Box,
  SwipeableDrawer,
  List,
  ListSubheader,
  ListItem,
  ListItemIcon,
  ListItemText, IconButton, AppBar, Toolbar,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { MenuOpen } from '@mui/icons-material';

import { useProfile } from '../../../contexts/profile.context';
import { getRoute, getRouteByPath, RoutesKeys, RouteType } from '../../../routes';
import { ProfessionalRole } from '../../../generated/graphql';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { HomeIcon, PatientsIcon, AgendaIcon, NetworkIcon, RequestsIcon, ReportsIcon, InvoicesIcon } from '../../../utils/icons';
import clsx from 'clsx';
import { isOrgSaAdministrator } from '../../../utils/profiles';

const useStyles = makeStyles((theme: any) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      background: theme.palette.background.paper,
      boxShadow: '0px 0px 8px 1px grey',
      overflow: 'hidden',
      padding: '10px 8px 8px 0px',
    },
    mobileDrawer: {
      minWidth: '50vw',
      maxWidth: '80vw',
    },
    toolbar: {
      borderBottom: 'solid 3px',
      borderBottomColor: theme.palette.role.main,
      alignItems: 'stretch',
    },
    quickLinks: {
      display: 'flex',
      width: '100%',
      justifyContent: 'space-evenly',
    },
    quickLinkItem: {
      color: theme.palette.text.secondary,
      '& *': {
        color: theme.palette.text.secondary,
      },
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      textAlign: 'center',
      alignItems: 'center',
      display: 'flex',
    },
    quickLinkItemActive: {
      backgroundColor: theme.palette.role.light,
      color: theme.palette.role.contrastText,
      '& *': {
        color: theme.palette.role.contrastText,
      },
    },
    quickLinkItemItem: {
      display: 'flex',
    },
    info: {
      color: theme.palette.secondary.main,
    },
    username: {
      fontWeight: theme.typography.fontWeightBold,
    },
    drawerIcon: {
      '& *': {
        fill: 'white',
      },
    },
  }),
);

type QuickLink = {
  label: string;
  to: RoutesKeys;
  icon: ReactNode;
  roles?: ProfessionalRole[];
  hideImpersonate?: boolean;
  hideForOrgAdmin?: boolean;
}

const quickLinks: QuickLink[] = [
  {
    label: 'menu:dashboard',
    to: RoutesKeys.dashboard,
    icon: <HomeIcon color="inherit"/>,
    roles: [ProfessionalRole.Assistant, ProfessionalRole.OrganizationSa],
  },
  {
    label: 'menu:agenda',
    to: RoutesKeys.agenda,
    icon: <AgendaIcon color="inherit"/>,
    roles: [ProfessionalRole.Medic, ProfessionalRole.Operator, ProfessionalRole.OrganizationSa],
    hideForOrgAdmin: true,
  },
  {
    label: 'menu:patients',
    to: RoutesKeys.patients,
    icon: <PatientsIcon color="inherit"/>,
    roles: [ProfessionalRole.Medic, ProfessionalRole.Operator, ProfessionalRole.OrganizationSa],
    hideForOrgAdmin: true,
  },
  {
    label: 'menu:network',
    to: RoutesKeys.network,
    icon: <NetworkIcon color="inherit"/>,
    roles: [ProfessionalRole.Medic, ProfessionalRole.Operator, ProfessionalRole.OrganizationSa],
  },
  {
    label: 'menu:requests',
    to: RoutesKeys.requests,
    icon: <RequestsIcon color="inherit"/>,
    roles: [ProfessionalRole.Medic, ProfessionalRole.Operator, ProfessionalRole.OrganizationSa],
    hideForOrgAdmin: true,
  },
  {
    label: 'menu:invoices',
    to: RoutesKeys.invoices,
    icon: <InvoicesIcon color="inherit"/>,
    roles: [ProfessionalRole.Medic, ProfessionalRole.Operator, ProfessionalRole.OrganizationSa],
    hideForOrgAdmin: true,
  },
  {
    label: 'menu:reports',
    to: RoutesKeys.reports,
    icon: <ReportsIcon color="inherit"/>,
    roles: [ProfessionalRole.Medic, ProfessionalRole.Operator, ProfessionalRole.OrganizationSa],
    hideImpersonate: true,
    hideForOrgAdmin: true,
  },
];

export const QuickLinksBar: FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { actingAs, isImpersonating, availableAdministrator } = useProfile();
  const [isMenuOpen, setMenuOpen] = useState(false);

  const { pathname } = useLocation();

  const currentRoute = useMemo(() => {
    // get the actual route object using the pathname
    const actualRoute: RouteType | undefined = getRouteByPath(pathname);
    // find the related quickLink (if exists)
    return quickLinks.find(ql => {
      return ql.to === actualRoute?.key // for parent
        || ql.to === actualRoute?.parent; // for child
   })
  }, [pathname]);

  const filteredLinks = useMemo(() => {
    return quickLinks
      .filter(quickLink => {
        return !quickLink.roles
          || (
            actingAs
            && quickLink.roles.includes(actingAs.__typename as ProfessionalRole)
            && (!isImpersonating || !quickLink.hideImpersonate)
            && !(quickLink.hideForOrgAdmin && isOrgSaAdministrator(actingAs, availableAdministrator))
          )
      })
  }, [actingAs, isImpersonating, availableAdministrator]);

  if (filteredLinks.length <= 1) return null;

  if (isMobile) {
    return (
      <>
        <AppBar position="static" color="inherit">
          <Toolbar onClick={() => setMenuOpen(true)} className={classes.toolbar} variant="dense">
            <Typography variant="h6" color="textSecondary" component="div" display="flex" alignItems="center">
              {currentRoute ? (
                <>
                  <Box display="inline-flex" mr={2}>
                    {currentRoute.icon}
                  </Box>
                  <span>{t(currentRoute.label)}</span>
                </>
              ) : (
                <Typography variant="subtitle1" color="textSecondary">
                  {t('menu:quick-actions')}
                </Typography>
              )}
            </Typography>
            <Box ml="auto" mr="-12px">
              <IconButton>
                <MenuOpen/>
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <SwipeableDrawer
          anchor="right"
          open={isMenuOpen}
          onOpen={() => undefined}
          onClose={() => setMenuOpen(false)}
        >
          <div
            role="presentation"
            onClick={() => setMenuOpen(false)}
            onKeyDown={() => setMenuOpen(false)}
            className={classes.mobileDrawer}
          >
            <List
              subheader={
                <ListSubheader component="div" color="primary">
                  {t('menu:quick-actions')}
                </ListSubheader>
              }>
              { filteredLinks.map((item, i) => (
                <ListItem
                  divider={i !== filteredLinks.length - 1}
                  button
                  component={RouteLink}
                  to={getRoute(item.to)}
                  key={item.label}
                >
                  <ListItemIcon className={classes.drawerIcon}>
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText primary={t(item.label)} />
                </ListItem>
              ))}
            </List>
          </div>
        </SwipeableDrawer>
      </>
    )
  }

  return (
    <AppBar position="static" color="inherit">
      <Toolbar variant="dense" disableGutters className={classes.toolbar}>
        <Box display="flex" width="100%" justifyContent="space-evenly">
          {filteredLinks.map(quickLink => (
            <Link key={quickLink.label}
              component={RouteLink}
              display="flex"
              to={getRoute(quickLink.to)}
              className={clsx(classes.quickLinkItem, {
              [classes.quickLinkItemActive]: currentRoute?.to === quickLink.to,
            })}>
              {quickLink.icon}
              <Typography pl={1}>
                {t(quickLink.label)}
              </Typography>
            </Link>
          ))}
        </Box>
      </Toolbar>
      {/*<Toolbar variant="dense" disableGutters className={classes.toolbar}>*/}
      {/*  <Grid container justify="space-evenly" alignItems="stretch" className={classes.quickLinks}>*/}
      {/*    {filteredLinks.map(quickLink => (*/}
      {/*      <Grid item className={clsx(classes.quickLinkItem, {*/}
      {/*        [classes.quickLinkItemActive]: currentRoute?.to === quickLink.to,*/}
      {/*      })} key={quickLink.label}>*/}
      {/*        <Link component={RouteLink} to={getRoute(quickLink.to)}>*/}
      {/*          <Grid container spacing={1}>*/}
      {/*            <Grid item className={classes.quickLinkItemItem}>*/}
      {/*              {quickLink.icon}*/}
      {/*            </Grid>*/}
      {/*            <Grid item className={classes.quickLinkItemItem}>*/}
      {/*              <Typography>*/}
      {/*                {t(quickLink.label)}*/}
      {/*              </Typography>*/}
      {/*            </Grid>*/}
      {/*          </Grid>*/}
      {/*        </Link>*/}
      {/*      </Grid>*/}
      {/*    ))*/}
      {/*    }*/}
      {/*  </Grid>*/}
      {/*</Toolbar>*/}
    </AppBar>
  )
}
