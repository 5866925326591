import { useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  PersonalMedicTransfersQuery,
  PersonalMedicTransfersQueryVariables,
  PersonalMedicTransfersDocument,
  DenyPersonalMedicTransferMutation as DenyMutation,
  DenyPersonalMedicTransferMutationVariables as DenyMutationVariables,
  DenyPersonalMedicTransferDocument as DenyDocument,
  AcceptPersonalMedicTransferMutation as AcceptMutation,
  AcceptPersonalMedicTransferMutationVariables as AcceptMutationVariables,
  AcceptPersonalMedicTransferDocument as AcceptDocument, PersonalMedicTransfer,
} from '../generated/graphql';

import { useTranslation } from 'react-i18next';
import { useConfirmDialog } from './useConfirmDialog';
import { useToast } from '@hu-care/react-ui-store';
import { useProfile } from '../contexts';
import { isMedic } from '../utils/profiles';
import { useHandleError } from './useHandleError';

const onlyAcceptedFilter = (pst: Pick<PersonalMedicTransfer, 'acceptedAt'>) => !!pst.acceptedAt;
const onlyPendingFilter = (pst: Pick<PersonalMedicTransfer, 'acceptedAt'>) => !pst.acceptedAt;

export const usePersonalMedicTransfers = (patientId?: string) => {
  const { actingAs } = useProfile();
  const { data, ...rest } = useQuery<PersonalMedicTransfersQuery, PersonalMedicTransfersQueryVariables>(PersonalMedicTransfersDocument, {
    fetchPolicy: 'network-only',
    variables: {
      medicId: actingAs?.id || '',
      patientId,
    },
    skip: !actingAs || !isMedic(actingAs),
  });

  return useMemo(() => {
    const psts = (data?.personalMedicTransfersForMedic || []);
    return {
      personalMedicTransfers: psts,
      pendingTransfers: psts.filter(onlyPendingFilter),
      acceptedTransfers: psts.filter(onlyAcceptedFilter),
      ...rest,
    }
  }, [
    rest,
    data,
  ]);
}

export const useReplyToPersonalMedicTransfer = (withConfirm = true) => {
  const { t } = useTranslation();
  const dialog = useConfirmDialog();
  const toast = useToast();
  const handleError = useHandleError(false);

  const [denyMutation, {
    loading: denyLoading,
  }] = useMutation<DenyMutation, DenyMutationVariables>(DenyDocument);

  const [acceptMutation, {
    loading: acceptLoading,
  }] = useMutation<AcceptMutation, AcceptMutationVariables>(AcceptDocument);

  const onDeny = useMemo(() => {
    const action = async (id: string) => {
      try {
        await denyMutation({
          variables: {
            id,
          },
          update: (cache, mutationResult) => {
            if (!mutationResult.data?.denyPersonalMedicTransfer) {
              return;
            }
            cache.evict({
              id: cache.identify({ __typename: 'PersonalMedicTransfer', id }),
              broadcast: true,
            });
            cache.gc();
          },
        });
        toast.success(t('patient:deny-personal-medic-transfer-success'));
      } catch (e) {
        handleError(e, 'errors:cannot-deny-personal-medic-transfer');
      } finally {
        dialog.close()
      }
    };

    if (withConfirm) {
      return (id: string) => dialog.confirm({
        onConfirm: () => action(id),
        title: t<string>('patient:confirm-deny-personal-medic-transfer'),
      });
    } else {
      return action;
    }
  }, [denyMutation, handleError, withConfirm, dialog, t, toast]);

  const onAccept = useMemo(() => {
    const action = async (id: string) => {
      try {
        await acceptMutation({
          variables: {
            id,
          },
          update: (cache, mutationResult) => {
            if (!mutationResult.data?.acceptPersonalMedicTransfer) {
              return;
            }
            cache.modify({
              id: cache.identify(mutationResult.data.acceptPersonalMedicTransfer),
              fields: {
                acceptedAt: (value) => {
                  if (!mutationResult.data?.acceptPersonalMedicTransfer) {
                    return value;
                  }
                  return mutationResult.data.acceptPersonalMedicTransfer.acceptedAt;
                },
              },
            });
            cache.modify({
              id: cache.identify(mutationResult.data.acceptPersonalMedicTransfer.patient),
              fields: {
                personalMedicId: (value) => {
                  if (!mutationResult.data?.acceptPersonalMedicTransfer) {
                    return value;
                  }
                  return mutationResult.data.acceptPersonalMedicTransfer.targetId;
                },
              },
            });
          },
        });
        toast.success(t('patient:accept-personal-medic-transfer-success'));
      } catch (e) {
        handleError(e, 'errors:cannot-accept-personal-medic-transfer');
      } finally {
        dialog.close()
      }
    };

    if (withConfirm) {
      return (id: string) => dialog.confirm({
        onConfirm: () => action(id),
        title: t<string>('patient:confirm-accept-personal-medic-transfer'),
      });
    } else {
      return action;
    }
  }, [acceptMutation, handleError, withConfirm, dialog, t, toast]);


  return useMemo(() => ({
    onDeny,
    onAccept,
    loading: denyLoading || acceptLoading,
  }), [
    onDeny,
    onAccept,
    denyLoading,
    acceptLoading,
  ]);
}
