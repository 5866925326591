import { useQuery } from '@apollo/client';
import {
  ProfessionalReportDocument,
  ProfessionalReportQuery,
  ProfessionalReportQueryVariables,
} from '../../generated/graphql';
import { useMemo } from 'react';
import { useProfile } from '../../contexts/profile.context';

export const useReport = (id: string) => {
  const { actingAs } = useProfile();
  const { data, ...rest } = useQuery<ProfessionalReportQuery, ProfessionalReportQueryVariables>(ProfessionalReportDocument, {
    variables: {
      id,
      medicId: actingAs!.id,
    },
    skip: !actingAs,
    errorPolicy: 'all',
  });

  return useMemo(() => ({
    sharedReport: data?.professionalSharedReport,
    ...rest,
  }), [
    data,
    rest,
  ]);
}
