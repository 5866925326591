import { ApolloLink, FetchResult, Observable, Operation } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { ConnectionParams } from 'subscriptions-transport-ws';

export class WsLink extends ApolloLink {
  private _webSocketLink!: WebSocketLink;

  constructor(
    private readonly wsUrl: URL | string,
    private readonly connectionParamsFactory: () => ConnectionParams,
  ) {
    super();
  }

  private get webSocketLink(): WebSocketLink {
    if (!this._webSocketLink) {
      this._webSocketLink = new WebSocketLink({
        uri: this.wsUrl.toString(),
        options: {
          reconnect: true,
          connectionParams: this.connectionParamsFactory(),
        },
      });
    }
    return this._webSocketLink;
  }

  public request(operation: Operation): Observable<FetchResult> | null {
    return this.webSocketLink.request(operation);
  }
}
