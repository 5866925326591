import {
  OrganizationProfessionalPersonalDataFragment,
  PatientProfessionalDataFragment, SharePatientWithProfessionalDocument as SharePatientDocument,
  SharePatientWithProfessionalMutation as SharePatientMutation,
  SharePatientWithProfessionalMutationVariables as SharePatientMutationVariables,
} from '../../generated/graphql';
import { useProfile } from '../../contexts';
import { useDialog, useToast } from '@hu-care/react-ui-store';
import { useIsMobile } from '../useIsMobile';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { useHandleError } from '../useHandleError';
import { useMutation } from '@apollo/client';
import { isOrganizationSa } from '../../utils/profiles';
import { OrganizationProfessionals } from '../../views/components/organization/organization-professionals';
import { IconButton } from '@mui/material';
import { Check, Share } from '@mui/icons-material';

export const useSharePatientWithProfessional = (patientProfessional: PatientProfessionalDataFragment) => {
  const { actingAs } = useProfile();
  const dialog = useDialog();
  const isMobile = useIsMobile();
  const { t } = useTranslation();
  const toast = useToast();
  const [sentInvites, setSentInvites] = useState<Record<string, boolean>>({});
  const handleError = useHandleError(false);

  const [shareMutation] = useMutation<SharePatientMutation, SharePatientMutationVariables>(SharePatientDocument);

  const onShare = useCallback(async (op: OrganizationProfessionalPersonalDataFragment) => {
    // Group medicine does not share (clone)
    if (!actingAs || !isOrganizationSa(actingAs) || actingAs.isGroupMedicine) return null;

    try {
      const r = await shareMutation({
        variables: {
          medicId: op.professional.id,
          patientProfessionalId: patientProfessional.id,
          orgId: actingAs.id,
        },
      })
      if (r.data?.sharePatientWithProfessional) {
        setSentInvites(invites => {
          const toReturn = {
            ...invites,
            [op.professional.id]: true,
          }
          return toReturn
        })
      }
      toast.success(t('patient:share-with-professional-success'))
    } catch (e) {
      handleError(e, 'errors:cannot-share-with-professional');
    }
  }, [toast, t, setSentInvites, shareMutation, patientProfessional, actingAs, handleError])

  return useMemo(() => {
    // Group medicine does not share (clone)
    if (!actingAs || !isOrganizationSa(actingAs) || actingAs.isGroupMedicine) return null;

    return () => {
      dialog.open({
        body: (
          <OrganizationProfessionals
            showInvite={false}
            showTitle={false}
            organization={actingAs.managedOrganization}
            getItemAction={op => {
              return sentInvites[op.professional.id]
                ? <IconButton disabled>
                  <Check />
                </IconButton>
                : <IconButton onClick={() => {
                  dialog.close(); // FIXME possible improvement: update sentInvites without closing dialog?
                  onShare(op);
                }}>
                  <Share />
                </IconButton>
            }}
          />
        ),
        title: t('patient:share-with-professionals'),
        maxWidth: 'md',
        fullWidth: true,
        onClose: dialog.close,
        fullScreen: isMobile,
      })
    }
  }, [actingAs, onShare, sentInvites, dialog, isMobile, t]);
}
