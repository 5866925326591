import { FC, useCallback, useEffect, useState } from 'react';
import { RequestDataFragment, RequestResult } from '../../../generated/graphql';
import {
  FormControlLabel,
  Radio,
  Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAnswerRequest, AnswerRequestForm } from '../../../hooks/requests/useAnswerRequest';
import { SendIcon } from '../../../utils/icons';
import { useProfile } from '../../../contexts';
import { isPatient } from '../../../utils/profiles';
import { ControlledFormControlRadioGroup, ControlledTextField, SubmitButton } from '../form';
import { PatientActionsDial } from '../patients/patient-actions-dial';
import { usePatientProfessionalWithPatient } from '../../../hooks/patients/usePatientProfessional';

export interface AnswerRequestProps {
  request: RequestDataFragment;
  files?: File[];
  onSubmit?: () => void;
}

export const AnswerRequest: FC<AnswerRequestProps> = ({ request, files: initialFiles, onSubmit }) => {
  const { t } = useTranslation();
  const { actingAs } = useProfile();

  const {
    answerRequest,
    loading,
    form: { getValues, handleSubmit, control, reset },
    files: { fields, remove, append },
  } = useAnswerRequest(request);

  useEffect(() => {
    if (initialFiles) {
      remove();
    }
  }, [initialFiles, remove]);

  useEffect(() => {
    if (initialFiles && fields.length === 0) {
      append(initialFiles.map(file => ({ file })));
    }
  }, [initialFiles, append, fields]);

  const submit = useCallback(async (data: AnswerRequestForm) => {
    await answerRequest(data);
    reset({
      message: '',
      result: data.result,
    });

    if (onSubmit) {
      onSubmit();
    }
  }, [answerRequest, reset, onSubmit]);

  const canResolve = actingAs && !isPatient(request.recipient) && request.recipient.id === actingAs?.id;
  const isPatientSender = isPatient(request.sender);
  const patientSenderId = request?.sender && isPatientSender ? request.sender.id : '';
  const isAuthor = !isPatientSender && request.sender.id === actingAs?.id;
  const { patientProfessional } = usePatientProfessionalWithPatient(patientSenderId);
  const getDisableSubButtonValue = (() => {
    if (isPatientSender) {
      // already answered question or a radio button is checked
      if (request.result || !getValues('result')) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
  const disableInitialValue = getDisableSubButtonValue();
  const [disableSubButton, setDisableSubButton] = useState(disableInitialValue);

  const canAnswer = (() => {
    if (isAuthor || isPatientSender) {
      return !request.result;
    }
    return true;
  })();

  const handleChange = (() => {
    setDisableSubButton(getDisableSubButtonValue());
  });

  if (!canAnswer) {
    return null;
  }

  return (
    <form noValidate onSubmit={handleSubmit(submit)} onChange={handleChange}>
      <Grid container spacing={2} alignItems="center" justifyContent="space-between">
        <Grid item xs={canResolve ? 12 : 9}>
          <ControlledTextField
            control={control}
            name="message"
            label={t('request:add-message')}
            multiline
          />
        </Grid>
        {canResolve && (
          <Grid item>
            <ControlledFormControlRadioGroup
              control={control}
              name="result"
              label={t('request:result')}
              row
            >
              {!isPatientSender && <FormControlLabel value={''} control={<Radio />} label={t<string>('request:status-choice.pending')} />}
              <FormControlLabel value={RequestResult.Ok} control={<Radio />} label={t<string>('request:status-choice.ok')} />
              <FormControlLabel value={RequestResult.Ko} control={<Radio />} label={t<string>('request:status-choice.ko')} />
            </ControlledFormControlRadioGroup>
          </Grid>
        )}
        { (isPatientSender && patientProfessional) &&
          <Grid item>
            <PatientActionsDial patientProfessional={patientProfessional}/>
          </Grid>
        }
        <Grid item>
          <SubmitButton
            disabled={disableSubButton}
            loading={loading}
            endIcon={<SendIcon />}
          >
            {t('request:answer')}
          </SubmitButton>
        </Grid>
      </Grid>
    </form>
  )
}
