import {
  ArchivePatientProfessionalDocument as ArchiveDocument,
  ArchivePatientProfessionalMutation as ArchiveMutation,
  ArchivePatientProfessionalMutationVariables as ArchiveMutationVariables,
  PatientProfessionalDataFragment,
  PatientProfessionalStatus,
} from '../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { useConfirmDialog } from '../useConfirmDialog';
import { useToast } from '@hu-care/react-ui-store';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { useHandleError } from '../useHandleError';
import { useMemo } from 'react';
import { getRoute, RoutesKeys } from '../../routes';

export const useArchivePatient = (patientProfessional: PatientProfessionalDataFragment, withConfirm?: Boolean) => {
  const { t } = useTranslation();
  const dialog = useConfirmDialog();
  const toast = useToast();
  const navigate = useNavigate();

  const [archiveMutation] = useMutation<ArchiveMutation, ArchiveMutationVariables>(ArchiveDocument, {
    update: (cache, mutationResult) => {
      if (!mutationResult.data?.archivePatientProfessional) {
        return;
      }
      // When archiving, the record moves from the old status to ARCHIVED.
      // The patients list that shows ARCHIVED patients is not cached, so we simply have to evict the record from the cache
      if (mutationResult.data.archivePatientProfessional.status === PatientProfessionalStatus.Archived) {
        cache.evict({
          id: cache.identify(patientProfessional),
          broadcast: true,
        });
      }
    },
  });

  const handleError = useHandleError(false);

  return useMemo(() => {
    const action = async () => {
      try {
        await archiveMutation({
          variables: {
            id: patientProfessional.id,
          },
        });
        navigate(getRoute(RoutesKeys.patients));
        toast.success(t('patient:archive-patient-professional-success'));
      } catch (e) {
        handleError(e, 'errors:cannot-archive-connection');
      } finally {
        dialog.close();
      }
    };

    if (withConfirm) {
      const { fullName } = patientProfessional;
      return () => dialog.confirm({
        onConfirm: action,
        title: t('patient:confirm-archive', { name: fullName }),
      });
    } else {
      return action;
    }
  }, [
    archiveMutation,
    navigate,
    handleError,
    withConfirm,
    patientProfessional,
    dialog,
    t,
    toast,
  ]);
}

export const useUnarchivePatient = (patientProfessional: PatientProfessionalDataFragment, withConfirm?: Boolean) => {
  const { t } = useTranslation();
  const dialog = useConfirmDialog();
  const toast = useToast();

  const [archiveMutation] = useMutation<ArchiveMutation, ArchiveMutationVariables>(ArchiveDocument);

  const handleError = useHandleError(false);

  return useMemo(() => {
    const action = async () => {
      try {
        await archiveMutation({
          variables: {
            id: patientProfessional.id,
            archive: false,
          },
        });
        toast.success(t('patient:unarchive-patient-professional-success'));
      } catch (e) {
        handleError(e, 'errors:cannot-unarchive-connection');
      }
    };

    if (withConfirm) {
      const { fullName } = patientProfessional;
      return () => dialog.confirm({
        onConfirm: action,
        title: t('patient:confirm-unarchive', { name: fullName }),
      });
    } else {
      return action;
    }
  }, [
    archiveMutation,
    handleError,
    withConfirm,
    patientProfessional,
    dialog,
    t,
    toast,
  ]);
}
