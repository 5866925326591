import { forwardRef } from 'react';
import { Link as RouteLink } from 'react-router-dom';
import { AppBar, Box, Grid, Hidden, Link, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { NavBarProps } from '@hu-care/react-layout';

import { getRoute, RoutesKeys } from '../../../routes';
import { ProfileSelector } from '../profile-selector';
import { ImpersonateBar } from './impersonate-bar';
import { QuickLinksBar } from './quick-links-bar';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { UserMenu } from './user-menu';
import { NotificationMenu } from '../notifications/notification-menu';
import { Sidebar } from './side-bar';
import { useMemo } from 'react';
import { useProfile } from '../../../contexts/profile.context';
import { DocumentationLink } from '../../../utils/icons';
import WarningIcon from '@mui/icons-material/Warning';
interface NavBarTypeProps {
  logoLink: string;
  hasNotifications?: boolean;
  hasQuickLinks?: boolean;
  hasImpersonate?: boolean;
  hasSideBar?: boolean;
}

const useStyles = makeStyles(theme => ({
  logo: {
    height: 46,
    width: 'auto',
  },
  logoSmall: {
    height: 34,
    width: 'auto',
  },
  notifications: {
    position: 'absolute',
    right: 0,
  },
  toolbar: {
    paddingTop: 0,
  },
  hamburger: {
    fontSize: 32,
    fill: 'white',
  },
  profileLink: {
    textAlign: 'center',
  },
}));

export const NavBar = forwardRef<HTMLDivElement, NavBarProps>((props, ref) => {
  const { navBarType = 'default' } = props;
  const { actingAs } = useProfile();

  const preset = useMemo(() => {
    const navBarPresets: Record<string, NavBarTypeProps> = {
      default: {
        logoLink: getRoute(RoutesKeys.dashboard),
        hasImpersonate: true,
        hasNotifications: true,
        hasQuickLinks: true,
        hasSideBar: true,
      },
      external: {
        logoLink: getRoute(RoutesKeys.profiles),
      },
      conditional: {
        logoLink: actingAs ? getRoute(RoutesKeys.dashboard) : getRoute(RoutesKeys.profiles),
        hasImpersonate: Boolean(actingAs),
        hasNotifications: Boolean(actingAs),
        hasQuickLinks: Boolean(actingAs),
        hasSideBar: Boolean(actingAs),
      },
    };
    return navBarPresets[navBarType] || navBarPresets.default;
  }, [actingAs, navBarType]);

  const classes = useStyles();
  const isMobile = useIsMobile('sm');

  return (
    <AppBar position={'fixed'} elevation={0} ref={ref} id="main-header">
      <Toolbar className={classes.toolbar} disableGutters>
        <Box component="nav" width="100%" px={{ sm: 1, md: 2 }}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container spacing={isMobile ? 0 : 2} alignItems="center">
                {preset.hasSideBar && (
                  <Grid item>
                    <Sidebar/>
                  </Grid>
                )}
                <Hidden xsDown>
                  <Grid item>
                    <Link component={RouteLink} to={preset.logoLink}>
                      <img src={'/logo_csw_white.svg'} alt={'App Logo'} className={classes.logo} />
                    </Link>
                  </Grid>
                </Hidden>

                <Hidden xsUp>
                  <Grid item>
                    <Link component={RouteLink} to={preset.logoLink}>
                      <img src={'/logo_csw_white_small.svg'} alt={'App Logo'} className={classes.logoSmall} />
                    </Link>
                  </Grid>
                </Hidden>

                {
                  process.env.REACT_APP_ENV === 'qa' &&
                  <Hidden smDown>
                    <Grid item display="flex" alignItems="center">
                      <Box p={0.5}>
                        <WarningIcon color="error" />
                      </Box>
                      <Hidden mdDown>
                        <Typography color="error" variant="h6">
                          Ambiente di Test
                        </Typography>
                      </Hidden>
                      <Hidden lgDown>
                        <Typography color="error" variant="subtitle2" px={0.5}>
                          -
                        </Typography>
                        <Typography color="error" variant="subtitle2" sx={{ fontSize: 11 }}>
                          le azioni non saranno salvate e non generano interazioni con i pazienti
                        </Typography>
                      </Hidden>
                    </Grid>
                  </Hidden>
                }

              </Grid>
            </Grid>

            <Grid item flex={1}>
              <Grid container spacing={1} justifyContent="flex-end" alignItems="center">

                <Grid item>
                  <ProfileSelector />
                </Grid>

                <Grid item>
                  <UserMenu/>
                </Grid>

                <Grid item>
                  <DocumentationLink/>
                </Grid>

                {preset.hasNotifications && (
                  <Grid item>
                    <NotificationMenu />
                  </Grid>
                )}
              </Grid>

            </Grid>
          </Grid>
        </Box>
      </Toolbar>

      {preset.hasImpersonate && <ImpersonateBar/>}
      {preset.hasQuickLinks && <QuickLinksBar/>}
    </AppBar>
  )
});
