import { useMutation } from '@apollo/client';
import {
  AcceptOrganizationInviteDocument,
  AcceptOrganizationInviteMutation,
  AcceptOrganizationInviteMutationVariables,
  ManagedOrganizationsDocument,
} from '../../generated/graphql';
import { useCallback, useMemo } from 'react';
import { useToast } from '@hu-care/react-ui-store';
import { useTranslation } from 'react-i18next';
import { useHandleError } from '../useHandleError';
import { useProfessionalProfiles } from '../useProfessionalProfiles';

export const useAcceptOrganizationInvite = () => {
  const toast = useToast();
  const { t } = useTranslation();
  const handleError = useHandleError(false);
  const [
    acceptMutation,
    rest,
  ] = useMutation<AcceptOrganizationInviteMutation, AcceptOrganizationInviteMutationVariables>(AcceptOrganizationInviteDocument);
  const { refetch } = useProfessionalProfiles();

  const acceptInvite = useCallback(async (id: string, medicId: string) => {
    try {
      const result = await acceptMutation({
        variables: {
          id,
          medicId,
        },
        update: (cache, mutationResult) => {
          if (!mutationResult.data?.acceptOrganizationInvite) {
            return;
          }
          cache.evict({
            id: cache.identify({ __typename: 'Invite', id }),
            broadcast: true,
          });
        },
        refetchQueries: [{
          query: ManagedOrganizationsDocument,
        }],
        awaitRefetchQueries: true,
      });
      await refetch();
      toast.success(t('invite:accept-invite-success'));
      return result.data?.acceptOrganizationInvite;
    } catch (e) {
      handleError(e, 'error:cannot-accept-invite');
    }
  }, [acceptMutation, t, toast, handleError, refetch]);

  return useMemo(() => ({
    acceptInvite,
    ...rest,
  }), [
    acceptInvite,
    rest,
  ]);
}
