import { RoutesKeys, RouteType, renderRoutes } from './utils';
import { intersection } from 'lodash-es';
import { getRouteFactory } from '@hu-care/react-utils';

import { commonRoutes } from './common.routes';
import { errorRoutes } from './error.routes';
import { matchPath } from 'react-router-dom';

export * from './utils';

export const allRoutes: RouteType[] = [
  ...commonRoutes,
  ...errorRoutes,
];

export const renderCommonRoutes = (scopes: string[] = []) => (
  renderRoutes(
    commonRoutes
      .filter(({ config }) => (config.scopes || []).length ? intersection(config.scopes, scopes).length : true),
  )
);

export const renderErrorRoutes = () => renderRoutes(errorRoutes);

const routeByKey = allRoutes.reduce((acc, r) => {
  acc.set(r.key, r.path);
  return acc;
}, new Map<RoutesKeys, string>());

export const getRoute = getRouteFactory<RoutesKeys>(routeByKey);

/**
 * Given a pathname, return the RouteConfig object or undefined
 * */
export const getRouteByPath = (pathname: string): RouteType | undefined => {
  // attach all routes and filter eliminating the match with "/*"
  const toCheck = [
     ...allRoutes.filter(route => route.key !== RoutesKeys.pageNotFound),
  ];

  console.log(pathname)

  // iterate all routes
  for (const routeConfig of allRoutes) {
    if (routeConfig?.children) {
        const children = routeConfig.children.map(c => ({
          ...c,
          path: routeConfig.path + c.path, // create the complete path for the children (es: "/reports" + "/:id")
          parent: routeConfig.key,         // associate the parent key to the children
        }))
        toCheck.push(...children);
    }
  }

  // searching for match...
  for (const routeConfig of toCheck) {
     const res = matchPath({
      path: routeConfig.path,
    }, pathname);

    if (res) {
      return routeConfig;
    }
  }

  return undefined;
}
