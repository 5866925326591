
const baseUrl = process.env.REACT_APP_STATIC_BASE_URL || '';

export function assetUrl(filename: string) {
  if (isAbsoluteUrl(filename)) {
    return filename;
  }
  return new URL(filename, baseUrl).toString();
}

export function isAbsoluteUrl(url: string) {
  return /^http/.test(url);
}
