import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { groupBy } from 'lodash-es';

import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  Skeleton, Theme,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CheckCircle, Clear, Search, Warning } from '@mui/icons-material';

import { useDialog } from '@hu-care/react-ui-store';

import { useSearchProfessionals, useSearchProfessionalsByEmail } from '../../../hooks/useSearchProfessionals';
import {
  OrganizationProfessionalsDataFragment,
  OrganizationRole,
  OrganizationSaBasicDataFragment,
  ProfessionalBasicDataFragment,
  ProfessionalRole,
} from '../../../generated/graphql';
import {
  getProfessionalFullName,
  getProfessionalLabel,
  getProfileColor,
  getRoleIcon,
  isAssistant,
  isOrganizationSa,
} from '../../../utils/profiles';
import { ProfessionalCard } from '../professionals/professional-card';
import { AddNetworkIcon, AdornmentIconButton } from '../../../utils/icons';
import { InviteUser } from '../invites/invite-user';
import { arrayOfLength } from '../../../utils';
import { useInviteToOrganization } from '../../../hooks/invite/useInviteToOrganization';
import { arrayFromEnum } from '../../../utils/enum';
import { useSentInvites } from '../../../hooks/invite/useInvites';

export interface InviteToOrganizationProps {
  organization: OrganizationProfessionalsDataFragment & { serviceAccounts?: OrganizationSaBasicDataFragment[] };
  organizationSa?: OrganizationSaBasicDataFragment;
}

const useStyles = makeStyles((theme: Theme) => ({
  profileIcon: {
    fill: theme.palette.text.secondary,
    width: 20,
  },
  link: {
    cursor: 'pointer',
  },
  roleSelector: {
    minWidth: 140,
  },
}));

const skeletonItems = 3;

const roleInfos: Record<string, string> = {
  [OrganizationRole.Admin]: 'invite:organization.admin',
  [OrganizationRole.Medic]: 'invite:organization.medic',
};

const options = {
  roleFilter: {
    ne: ProfessionalRole.Assistant,
  },
};

export const InviteToOrganization: FC<InviteToOrganizationProps> = ({ organization, organizationSa }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedProfessional, setSelectedProfessional] = useState<ProfessionalBasicDataFragment | null>(null);
  const [selectedRole, setSelectedRole] = useState<string>('');

  const [isCreatingNew, setIsCreatingNew] = useState(false);
  const dialog = useDialog();

  const inviterSa = organizationSa || (organization.serviceAccounts || [])[0];

  const { sentInvites } = useSentInvites(inviterSa);

  const toExclude = useMemo(() => (
    organization.professionals.map(p => p.professional)
  ), [organization ]);

  const {
    search: byNameSearch,
    setSearch: byNameSetSearch,
    options: professionalsByName,
    called: byNameCalled,
    loading: byNameSearching,
  } = useSearchProfessionals(toExclude, options);

  const {
    search: byEmailSearch,
    setSearch: byEmailSetSearch,
    options: professionalsByEmail,
    called: byEmailCalled,
    loading: byEmailSearching,
  } = useSearchProfessionalsByEmail();

  const { onInvite: onInviteToOrg, loading: inviting } = useInviteToOrganization(organization);

  const onConfirm = useCallback((data?: { email: string }) => {
    if (!data || !selectedRole) {
      return;
    }
    onInviteToOrg(data.email, selectedRole)
    .then(() => dialog.close())
    .catch(() => {
      //
    })
  }, [onInviteToOrg, selectedRole, dialog]);

  useEffect(() => {
    if (isCreatingNew) {
      setSelectedProfessional(null);
    }
  }, [isCreatingNew, setSelectedProfessional]);

  const professionals = useMemo(() => {
    const grouped = groupBy([
      ...professionalsByName,
      ...professionalsByEmail.filter(p => !isAssistant(p)),
    ], 'userId');

    return Object.values(grouped).flatMap(professionals => {
      const noOrganizationSa = professionals.filter(p => !isOrganizationSa(p));
      const noAssistant = noOrganizationSa.filter(p => !isAssistant(p));
      return noAssistant.length
        ? noAssistant
        : noOrganizationSa;
    });
  }, [professionalsByName, professionalsByEmail]);

  const searching = byNameSearching || byEmailSearching;
  const called = byNameCalled || byEmailCalled;

  const roleSelector = (
    <Box pb={2}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <Typography variant="subtitle1">
            {t('invite:which-role')}
          </Typography>
        </Grid>
        <Grid item>
          <TextField
            className={classes.roleSelector}
            label={t('invite:role')}
            fullWidth
            size="small"
            required
            value={selectedRole}
            onChange={e => setSelectedRole(e.target.value)}
            variant="outlined"
            select
          >
            {arrayFromEnum(OrganizationRole).map(role => (
              <MenuItem value={role} key={role}>
                {t(`invite:roles.${role}`)}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        {selectedRole && (
          <Grid item xs={12}>
            <Typography variant="caption">{t(roleInfos[selectedRole])}</Typography>
          </Grid>
        )}
      </Grid>
    </Box>
  );

  const warning = <Box pb={3} textAlign="center">
    <Warning fontSize="large" color="error" />
    <Typography variant="subtitle2">
      {t('invite:alert')}
    </Typography>
  </Box>

  if (!selectedRole) {
    return <Box mb={2} width="100%">
      {warning}
      {roleSelector}
    </Box>
  }

  return (
    <Box mb={1} width="100%">
      {warning}
      {roleSelector}

      {isCreatingNew ? (
        <InviteUser
          loading={inviting}
          onConfirm={user => onConfirm(user)}
          suggestedEmail={byEmailSearch}
          onCancel={() => setIsCreatingNew(false)}
        />
      ) : selectedProfessional ? (
        <Box width="100%">
          <ProfessionalCard profile={selectedProfessional} />
          <Grid container justifyContent="space-between">
            <Grid item>
              <Button variant="outlined" onClick={() => setSelectedProfessional(null)}>
                {t('back')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => onConfirm(selectedProfessional?.user?.traits)}
              >
                {t('invite:send-request')}
              </Button>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
              placeholder={t('invite:search-by-name')}
              variant="outlined"
              size="small"
              value={byNameSearch}
              autoFocus
              onChange={e => byNameSetSearch(e.target.value)}
              fullWidth
              InputProps={{
                endAdornment: byNameSearch && <AdornmentIconButton onClick={() => byNameSetSearch('')}>
                  <Clear />
                </AdornmentIconButton>,
                startAdornment: <InputAdornment position="start">
                  <Search />
                </InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              placeholder={t('invite:search-by-email')}
              variant="outlined"
              size="small"
              value={byEmailSearch}
              type="email"
              autoFocus
              onChange={e => byEmailSetSearch(e.target.value)}
              fullWidth
              InputProps={{
                endAdornment: byEmailSearch && <AdornmentIconButton onClick={() => byEmailSetSearch('')}>
                  <Clear />
                </AdornmentIconButton>,
                startAdornment: <InputAdornment position="start">
                  <Search />
                </InputAdornment>,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <List>
              { professionals.length
                ? professionals.map((profile, i) => {
                  const Icon = getRoleIcon();

                  const isInNetwork = organization.professionals
                    .map(p => p.professional)
                    .find(c => c.userId === profile.userId && isAssistant(c));

                  const alreadyInvited = 'user' in profile
                    && (sentInvites || []).find(i => {
                      return i.email === profile?.user?.traits.email
                    })

                  const primary = `${getProfessionalFullName(profile, t)} - ${t(getProfessionalLabel(profile))}`;
                  const secondary = 'user' in profile
                    ?  profile.user?.traits.email
                    : ''

                  return (isInNetwork || alreadyInvited)
                    ? <ListItem
                      key={profile.id}
                      divider={i !== professionals.length - 1}>

                      <ListItemIcon>
                        <Icon className={classes.profileIcon} style={{
                          fill: getProfileColor(profile),
                        }} />
                      </ListItemIcon>

                      <ListItemText
                        secondary={secondary}
                        primary={primary}
                      />
                      <ListItemSecondaryAction>
                        <Tooltip title={t('organization:already-invited') as string}>
                            <span>
                              <IconButton disabled>
                                <CheckCircle />
                              </IconButton>
                            </span>
                        </Tooltip>
                      </ListItemSecondaryAction>
                    </ListItem>
                    : <ListItem
                      button
                      onClick={() => setSelectedProfessional(profile)}
                      key={profile.id}
                      divider={i !== professionals.length - 1}>

                      <ListItemIcon>
                        <Icon className={classes.profileIcon} />
                      </ListItemIcon>

                      <ListItemText
                        secondary={secondary}
                        primary={primary}
                      />
                      <ListItemSecondaryAction>
                        <IconButton onClick={() => setSelectedProfessional(profile)}>
                          <AddNetworkIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                })
                : searching
                  ? arrayOfLength(skeletonItems).map(i => (
                    <ListItem divider={i !== skeletonItems - 1} key={i}>
                      <ListItemIcon>
                        <Skeleton variant="circular" width={32} height={32} />
                      </ListItemIcon>

                      <ListItemText>
                        <Skeleton variant="rectangular" width="100%" />
                      </ListItemText>
                    </ListItem>
                  ))
                  : called
                    ? (
                      <ListItem>
                        <ListItemText>
                          <Typography color="textSecondary" variant="h6">
                            {t('network:no-results')}
                          </Typography>
                        </ListItemText>
                      </ListItem>
                    )
                    : null
              }
            </List>
          </Grid>
          <Grid item xs={12}>
            {t('invite:user-not-found-question')}
            {' '}<Link className={classes.link} onClick={() => setIsCreatingNew(true)}>{t('invite:add-new-user')}</Link>
          </Grid>
        </Grid>
      )}
    </Box>
  )
}
