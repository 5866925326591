import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { setContext } from '@apollo/client/link/context';
import { HttpLink, makeVar, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import { extractFiles } from 'extract-files';
import { WsLink } from './ws-link';

function getUserId() {
  return window.userId;
}

export const currentProfessionalIdVar = makeVar<string | null>(null);

export function httpLink(tokenGetter: () => string) {
  const apiUrl = new URL('/graphql', process.env.REACT_APP_API_ENDPOINT);
  const wsUrl = new URL(apiUrl.toString());
  wsUrl.protocol = apiUrl.protocol === 'https:' ? 'wss' : 'ws';

  // Create Websocket link to handle subscriptions
  const wsLink = new WsLink(
    wsUrl,
    () => ({
      token: tokenGetter(),
      ...process.env.NODE_ENV === 'development' && {
        userId: getUserId(),
      },
    }),
  );

  const httpOpts = {
    uri: apiUrl.toString(),
    credentials: 'include',
    includeExtensions: true,
  }

  // Create all possible http links
  const batchLink = new BatchHttpLink(httpOpts);
  const baseHttpLink = new HttpLink(httpOpts);
  const uploadLink = createUploadLink(httpOpts);

  // First split (last to run), by default all queries/mutations are batched,
  // optionally setting context.batch to false will disable batch for that specific operation
  const finalHttpLink = split(
    (op) => {
      const hasAtLeastOneMutation = !!op.query.definitions.find(d => d.kind === 'OperationDefinition' && d.operation === 'mutation');
      return op.getContext().batch === false || hasAtLeastOneMutation;
    },
    baseHttpLink,
    batchLink,
  );

  // Second split, if there are file uploads, use uploadLink
  const httpLink = split(
    (op) => extractFiles(op).files.size > 0,
    uploadLink,
    finalHttpLink,
  );

  // Third split (first to run), if operation is a Subscription, use websocket,
  // else, go to next split
  return split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition'
        && definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink,
  );
}

export function authLink(tokenGetter: () => string) {
  return setContext(
    async (data, { headers }) => {
      const currentProfessionalId = currentProfessionalIdVar();
      return {
        headers: {
          ...headers,
          Authorization: `Bearer ${tokenGetter()}`,
          ...process.env.NODE_ENV === 'development' && {
            userId: getUserId(),
          },
          ...currentProfessionalId && {
            'X-CURRENT-PROFESSIONAL-ID': currentProfessionalId,
          },
        },
      }
    },
  );
}
