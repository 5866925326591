import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  FormControl,
  Grid,
  Icon,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { useProfile } from '../../../contexts';
import { getProfessionalLabel, getProfileColor, getProfileIcon, isOrganizationSa } from '../../../utils/profiles';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { getRoute, RoutesKeys } from '../../../routes';
import { useNavigate } from 'react-router-dom';
import { OrganizationIcon } from '../../../utils/icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      '& .MuiSelect-iconStandard': {
        fill: 'white',
      },
      height: 32,
      '&:before': {
        borderBottom: '0px solid white !important',
      },
      color: 'white',
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'capitalize',
    },
    icon: {
      fill: 'white',
    },
    listItemIcon: {
      minWidth: 0,
      paddingRight: 4,
    },
    listItemIconContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    profileIcon: {
      height: 20,
      width: 20,
      paddingRight: 2,
      fill: theme.palette.text.secondary,
    },
    profileName: {
      maxWidth: '16ch',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    selectedProfileName: {
      maxWidth: '16ch',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      color: theme.palette.background.paper,
    },
  }),
);

export const ProfileSelector: FC = () => {
  const { setSelectedProfile, selectedProfile, availableProfiles, availableOrganizations } = useProfile();
  const classes = useStyles();
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const [profileIndex, setProfileIndex] = useState<string | null>(null);
  const { t } = useTranslation();

  // update profileIndex if selectedProfile changes from outside
  useEffect(() => {
    if (!selectedProfile) return;
    if (isOrganizationSa(selectedProfile)) {
      const orgIdx = availableOrganizations.findIndex(o => o.id === selectedProfile.managedOrganizationId).toString();
      setProfileIndex(`org-${orgIdx}`);
      return;
    }

    const index = availableProfiles.findIndex(p => p.id === selectedProfile.id).toString();
    if (index !== profileIndex) {
      setProfileIndex(index);
    }
  }, [selectedProfile, availableProfiles, profileIndex, setProfileIndex, availableOrganizations]);

  const handleChange = useCallback((event: SelectChangeEvent | string) => {
    const idx = typeof event === 'string' ? event : (event.target.value as string).toString();
    setProfileIndex(idx);
    if (idx.startsWith('org-')) {
      const org = availableOrganizations[parseInt(idx.replace('org-', ''))];
      setSelectedProfile(null);
      navigate(getRoute(RoutesKeys.organization, { id: org.id }));
    } else {
      const newProfile = availableProfiles[parseInt(idx)];
      setSelectedProfile(newProfile || null);
      navigate(getRoute(RoutesKeys.dashboard));
    }
  }, [availableProfiles, navigate, setSelectedProfile, availableOrganizations]);

  if (!availableProfiles.length || profileIndex === null) {
    return null;
  }

  return (
    <FormControl>
      <Select
        className={classes.select}
        variant="standard"
        inputProps={{
          classes: {
            icon: classes.icon,
          },
        }}
        value={profileIndex}
        onChange={handleChange}
        renderValue={value => {
          if ((value as string).startsWith('org-')) {
            const orgProfile = availableOrganizations[parseInt((value as string).replace('org-', ''))];
            const isSelectedProfile = isOrganizationSa(selectedProfile) && selectedProfile.managedOrganizationId === orgProfile.id;

            if (!orgProfile) return null;

            return <Grid container alignItems="center">
              <Grid item className={classes.listItemIconContainer}>
                <Icon className={classes.listItemIcon}>
                  <OrganizationIcon className={classes.profileIcon} style={{ fill: 'white' }} />
                </Icon>
              </Grid>
              { !isMobile && <Grid item>
                <Tooltip title={orgProfile.name}>
                  <Typography className={classes.selectedProfileName}>
                    {orgProfile.name} {isSelectedProfile && `(${selectedProfile?.name})`}
                  </Typography>
                </Tooltip>
              </Grid>}
            </Grid>
          }
          const profile = availableProfiles[value as unknown as number];

          if (!profile) return null;

          const ProfileIcon = getProfileIcon(profile);
          const profileNameLabel = getProfessionalLabel(profile);
          const profileName = isOrganizationSa(profile) ? profileNameLabel : t(profileNameLabel);

          return <Grid container alignItems="center" component="div">
            <Grid item className={classes.listItemIconContainer}>
              <Icon className={classes.listItemIcon}>
                <ProfileIcon className={classes.profileIcon} style={{ fill: 'white' }} />
              </Icon>
            </Grid>
            { !isMobile && <Grid item>
              <Tooltip title={profileName}>
                <Typography className={classes.selectedProfileName}>
                  {profileName}
                </Typography>
              </Tooltip>
            </Grid>}
          </Grid>
        }}
      >
        {availableProfiles.map((profile, i) => {
          const ProfileIcon = getProfileIcon(profile);
          const profileNameLabel = getProfessionalLabel(profile);
          const profileName = isOrganizationSa(profile) ? profileNameLabel : t(profileNameLabel);

          return <MenuItem key={profile.__typename} value={i}>
            <Grid container alignItems="center">
              <Grid item>
                <Icon className={classes.listItemIcon}>
                  <ProfileIcon className={classes.profileIcon} style={{ fill: getProfileColor(profile) }} />
                </Icon>
              </Grid>
              <Grid item>
                <Tooltip title={profileName} placement="right">
                  <Typography className={classes.profileName}>
                    {profileName}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          </MenuItem>
        })}
        {availableOrganizations.map((org, i) => {
          return <MenuItem key={`org-${org.id}`} value={`org-${i}`} onClick={() => handleChange(`org-${i}`)}>
            <Grid container alignItems="center">
              <Grid item>
                <Icon className={classes.listItemIcon}>
                  <OrganizationIcon  className={classes.profileIcon} style={{ fill: getProfileColor({ __typename: 'OrganizationSa' }) }} />
                </Icon>
              </Grid>
              <Grid item>
                <Tooltip title={org.name} placement="right">
                  <Typography className={classes.profileName}>
                    {org.name}
                  </Typography>
                </Tooltip>
              </Grid>
            </Grid>
          </MenuItem>
        })}
      </Select>
    </FormControl>
  )
}
