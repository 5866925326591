import { useQuery } from '@apollo/client';
import {
  ManagedOrganizationsQuery,
  ManagedOrganizationsQueryVariables,
  ManagedOrganizationsDocument,
  OrganizationProfessionalPersonalDataFragment,
} from '../generated/graphql';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MailIcon, ChatIcon } from '../utils/icons';
import { useDialog } from '@hu-care/react-ui-store';
import { useIsMobile } from './useIsMobile';
import { useProfile, useChatPopup } from '../contexts';
import { SendMail } from '../views/components/send-mail';
import { Box, Typography } from '@mui/material';
import { canChat, getProfessionalFullName, isOrganizationSa } from '../utils/profiles';

export const useManagedOrganizations = () => {
  const { data, ...rest } = useQuery<ManagedOrganizationsQuery, ManagedOrganizationsQueryVariables>(ManagedOrganizationsDocument, {
    context: {
      batch: false,
    },
  });

  return useMemo(() => ({
    organizations: data?.managedOrganizations,
    ...rest,
  }), [
    data,
    rest,
  ]);
}

export const useOrganizationProfessionalsActions = (profile: OrganizationProfessionalPersonalDataFragment, withConfirm?: Boolean) => {
  const { t } = useTranslation();

  const dialog = useDialog();
  const isMobile = useIsMobile();
  const { actingAs } = useProfile();

  const { open: openChat } = useChatPopup();

  const openSendMail = useCallback(() => {
    if (!actingAs || !profile?.professional.user?.id) {
      return;
    }
    dialog.open({
      body: (
        <SendMail
          onSend={dialog.close}
          onCancel={dialog.close}
          professional={actingAs}
          recipientIds={[profile.professional.user.id]}
        />
      ),
      title: (
        <Box display="flex" alignItems="center">
          <Box mr={2} display="flex"><MailIcon /></Box>
          <Typography>{getProfessionalFullName(profile.professional, t)}</Typography>
        </Box>
      ),
      maxWidth: 'md',
      fullWidth: true,
      fullScreen: isMobile,
    })
  }, [actingAs, dialog, t, profile, isMobile]);

  const allActions = useMemo(() => [
    {
      icon: <MailIcon />,
      name: t('menu:mail'),
      callback: openSendMail,
    },
    ...(
      isOrganizationSa(profile.professional)
        ? []
        : [{
          icon: <ChatIcon />,
          disabled: !canChat(profile.professional),
          name: canChat(profile.professional)
            ? t('menu:chat')
            : t('action-temporarily-disabled', {
              by: t(`by_the_${profile.professional.role.toLowerCase()}`),
            }),
          callback: () => openChat(profile!.professional.user!),
        }]
    ),
  ], [t, openSendMail, profile, openChat]);

  return allActions;
}
