import { createContext, FC, ReactNode, useCallback, useContext, useMemo, useReducer } from 'react';
import { uniqueId } from 'lodash-es';
import { AlertColor } from '@mui/material/Alert/Alert';

export interface AlertMessage {
  id: string;
  severity: AlertColor;
  title?: string;
  text: ReactNode | string;
  canClose?: boolean;
  onClose?: () => void;
}

export interface AlertsContext {
  messages: AlertMessage[];
  addMessage: (message: Omit<AlertMessage, 'id'>) => string;
  closeMessage: (id: string) => void;
  clearMessages: () => void;
  addManyMessages: (messages: Omit<AlertMessage, 'id'>[]) => string[];
}

const AlertsCtx = createContext<AlertsContext>({
  messages: [],
  addMessage: message => '',
  closeMessage: id => {/**/},
  clearMessages: () => {/**/},
  addManyMessages: messages => [],
});

type AddAction = {
  action: 'ADD',
  message: AlertMessage;
}

type AddManyAction = {
  action: 'ADD_MANY',
  messages: AlertMessage[],
}

type CloseAction = {
  action: 'CLOSE',
  id: string;
}

type ClearAction = {
  action: 'CLEAR',
}

const reducer = (state: { messages: Record<string, AlertMessage> }, action: AddAction | CloseAction | ClearAction | AddManyAction ) => {
  switch (action.action) {
    case 'ADD_MANY':
      return {
        messages: {
          ...state.messages,
          ...action.messages.reduce((acc, curr) => ({
            ...acc,
            [curr.id]: curr,
          }), {}),
        },
      }
    case 'ADD':
      return {
        messages: {
          ...state.messages,
          [action.message.id]: action.message,
        },
      }
    case 'CLOSE':
      const newMessages = { ...state.messages };
      delete newMessages[action.id];
      return {
        messages: newMessages,
      }
    case 'CLEAR':
      return {
        messages: {},
      }
  }
}

const initialState = { messages: {} };

export const useAlerts = () => useContext(AlertsCtx);

export const AlertsProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const addMessage = useCallback((message: Omit<AlertMessage,'id'>) => {
    const id = uniqueId();
    dispatch({ action: 'ADD', message: { ...message, id } });
    return id;
  }, [dispatch]);

  const closeMessage = useCallback((id: string) => {
    dispatch({ action: 'CLOSE', id });
  }, [dispatch]);

  const addManyMessages = useCallback((messages: Omit<AlertMessage, 'id'>[]) => {
    const messagesWithId = messages.map(message => ({
      id: uniqueId(),
      ...message,
    }));
    dispatch({ action: 'ADD_MANY', messages: messagesWithId });
    return messagesWithId.map(({ id }) => id);
  }, [dispatch]);

  const clearMessages = useCallback(() => dispatch({ action: 'CLEAR' }), [dispatch]);

  const value = useMemo<AlertsContext>(() => ({
    messages: Object.values(state.messages),
    addMessage,
    closeMessage,
    clearMessages,
    addManyMessages,
  }), [state, addMessage, closeMessage, clearMessages, addManyMessages]);

  return (
    <AlertsCtx.Provider value={value}>
      {children}
    </AlertsCtx.Provider>
  )
}

