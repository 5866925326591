import { Font, StyleSheet } from '@react-pdf/renderer';
import { ClinicDataFragment, ProfessionalBasicDataFragment, SpecializationDataFragment } from '../../../generated/graphql';
import { TFunction } from 'react-i18next';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import RobotoRegular from '../../../assets/fonts/Roboto-Regular.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import RobotoLight from '../../../assets/fonts/Roboto-Light.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import RobotoBold from '../../../assets/fonts/Roboto-Bold.ttf';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import RobotoItalic from '../../../assets/fonts/Roboto-Italic.ttf';

Font.register({
  family: 'Roboto',
  fonts: [
    {
      src: RobotoRegular,
    },
    {
      src: RobotoLight,
      fontWeight: 'light',
    },
    {
      src: RobotoBold,
      fontWeight: 'bold',
    },
    {
      src: RobotoItalic,
      fontStyle: 'italic',
    },
  ],
});

export const pdfStyles = StyleSheet.create({
  bold: {
    fontWeight: 'bold',
  },
  delegationTitle: {
    borderTop: 'solid 1 px black',
    borderBottom: 'solid 1 px black',
    padding: '10px 0',
    textAlign: 'center',
  },
  delegationDescriptionRow: {
    fontSize: 12,
    marginTop: 8,
    marginBottom: 6,
  },
  delegatedRow: {
    fontSize: 12,
    marginBottom: 4,
  },
  delegationNotes: {
    fontSize: 11,
    marginVertical: 2,
  },
  delegationSign: {
    fontSize: 13,
    paddingTop: 16,
  },
  page: {
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    padding: 30,
    fontFamily: 'Roboto',
    fontSize: 16,
  },
  header: {
    padding: 10,
    paddingLeft: 0,
    paddingBottom: 20,
    display: 'flex',
    flexDirection: 'row',
  },
  headerImage: {
    width: '15%',
  },
  medicTitle: {
    paddingLeft: 20,
    fontSize: 14,
  },
  headerMedicContainer: {
    paddingTop: 0,
    paddingLeft: 20,
    paddingRight: 20,
  },
  headerMedic: {
    fontSize: 14,
    fontWeight: 'bold',
    width: '40%',
  },
  headerAgenda: {
    paddingLeft: 20,
    paddingRight: 20,
    fontSize: 14,
    fontWeight: 'bold',
  },
  specializationListContainer: {
    paddingTop: 8,
  },
  specializationList: {
    // paddingTop: 8,
    fontWeight: 'normal',
    fontSize: 10,
  },
  headerPatient: {
    paddingBottom: 8,
    fontSize: 10,
    fontWeight: 'bold',
  },
  headerPatientInfo: {
    paddingTop: 8,
    fontSize: 10,
    fontWeight: 'normal',
  },
  titleSection: {
    borderTop: 'solid 1 px black',
    borderBottom: 'solid 1 px black',
    padding: '10px 0',
    textAlign: 'center',
  },
  footerMedic: {
    fontSize: 10,
  },
  medicAddress: {
    paddingTop: 10,
  },
  sign: {
    fontSize: 14,
    paddingTop: 16,
  },
  descriptionSection: {
    padding: '20px 0 30px',
    minHeight: 100,
    fontSize: 14,
  },
  clinicRoomTitle: {
    borderTop: 'solid 1 px black',
    borderBottom: 'solid 1 px black',
    padding: '10px 0',
    fontSize: 12,
    marginBottom: '10px',
  },
  clinicSection: {
    marginBottom: '10px',
  },
  clinicEvent: {
    marginBottom: '12px',
  },
  section: {
    flex: 1,
  },
  footer: {
    borderTop: 'solid 1 px black',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  footerContacts: {
    fontSize: 10,
  },
});

export const getAddress = (professional?: ProfessionalBasicDataFragment | null) => {
  if (!professional) return null;
  if (!('address' in professional)) return null;
  if (!professional.address) return null;

  const municipality = professional.address.municipality?.name || professional.address.municipalityName;

  return `${professional.address.address} - ${municipality}`;
}

export const getContacts = (professional: ProfessionalBasicDataFragment | undefined | null, t: TFunction) => {
  if (!professional) return null;
  if (!('contacts' in professional)) return null;
  if (!professional.contacts) return null;

  return [
    professional.contacts.phone && `${t('report:pdf.phone')}: ${professional.contacts.phone}`,
    professional.contacts.fax && `${t('report:pdf.fax')}: ${professional.contacts.fax}`,
    professional.contacts.email && `${t('report:pdf.email')}: ${professional.contacts.email}`,
  ].filter(Boolean).join(' - ');
}

export const getSpecializations = (
  professional: ProfessionalBasicDataFragment & { specializations?: SpecializationDataFragment[] } | undefined | null,
) => {
  if (!professional) return null;
  if (!('specializations' in professional)) return null;
  if (!professional.specializations || professional.specializations.length === 0) return null;

  const specs = professional.specializations.map(spec => spec.name);
  return specs.join(', ');
}

export type ClinicData = Omit<ClinicDataFragment, 'rooms'> | {
  id: string;
  cap: string;
  name: string;
  phone: string;
  address: string;
  location: string;
};

export const getClinic = (clinicData: ClinicData | undefined, t: TFunction) => {
  if (!clinicData) {
    return '';
  }
  return `${t('clinic')} ${clinicData.name}`;
}

export const getClinicAddress = (clinicData: ClinicData | undefined, t: TFunction) => {
  if (!clinicData) {
    return '';
  }
  const addressString = typeof clinicData.address === 'string' ? clinicData.address : clinicData.address.address;
  const city = 'location' in clinicData ? clinicData.location : clinicData.address.municipalityName;

  return `${addressString}, ${city}`;
}
