import { ChangeEvent, FC } from 'react';
import { Typography, TypographyProps, TextFieldProps, TextField } from '@mui/material';
import { Controller, ControllerProps } from 'react-hook-form';

export type EditableInputProps = TextFieldProps & {
  editMode: boolean;
  labelProps?: TypographyProps;
  valueProps?: TypographyProps;
  hideLabel?: boolean;
}

export const EditableInput: FC<EditableInputProps> = ({ editMode = false, labelProps, valueProps, hideLabel, ...rest }) => {
  return editMode
    ? <TextField {...rest} />
    : <>
      {!hideLabel && (
        <Typography variant={'caption'} color={'textSecondary'} {...labelProps}>
          {rest.label}
        </Typography>
      )}
      <Typography variant={'body1'} {...valueProps}>
        {`${rest.value || rest.defaultValue || ''}`}
      </Typography>
    </>
};

export type ControlledEditableInputProps<TForm = any> = Pick<ControllerProps<TForm>, 'control' | 'name' | 'rules'> & EditableInputProps & {
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => any;
};

export const ControlledEditableInput: FC<ControlledEditableInputProps> = ({ control, name, rules, onChange: onParentChange, ...rest }) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { value, name, onChange } }) => (
        <EditableInput
          {...rest}
          value={value}
          name={name}
          onChange={e => onChange(onParentChange ? onParentChange(e) : e)}
        />
      )}
    />
  )
}
