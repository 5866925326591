export const REQUEST_TAB_QUERY_PARAM = 'requestTab';

export enum AgendaViewType {
  Weekly = 'weekly',
  Daily = 'daily',
  Edit = 'edit',
}

export enum UploadDestinations {
  Requests= 'requests',
  Reports = 'reports',
  Privacies = 'privacies',
  Imports = 'imports',
}

export enum NetworkTabStatus {
  Active = 'active',
  Pending = 'pending',
}

export enum PatientDetailTabStatus {
  Record = 'record',
  Invoices = 'invoices',
  Agenda = 'appointments',
  Reports = 'reports',
  Delegations = 'delegations',
}

export enum RequestTabStatus {
  Received = 'received',
  Sent = 'sent',
}

export enum RequestStatus {
  Pending = '',
  Approved = 'OK',
  Denied = 'KO',
}

export const propsAsTrue = (props: Record<string, boolean>) => {
  const toReturn = Object.keys(props)
    .reduce((acc: Record<string,true>, key: string) => {
      const value = props[key];
      acc[value ? key : ''] = true;
      return acc;
    }, {})

  delete toReturn[''];

  return toReturn;
}
