import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useProfile } from '../../contexts';
import { Box, Button, CircularProgress, Link, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { FileRejection, useDropzone } from 'react-dropzone';
import { ACCEPTED_FILES_HUMAN, canHandleFiles, convertBytesToMbsOrKbs, MAX_FILE_SIZE } from '../../utils/upload';
import { useDialog, useToast } from '@hu-care/react-ui-store';
import { useStyles } from './send-mail/send-mail-form';
import { DragOverlay } from './drag-overlay';
import { AttachFile } from '@mui/icons-material';
import { useMutation } from '@apollo/client';
import { UploadPrivacyDocument, UploadPrivacyMutation, UploadPrivacyMutationVariables } from '../../generated/graphql';
import { useHandleError } from '../../hooks/useHandleError';
import { UploadDestinations } from '../../utils/constants';
import { isValidHttpUrl } from '../../utils';

export const UploadPrivacy: FC = () => {
  const { t } = useTranslation();
  const { actingAs } = useProfile();
  const toast = useToast();
  const classes = useStyles();
  const handleError = useHandleError(false);
  const dialog = useDialog();

  const [
    uploadMutation,
    { loading },
  ] = useMutation<UploadPrivacyMutation, UploadPrivacyMutationVariables>(UploadPrivacyDocument, {
    update: (cache, mutationResult) => {
      if (!mutationResult.data?.uploadPrivacyPolicy || !actingAs) {
        return null;
      }
      cache.modify({
        id: cache.identify(actingAs),
        fields: {
          privacyPolicy: (value) => mutationResult.data?.uploadPrivacyPolicy || value,
        },
      });
    },
  });

  const [file, setFile] = useState<File | null>();
  const [link, setLink] = useState<string>('');
  const [linkError, setLinkError] = useState<string>('');

  const validateLink = useCallback(() => {
    if (link) {
      setLinkError(isValidHttpUrl(link) ? '' : t<string>('errors:invalid-url'));
    } else {
      setLinkError('');
    }
  }, [link, setLinkError, t]);

  const onUpload = useCallback(async () => {
    if (!actingAs) {
      return;
    }
    if (!file && !link) {
      return;
    }

    if (link && !isValidHttpUrl(link)) {
      validateLink();
      return;
    }

    try {
      const payload: UploadPrivacyMutationVariables = {
        medicId: actingAs.id,
      }
      if (file) {
        payload.file = {
          file,
          destination: UploadDestinations.Privacies,
        }
      } else if (link) {
        payload.link = link;
      }

      await uploadMutation({
        variables: payload,
      });
      toast.success(t('privacy-policy-uploaded'));
      dialog.close();
    } catch (e) {
      handleError(e, 'errors:cannot-upload-privacy-policy');
    }
  }, [uploadMutation, t, toast, handleError, actingAs, file, dialog, link, validateLink]);

  const onDrop = useCallback((files: File[], reject: FileRejection[]) => {
    const canHandle = canHandleFiles({
      newFiles: files,
      maxFiles: 1,
      maxFileSize: MAX_FILE_SIZE,
      reject,
      showMessage: toast.error,
      t,
    });

    if (canHandle) {
      setFile(files[0]);
    }
  }, [toast, t, setFile]);

  const { getInputProps, getRootProps, isDragActive, inputRef } = useDropzone({
    onDrop,
    noClick: true,
    multiple: false,
    maxSize: MAX_FILE_SIZE,
  });

  useEffect(() => {
    if (file) {
      setLink('');
    } else if (link) {
      setFile(null);
      if (inputRef.current) {
        inputRef.current.value = '';
      }
    }
  }, [link, setLink, file, setFile, inputRef]);

  const previewUrl = useMemo(() => file && URL.createObjectURL(file), [file]);

  if (!actingAs) {
    return null;
  }

  return (
    <div {...getRootProps({ className: classes.dragRoot })}>
      {isDragActive && <DragOverlay/>}
      <Box pb={1}>
        {'privacyPolicy' in actingAs && actingAs.privacyPolicy?.url && (
          <Box pb={2}>
            <Typography>
              <Link href={actingAs.privacyPolicy.url} target="_blank">
                {t('view-current-privacy-policy')}
              </Link>
            </Typography>
          </Box>
        )}
        <Box>
          <label htmlFor="mail-attachments">
            <Button startIcon={<AttachFile />} component="span" variant="outlined">
              {t('upload-file')}
            </Button>
            <input id="mail-attachments" {...getInputProps()} />
          </label>
          <Typography variant="caption" pl={1}>
            {
              t('max-file-size', {
                size: convertBytesToMbsOrKbs(MAX_FILE_SIZE),
                formats: ACCEPTED_FILES_HUMAN.join(', '),
              })
            }
          </Typography>
          <Box pt={2}>
            <Typography>
              {t('or-paste-external-link')}
            </Typography>
            <TextField
              fullWidth
              placeholder="http://example.com/privacy.pdf"
              value={link}
              size="small"
              onChange={e => setLink(e.target.value)}
              onBlur={validateLink}
              variant="outlined"
              error={!!linkError}
              helperText={linkError}
            />
          </Box>
          {previewUrl && (
            <Box pt={2}>
              <Typography>
                {t('preview')}:{' '}
                <Link href={previewUrl} target="_blank">
                  {file?.name}
                </Link>
              </Typography>
            </Box>
          )}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" pt={3}>
          <Button
            type="button"
          >
            {t('cancel')}
          </Button>
          <Button
            type="button"
            color="primary"
            variant="contained"
            onClick={onUpload}
            disabled={loading}
            startIcon={loading && <CircularProgress size="1rem" color="inherit"/>}
          >
            {t('save')}
          </Button>
        </Box>
      </Box>
    </div>
  )
}
