import { ActionsDial } from '../actions-dial/actions-dial';
import { FC } from 'react';
import { PatientProfessionalDataFragment } from '../../../generated/graphql';
import { usePatientActions } from '../../../hooks/patients/actions/usePatientActions';

export const PatientActionsDial: FC<{ patientProfessional: PatientProfessionalDataFragment }> = ({ patientProfessional }) => {
  const actions = usePatientActions(patientProfessional);

  return (
    <ActionsDial actions={actions}/>
  );
};
