import { asyncLoad, getRouteFactory } from '@hu-care/react-utils';
import { RoutesKeys, RouteType } from './utils';
import { getRoute } from './index';

export enum InvoiceRoutesKeys {
  list = 'list',
  detail = 'detail',
  create = 'create',
  options = 'options',
  protocols = 'protocols',
  protocolDetail = 'protocolDetail',
}

export interface InvoiceRouteType extends Omit<RouteType, 'key'> {
  key: InvoiceRoutesKeys;
}

export const invoiceRoutes: InvoiceRouteType[] = [
  {
    key: InvoiceRoutesKeys.options,
    exact: true,
    path: '/options',
    component: asyncLoad(() => import('../views/pages/invoices/invoice-options.page')),
    config: {
      title: 'invoice options',
    },
  },
  {
    key: InvoiceRoutesKeys.create,
    exact: true,
    path: '/create',
    component: asyncLoad(() => import('../views/pages/invoices/invoice-create.page')),
    config: {
      title: 'new invoice',
    },
  },
  {
    key: InvoiceRoutesKeys.protocols,
    exact: true,
    path: '/protocols',
    component: asyncLoad(() => import('../views/pages/invoices/invoices-protocols.page')),
    config: {
      title: 'protocols',
    },
  },
  {
    key: InvoiceRoutesKeys.protocolDetail,
    exact: true,
    path: '/protocols/:protocolNumberAndYear',
    component: asyncLoad(() => import('../views/pages/invoices/invoice-protocol-detail.page')),
    config: {
      title: 'invoice protocol',
    },
  },
  {
    key: InvoiceRoutesKeys.detail,
    exact: true,
    path: '/:invoiceId',
    component: asyncLoad(() => import('../views/pages/invoices/invoice-detail.page')),
    config: {
      title: 'invoice',
    },
  },
  {
    key: InvoiceRoutesKeys.list,
    exact: true,
    path: '/',
    component: asyncLoad(() => import('../views/pages/invoices/invoices-list.page')),
    config: {
      title: 'invoices',
    },
  },
];

const routeByKey = invoiceRoutes.reduce((acc, r) => {
  acc.set(r.key, r.path);
  return acc;
}, new Map<InvoiceRoutesKeys, string>());

const _getInvoiceRoute = getRouteFactory<InvoiceRoutesKeys>(routeByKey);

export const getInvoiceRoute = (key: InvoiceRoutesKeys, replaces?: Record<string, any>) => {
  const invoiceBaseRoute = getRoute(RoutesKeys.invoices);
  const invoicePath = _getInvoiceRoute(key, replaces);
  return `${invoiceBaseRoute}${invoicePath}`;
}
