import {
  InviteToOrganizationDocument as InviteDocument,
  InviteToOrganizationMutation as InviteMutation,
  InviteToOrganizationMutationVariables as InviteMutationVariables,
  OrganizationProfessionalsDataFragment,
} from '../../generated/graphql';

import { useMutation } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { useToast } from '@hu-care/react-ui-store';
import { useTranslation } from 'react-i18next';

import { useHandleError } from '../useHandleError';

export const useInviteToOrganization = (organization: OrganizationProfessionalsDataFragment) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [inviteMutation, rest] = useMutation<InviteMutation, InviteMutationVariables>(InviteDocument);
  const handleError = useHandleError(true);

  const onInvite = useCallback(async (email: string, role: string) => {
    try {
      const result = await inviteMutation({
        variables: {
          email,
          role,
          orgId: organization.id,
        },
      });

      toast.success(t('invite:success-invite-to-organization', { role: t(`invite:roles.${role}`) }));

      return result.data?.inviteToOrganization;
    } catch (e) {
      handleError(e, 'errors:cannot-invite-to-organization');
    }
  }, [organization, handleError, t, toast, inviteMutation]);

  return useMemo(() => ({
    onInvite,
    ...rest,
  }), [
    onInvite,
    rest,
  ]);
}
