import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControl, Grid, Icon, MenuItem, Select, SelectChangeEvent, Theme, Typography } from '@mui/material';
import { SupportedProfileType, useProfile } from '../../../contexts/profile.context';
import { getProfessionalPrefix, getProfileColor, getProfileIcon } from '../../../utils/profiles';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { makeSxProps } from '../../../themes/sx';

const sxProps = makeSxProps({
  select: {
    height: 32,
    '&:before': {
      borderBottom: '0px solid white !important',
    },
    fontWeight: theme => theme.typography.fontWeightBold,
    textTransform: 'capitalize',
    color: theme => theme.palette.text.secondary,
  },
  listItemIcon: {
    minWidth: 0,
    paddingRight: 4,
  },
  profileIcon: {
    height: 20,
    width: 20,
    paddingRight: 2,
    fill: (theme: Theme) => theme.palette.text.secondary,
  },
  icon: {
    fill: (theme: Theme) => theme.palette.text.secondary,
  },
  renderedName: {
    maxWidth: '30vw',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

type ImpersonateSelectorProps = {
  options: SupportedProfileType[];
}

export const ImpersonateSelector: FC<ImpersonateSelectorProps> = ({ options }) => {
  const { impersonatedProfile, setImpersonatedProfile } = useProfile();
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const getProfileIndex = useCallback(() => {
    return options.findIndex(p => p.id === impersonatedProfile?.id)
  }, [impersonatedProfile, options]);

  const [profileIndex, setProfileIndex] = useState(getProfileIndex());

  useEffect(() => {
    const newIndex = getProfileIndex();
    if (newIndex !== profileIndex) {
      setProfileIndex(newIndex);
    }
  }, [impersonatedProfile, profileIndex, setProfileIndex, getProfileIndex]);

  const handleChange = useCallback((event: SelectChangeEvent<any>) => {
    const idx = event.target.value as number;
    setProfileIndex(idx);

    const newProfile = options[idx];
    setImpersonatedProfile(newProfile || null);
  }, [options, setImpersonatedProfile]);

  return (
    <FormControl>
      <Select
        variant="outlined"
        sx={sxProps.select}
        value={profileIndex}
        onChange={handleChange}
        renderValue={value => {
          const profile = options[value as number];
          if (!profile) {
            return null;
          }
          const ProfileIcon = getProfileIcon(profile);

          return (
            <Grid container alignItems="center">
              <Grid item display="flex" alignItems="center">
                <Icon sx={sxProps.listItemIcon}>
                  <ProfileIcon style={{ ...sxProps.profileIcon as any, fill: getProfileColor(profile) }} />
                </Icon>
              </Grid>
              <Grid item>
                <Typography sx={sxProps.renderedName}>
                  {`${getProfessionalPrefix(profile, t)}${
                    isMobile ? `${profile.name[0]}.` : profile.name
                  } ${profile.surname} `}
                </Typography>
              </Grid>
            </Grid>
          )
        }}
      >
        {options.map((profile, i) => {
          const ProfileIcon = getProfileIcon(profile);

          return <MenuItem key={profile.id} value={i}>
            <Grid container alignItems="center">
              <Grid item>
                <Icon sx={sxProps.listItemIcon}>
                  <ProfileIcon style={{ ...sxProps.profileIcon as any, fill: getProfileColor(profile) }} />
                </Icon>
              </Grid>
              <Grid item>
                <Typography>
                  {`${getProfessionalPrefix(profile, t)}${profile.name} ${profile.surname} `}
                </Typography>
              </Grid>
            </Grid>
          </MenuItem>
        })}
      </Select>
    </FormControl>
  )
}
