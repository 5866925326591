import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useMountedState } from 'react-use';

import {
  AdministratorDataFragment,
  NetworkStatus,
  OrganizationDataFragment,
  PaymentType,
  ProfessionalDataFragment,
  AssistantDataFragment,
} from '../generated/graphql';

import { useRoleTheme } from '../themes';
import { useProfessionalProfiles } from '../hooks/useProfessionalProfiles';
import { useCurrentProfessional } from '../hooks/useProfessional';
import { isAssistant, isMedic, isOperator, isOrganizationSa, professionalHasCompletedProfile } from '../utils/profiles';
import { useLocation } from 'react-router-dom';
import { getRoute, RoutesKeys } from '../routes';
import { useRedirect } from '../hooks/useRedirect';
import { useManagedOrganizations } from '../hooks/useManagedOrganizations';
import { currentProfessionalIdVar } from '../apollo/links';
import { useAlerts } from './alerts.context';
import { format } from '@hu-care/react-utils';
import { useTranslation } from 'react-i18next';
import { isFuture } from 'date-fns';

import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useManageSubscription } from '../hooks/useManageSubscription';
import { GA } from '../utils/ga';

export type SupportedProfileType = ProfessionalDataFragment;

type ProfileContextType = {
  // Current selected profile
  selectedProfile: SupportedProfileType | null;
  setSelectedProfile: Dispatch<SetStateAction<SupportedProfileType | null>>;
  // Current impersonated profile
  impersonatedProfile: SupportedProfileType | null;
  setImpersonatedProfile: Dispatch<SetStateAction<SupportedProfileType | null>>;
  // All Selectable profiles
  availableProfiles: SupportedProfileType[];
  // All available manageable organizations
  availableOrganizations: OrganizationDataFragment[];
  // All impersonatable profiles
  availableImpersonate: SupportedProfileType[];
  allImpersonatable: SupportedProfileType[];
  // impersonated profile if any, otherwise selectedProfile if any
  actingAs: NonNullable<ReturnType<typeof useCurrentProfessional>['professional']> | null;
  // current user available medic profile (if present)
  medicProfile: ProfessionalDataFragment | null;
  availableAdministrator: AdministratorDataFragment | null;
  isImpersonating: boolean;
  loading: boolean;
  orgAcceptableProfile?: SupportedProfileType;
}

const ProfileContext = createContext<ProfileContextType>({
  selectedProfile: null,
  setSelectedProfile: () => undefined,
  impersonatedProfile: null,
  setImpersonatedProfile: () => undefined,
  availableProfiles: [],
  availableOrganizations: [],
  availableImpersonate: [],
  allImpersonatable: [],
  availableAdministrator: null,
  actingAs: null,
  medicProfile: null,
  isImpersonating: false,
  loading: false,
  orgAcceptableProfile: undefined,
});

const LAST_PROFILE_USED_KEY = '@medic:lastProfileUsed';
const LAST_PROFILE_IMPERSONATED_KEY = '@medic:lastProfileImpersonated';

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.secondary.main,
    cursor: 'pointer',
    fontWeight: theme.typography.fontWeightMedium,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export const ProfileProvider: FC = ({ children }) => {
  const classes = useStyles();
  const [selectedProfile, setSelectedProfile] = useState<SupportedProfileType | null>(null);
  const [impersonatedProfile, setImpersonatedProfile] = useState<SupportedProfileType | null>(null);
  const redirect = useRedirect();
  const { addMessage, clearMessages } = useAlerts();
  const { t } = useTranslation();

  useEffect(() => {
    if (selectedProfile) {
      currentProfessionalIdVar(selectedProfile.id);
    } else {
      currentProfessionalIdVar(null);
    }
  }, [selectedProfile]);

  const { data: professionalData, loading: professionalLoading } = useProfessionalProfiles();
  const { organizations, loading: organizationsLoading } = useManagedOrganizations();
  const loading = professionalLoading || organizationsLoading;

  const availableProfiles = useMemo(() => {
    if (loading) return [];
    return [
      professionalData?.medicProfile,
      professionalData?.assistantProfile,
      professionalData?.operatorProfile,
    ].filter(Boolean) as SupportedProfileType[]
  }, [professionalData, loading]);

  const orgAcceptableProfile = useMemo(() => {
    return [...availableProfiles]
      .sort(p => isMedic(p) ? -1 : 1)
      .find(p => isMedic(p) || isOperator(p));
  }, [availableProfiles]);

  const availableAdministrator = professionalData.administratorProfile as AdministratorDataFragment || null;

  const availableOrganizations = useMemo(() => {
    if (loading) return [];
    return (organizations || []).map(op => op.organization);
  }, [loading, organizations]);

  const { setRole } = useRoleTheme();
  const { pathname } = useLocation();

  const { professional, loading: pLoading } = useCurrentProfessional(impersonatedProfile?.id || selectedProfile?.id);

  const allImpersonatable = useMemo(() => {
    if (selectedProfile && isAssistant(selectedProfile) && selectedProfile.networks) {
      return (selectedProfile as AssistantDataFragment).networks
        .filter(n => n.status === NetworkStatus.Approved && n.canImpersonate)
        .map(n => n.inverse) as SupportedProfileType[]
    } else {
      return [] as SupportedProfileType[];
    }
  }, [selectedProfile]);

  const availableImpersonate = useMemo(() => {
    return allImpersonatable
      .filter(p => {
        return (
          ('hasActiveLicense' in p
            && p.hasActiveLicense
            // TODO fix this with local checks
            // && professionalHasCompletedProfile(p as ProfessionalDataFragment)
          )
          || isOrganizationSa(p)
        )
      }) as SupportedProfileType[];
  }, [allImpersonatable]);

  const isImpersonating = useMemo(() => {
    return !!impersonatedProfile && impersonatedProfile.id !== selectedProfile?.id;
  }, [selectedProfile, impersonatedProfile]);

  useEffect(() => {
    GA.set({ dimension1: professional?.id });
    GA.set({ dimension2: professional?.role });
    GA.set({ dimension3: isImpersonating });
    GA.set({ dimension4: isImpersonating ? selectedProfile?.id : undefined });
    GA.set({ dimension5: isImpersonating ? selectedProfile?.role : undefined });
  }, [professional, selectedProfile, isImpersonating]);

  const value = useMemo(() => ({
    selectedProfile,
    setSelectedProfile,
    availableProfiles,
    impersonatedProfile,
    setImpersonatedProfile,
    availableImpersonate,
    allImpersonatable,
    actingAs: professional || null,
    isImpersonating,
    loading: loading || pLoading,
    medicProfile: availableProfiles.find(f => isMedic(f)) || null,
    availableOrganizations,
    availableAdministrator,
    orgAcceptableProfile,
  }), [
    selectedProfile,
    setSelectedProfile,
    isImpersonating,
    availableProfiles,
    setImpersonatedProfile,
    availableImpersonate,
    allImpersonatable,
    loading,
    professional,
    impersonatedProfile,
    pLoading,
    availableOrganizations,
    availableAdministrator,
    orgAcceptableProfile,
  ]);

  const isMounted = useMountedState();

  useEffect(() => {
    if (loading || !isMounted()) return;

    if (!selectedProfile) {
      // if last profile id was saved in localStorage, try to retrieve it
      const lastProfileId = localStorage.getItem(LAST_PROFILE_USED_KEY);

      const selectableProfiles = [
        ...availableProfiles,
        ...availableOrganizations.flatMap(o => o.serviceAccounts),
      ];

      const profileIndex = selectableProfiles.findIndex(p => p.id === lastProfileId);

      if (profileIndex !== -1) {
        setSelectedProfile(selectableProfiles[profileIndex] as any);
      } else {
        setTimeout(() => {
          const mustRedirect = (
            !loading
            && isMounted()
            && (availableProfiles.length === 0 || !selectedProfile)
          );
          if (mustRedirect) {
            redirect(getRoute(RoutesKeys.profiles), [
              RoutesKeys.createProfile,
              RoutesKeys.payment,
              RoutesKeys.paymentSuccess,
              RoutesKeys.professionalRegister,
              RoutesKeys.organization,
              RoutesKeys.paymentRenew,
            ]);
          }
        });
      }
    }
  }, [
    availableProfiles,
    redirect,
    isMounted,
    selectedProfile,
    loading,
    setSelectedProfile,
    availableOrganizations,
  ]);

  useEffect(() => {
    if (selectedProfile) {
      localStorage.setItem(LAST_PROFILE_USED_KEY, selectedProfile.id);
    }
  }, [selectedProfile]);

  // this hook resets impersonated profile on selectedProfile change
  useEffect(() => {
    if (selectedProfile) {
      setImpersonatedProfile(null);
    }
  }, [selectedProfile, setImpersonatedProfile]);

  // beware: the above hook must be kept before this one
  useEffect(() => {
    if (selectedProfile && availableImpersonate.length) {

      // if last profile id was saved in localStorage, try to retrieve it
      const lastProfileId = localStorage.getItem(LAST_PROFILE_IMPERSONATED_KEY);

      if (lastProfileId !== null) {
        const profileIndexFound = availableImpersonate.findIndex(p => p.id === lastProfileId);

        if (profileIndexFound > -1) {
          setImpersonatedProfile(availableImpersonate[profileIndexFound] as any);
        }
      }
    }
  }, [selectedProfile, availableImpersonate, setImpersonatedProfile]);

  useEffect(() => {
    if (selectedProfile) {
      if (impersonatedProfile) {
        localStorage.setItem(LAST_PROFILE_IMPERSONATED_KEY, impersonatedProfile.id);
      } else {
        localStorage.removeItem(LAST_PROFILE_IMPERSONATED_KEY);
      }
    }
  }, [selectedProfile, impersonatedProfile]);

  useEffect(() => {
    setRole(professional?.role);
  }, [professional, setRole]);

  useEffect(() => {
    if (!professional) return;

    const mustPay = (isMedic(professional) || isOperator(professional))
      && !professional.hasActiveLicense
      && !process.env.REACT_APP_SKIP_LICENSE;

    if (mustPay) {
      let newPath: string;
      if (professional.licenses.length === 0) {
        newPath = getRoute(RoutesKeys.payment, { professionalId: professional.id });
      } else {
        newPath = getRoute(RoutesKeys.paymentRenew, { professionalId: professional.id });
      }
      return redirect(newPath, [RoutesKeys.profiles, RoutesKeys.createProfile, RoutesKeys.paymentSuccess]);
    }

    const mustCompleteDetails = !professionalHasCompletedProfile(professional);
    if (mustCompleteDetails && !isImpersonating) {
      const newPath = getRoute(RoutesKeys.professionalRegister, { medicId: professional.id });
      redirect(newPath);
    }
  }, [professional, isImpersonating, redirect, pathname]);

  const { redirectToStripe } = useManageSubscription();

  useEffect(() => {
    if (!professional) {
      return;
    }

    if ((isMedic(professional) || isOperator(professional)) && !isImpersonating) {
      if (!professional.activeLicense) {
        return;
      }
      const hasTrial = professional.activeLicense.transactionType === PaymentType.Stripe
        && professional.activeLicense.transactionData.trialEnd;

      if (hasTrial) {
        let trialEndN = professional.activeLicense.transactionData.trialEnd.toString();
        const refDate = Date.now().toString();

        if (trialEndN.length < refDate.length) {
          trialEndN += '000';
        }

        const trialEnd = new Date(parseInt(trialEndN));

        if (isFuture(trialEnd)) {
          addMessage({
            severity: 'warning',
            canClose: true,
            text: <Box display="flex">
              <Box pr={0.5}>
                <Typography>
                  {t('trial-about-to-expire', { trialEnd: format(trialEnd, 'dd/MM/yyyy') })}
                </Typography>
              </Box>
              <Typography
                className={classes.link}
                onClick={() => {
                  if (professional.activeLicense)
                  redirectToStripe({
                    professionalId: professional.id,
                    licenseId: professional.activeLicense.id,
                    returnUrl: window.location.href,
                  })
                }}>
                  {t('cancel-subscription')}
              </Typography>
            </Box>,
          });
        }
      }
    }

    return () => {
      clearMessages();
    }
  }, [professional, redirectToStripe, isImpersonating, classes, addMessage, clearMessages, t]);

  return (
    <ProfileContext.Provider value={value}>
      {children}
    </ProfileContext.Provider>
  )
};

export const useProfile = () => useContext(ProfileContext);
