import { onError } from '@apollo/client/link/error';
import { fromPromise } from '@apollo/client';

let isRefreshing = false;
let pendingRequests: Function[] = [];

let count = 0;
const MAX_TRIES = 2;

const setIsRefreshing = (value: boolean) => isRefreshing = value;

const addPendingRequest = (pendingRequest: Function) => {
  pendingRequests.push(pendingRequest);
};

const resolvePendingRequests = () => {
  pendingRequests.map(cb => cb());
  pendingRequests = [];
};

/**
 * Handle Authentication errors
 * If the error is UNAUTHENTICATED, try to get a new token from the current refresh token
 * and repeat the call
 * @param refreshToken
 * @param onRefreshFail
 */
export function errorLink(
  refreshToken: () => Promise<any>,
  onRefreshFail: (err?: Error) => any,
  onNetworkError?: (err: Error) => any,
) {
  return onError(e => {
    const { graphQLErrors, operation, forward, networkError } = e;

    if (networkError && onNetworkError) {
      onNetworkError(networkError);
    }

    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        const isUnauthenticatedPhp = /^004 /.test((err as any).debugMessage);
        const canProceed = err.extensions?.code || isUnauthenticatedPhp;

        if (!canProceed) {
          continue;
        }

        // It is a error we can handle
        const isUnauthenticated = err.extensions!.code === 'UNAUTHENTICATED'
          || isUnauthenticatedPhp;

        if (isUnauthenticated) {
          // Too many tries already, break
          if (count >= MAX_TRIES) {
            onRefreshFail();
            return;
          }
          // No ongoing requests found, create a new one
          if (!isRefreshing) {
            setIsRefreshing(true);
            // Increment tries count
            count++;
            return fromPromise(
              // Try to refresh the token
              refreshToken()
              // eslint-disable-next-line no-loop-func
                .then((res) => {
                  return new Promise<any>(resolve => {
                    setTimeout(() => {
                      count = 0;
                      resolve(res);
                    });
                  });
                })
              // An error occurred, ignore, when count reaches the max it will flushed
                .catch((err) => {
                  resolvePendingRequests();
                  setIsRefreshing(false);

                  onRefreshFail(err);

                  return forward(operation);
                }),
            ).flatMap((r) => {
              resolvePendingRequests();
              setIsRefreshing(false);

              return forward(operation);
            });
          } else {
            // A request is already in process,
            // queue this operation to be resolved when the refresh token request fulfills
            return fromPromise(
              new Promise<void>(resolve => {
                addPendingRequest(() => resolve());
              }),
            ).flatMap(() => {
              return forward(operation);
            })
          }
        }
      }
    }
  });
}
