import { useCallback, useMemo, useState } from 'react';
import axios from 'axios';
import { useAuth } from '../contexts/auth.context';
import { useHandleError } from './useHandleError';

export type PaymentsStripeManageProps = {
  licenseId: string;
  professionalId: string;
  returnUrl: string;
}

export const useManageSubscription = () => {
  const { token } = useAuth();
  const [loading, setLoading] = useState(false);
  const handleError = useHandleError(false)

  const redirectToStripe = useCallback(async (body: PaymentsStripeManageProps) => {
    try {
      const url = `${process.env.REACT_APP_API_ENDPOINT}/payments/stripe/manage`;
      const config = (process.env.NODE_ENV === 'development' && window.userId)
        ? {
          headers: {
            userId: window.userId,
          },
        }
        : {
          headers: {
            Authorization: `Bearer ${token()?.accessToken}`,
          },
        };
      setLoading(true);
      const res = await axios.post(url, body, config);
      const { returnUrl } = res.data;
      window.location.href = returnUrl;
    } catch (e) {
      handleError(e, 'errors:unknown-error');
    } finally {
      setLoading(false);
    }
  }, [token, handleError, setLoading]);

  return useMemo(() => ({
    loading,
    redirectToStripe,
  }), [
    redirectToStripe,
    loading,
  ]);
}
