import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { ProfessionalProfilesQuery, ProfessionalProfilesDocument } from '../generated/graphql';
import { isAdministrator, isAssistant, isMedic, isOperator } from '../utils/profiles';

export const useProfessionalProfiles = () => {
  const {
    data,
    loading,
    refetch,
    error,
  } = useQuery<ProfessionalProfilesQuery>(ProfessionalProfilesDocument, {
    fetchPolicy: 'network-only',
  });

  if (error) {
    throw error;
  }

  return useMemo(() => ({
    data: {
      medicProfile: data?.userProfiles.find(isMedic),
      assistantProfile: data?.userProfiles.find(isAssistant),
      operatorProfile: data?.userProfiles.find(isOperator),
      administratorProfile: data?.userProfiles.find(isAdministrator),
    },
    loading,
    refetch,
  }), [
    data,
    loading,
    refetch,
  ]);
}
