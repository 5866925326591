import { useProfile } from '../../contexts/profile.context';
import { useMutation } from '@apollo/client';
import {
  InvitePatientDocument,
  InvitePatientMutation,
  InvitePatientMutationVariables,
  PatientProfessionalDataFragment,
} from '../../generated/graphql';
import { useHandleError } from '../useHandleError';
import { useTranslation } from 'react-i18next';
import { useToast } from '@hu-care/react-ui-store';
import { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { GA } from '../../utils/ga';

export interface InvitePatientForm {
  email: string;
}

export const useInvitePatient = (patientProfessional: PatientProfessionalDataFragment) => {
  const { actingAs } = useProfile();
  const handleError = useHandleError(false);
  const { t } = useTranslation();
  const toast = useToast();

  const form = useForm<InvitePatientForm>({
    defaultValues: {
      email: patientProfessional.email || '',
    },
  });

  const [
    inviteMutation,
    { loading },
  ] = useMutation<InvitePatientMutation, InvitePatientMutationVariables>(InvitePatientDocument);

  const onInvite = useCallback(async (data: InvitePatientForm) => {
    if (!actingAs) {
      return null;
    }
    try {
      const result = await inviteMutation({
        variables: {
          ...data,
          patientProfessionalId: patientProfessional.id,
          medicId: actingAs.id,
        },
      });
      toast.success(t('invite:success-patient-invite'));

      try {
        GA.event({
          category: 'patient-professionals',
          action: 'invite-patient',
        });
      } catch (e) {
        //
      }

      return result.data?.invitePatient.id;
    } catch (e) {
      handleError(e, 'errors:cannot-invite-patient');
    }
  }, [inviteMutation, handleError, t, toast, actingAs, patientProfessional]);

  return {
    loading,
    onInvite,
    ...form,
  }
}
