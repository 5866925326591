import { FC, useMemo, useState } from 'react';
import { SpeedDial, SpeedDialAction, SpeedDialIcon, Box } from '@mui/material';
import { Close, MoreVert } from '@mui/icons-material';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { Grid, IconButton, Tooltip, Button } from '@mui/material';
import { makeSxProps } from '../../../themes/sx';

const sxProps = makeSxProps({
  speedDialMobile: {
    '& .MuiSpeedDial-actions': {
      paddingRight: theme => theme.spacing(4),
    },
  },
  speedDial: {
    position: 'absolute',
    right: 0,
    '& .MuiSpeedDial-fab': {
      boxShadow: 'none',
      background: 'none',
      color: 'grey',
    },
  },
  root: {
    position: 'relative',
    bottom: 30,
  },
  dialAction: {
    marginLeft: theme => theme.spacing(0.5),
    marginRight: theme => theme.spacing(0.5),
  },
});

export interface Action {
  icon: JSX.Element;
  name: any;
  callback: () => void;
  disabled?: boolean;
}

export interface ActionsDialProps {
  collapsible?: Boolean;
  collapsed?: Boolean;
  alwaysExpanded?: Boolean;
  disabled?: Boolean;
  actions: Action[];
  expandedApprove?: Boolean
}

export const ActionsDial: FC<ActionsDialProps> = ({ actions: rawActions, alwaysExpanded, collapsible, collapsed, disabled, expandedApprove }) => {
  const [open, setOpen] = useState(false);
  const isMobile = useIsMobile();

  const handleClose = () => {
    setOpen(false);
  };

  const mustBeExpanded = useMemo(() => (
    alwaysExpanded || (!collapsed && (rawActions.length < 2 || (!collapsible && !isMobile)))
  ), [collapsed, rawActions, isMobile, collapsible, alwaysExpanded]);

  const actions = useMemo(() => {
    if (mustBeExpanded) return rawActions;
    else return rawActions.reverse();
  }, [rawActions, mustBeExpanded]);

  if (mustBeExpanded) {
    return (
      <Grid container>
        {actions.map((action, i) => {
          if (action.name === 'Approva' && expandedApprove) {
            return <Grid item key={i} ml={1}>
              <Button
                disabled={Boolean(disabled) || action.disabled}
                onClick={action.callback}
                variant="contained"
                color="primary"
                startIcon={action.icon}
              >
                { action.name }
              </Button>
            </Grid>
          }
          return <Grid item key={i}>
            <Tooltip placement="top" title={action.name}>
              <span>
                <IconButton disabled={Boolean(disabled) || action.disabled} onClick={action.callback}>
                  {action.icon}
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        })}
      </Grid>
    )
  }

  return (
    <Box sx={sxProps.root}>
      <SpeedDial
        FabProps={{
          size: 'small',
        }}
        ariaLabel="actions"
        sx={{
          ...sxProps.speedDial,
          ...isMobile && sxProps.speedDialMobile,
        } as any}
        hidden={actions.length === 0}
        icon={<SpeedDialIcon icon={<MoreVert />}
        openIcon={<Close />} />}
        onClose={handleClose}
        onOpen={(_, reason) => {
          if (reason === 'toggle') {
            setOpen(true);
          }
        }}
        open={open}
        direction={'left'}
      >
        {actions.map((action, i) => (
          <SpeedDialAction
            key={i}
            icon={action.icon}
            sx={sxProps.dialAction}
            tooltipTitle={action.name}
            tooltipPlacement="top"
            onClick={() => {
              action.callback();
              handleClose();
            }}
          />
        ))}
      </SpeedDial>
    </Box>
  );
};
