import { FC, useCallback } from 'react';
import { InviteDataFragment, InviteType, ProfessionalBasicDataFragment } from '../../../generated/graphql';
import { Box, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Tooltip, Skeleton } from '@mui/material';
import { arrayOfLength } from '../../../utils';
import { getProfessionalFullName } from '../../../utils/profiles';
import { TFunction, useTranslation } from 'react-i18next';
import { CheckCircleOutline } from '@mui/icons-material';
import { useAcceptAssistantInvite } from '../../../hooks/invite/useAcceptAssistantInvite';
import { useAcceptOrganizationInvite } from '../../../hooks/invite/useAcceptOrganizationInvite';

export const getPrimary = (invite: InviteDataFragment, t: TFunction) => {
  const inviter = getProfessionalFullName(invite.inviter, t);
  return invite.type === InviteType.Assistant
    ? t('invite:invited-as-assistant', { inviter })
    : t('invite:invited-as-organization', { inviter, role: t(`invite:roles.${invite.inviteRole}`) });
}

export const PendingInvitesList: FC<{
  invites: InviteDataFragment[];
  loading: boolean;
  orgAcceptableProfile?: ProfessionalBasicDataFragment;
}> = ({ invites, loading, orgAcceptableProfile }) => {
  const { t } = useTranslation();
  const { loading: aLoading, acceptInvite: acceptAssistantInvite } = useAcceptAssistantInvite();
  const { loading: oLoading, acceptInvite: acceptOrgInvite } = useAcceptOrganizationInvite();

  const acceptInvite = useCallback((invite: InviteDataFragment) => {
    if (invite.type === InviteType.Assistant) {
      return acceptAssistantInvite(invite.id);
    }
    if (orgAcceptableProfile) {
      return acceptOrgInvite(invite.id, orgAcceptableProfile.id);
    }
  }, [orgAcceptableProfile, acceptAssistantInvite, acceptOrgInvite]);

  return (
    <Box p={2}>
      <List>
        {loading ? arrayOfLength(3).map(i => (
          <ListItem key={i} divider>
            <ListItemText
              primary={<Skeleton variant="text" width="60px"/>}
            />
          </ListItem>
        )) : invites.map(invite => (
          <ListItem key={invite.id} divider>
            <ListItemText
              primary={<div dangerouslySetInnerHTML={{ __html: getPrimary(invite, t) }} />}
            />
            <ListItemSecondaryAction>
              <Tooltip title={t<string>('accept')}>
                <IconButton
                  disabled={aLoading || oLoading}
                  onClick={() => acceptInvite(invite)}
                >
                  <CheckCircleOutline />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}
