import { FC } from 'react';
import { Document, Page, Text, View, Image } from '@react-pdf/renderer';
import { ReportSharedWithDataFragment } from '../../../generated/graphql';
import { getPatientFullName, getProfessionalFullName, isAdministrator, isAssistant } from '../../../utils/profiles';
import { useTranslation } from 'react-i18next';
import { formatDate } from '@hu-care/react-utils';
import Esculapio from '../../../assets/esculapio.png';
import { pdfStyles as styles, getContacts, getAddress, getClinic, getClinicAddress } from '../pdf/common';
import { htmlToPdf } from '../../../utils/pdf';
import { useMemo } from 'react';
import { cloneElement } from 'react';
import { splitStringIntoChunks } from '../../../utils/strings';

export interface ReportPdfProps {
  report: ReportSharedWithDataFragment['report'];
}

const medicHeaderSize = 50;
const patientHeaderSize = 40;

export const ReportPdf: FC<ReportPdfProps> = ({ report }) => {
  const { t } = useTranslation();
  const medicName = report.professional ? getProfessionalFullName(report.professional, t) : report.medicName;

  const medicSpecializations = report.professional && 'specializations' in report.professional
    ? splitStringIntoChunks(
        `${t('specialized-in')}: ${report.professional.specializations.map(s => s.name).join(', ')}`,
        medicHeaderSize,
      )
    : null;

  const address = getAddress(report.professional);
  const contacts = getContacts(report.professional, t);

  const clinic = getClinic(report.meta?.clinic, t);
  const clinicAddress = getClinicAddress(report.meta?.clinic, t);

  const patientAddressLine1 = report.patient.address?.address;
  const patientAddressLine2 = report.patient?.address && [
    report.patient.address?.cap && `${report.patient.address?.cap} - `,
    report.patient.address?.municipalityName && `${report.patient.address?.municipalityName} `,
    report.patient.address?.districtName && `(${report.patient.address?.districtName})`,
  ].filter(Boolean).join('');

  const headers = useMemo(() => {
    let toReturn: (JSX.Element | null)[] | undefined;
    // the professional should never be Assistant btw
    if (report.professional && !isAssistant(report.professional) && !isAdministrator(report.professional)) {
      toReturn = report.professional?.header?.split('|').flatMap(txt => htmlToPdf(txt, medicHeaderSize));
    }
    return toReturn;
  }, [report]);

  const footer = useMemo(() => {
    if (report.professional && !isAssistant(report.professional) && !isAdministrator(report.professional)) {
      return (report.professional?.footer?.split('|') || [])
        .flatMap(txt => htmlToPdf(txt));
    }
  }, [report]);

  return (
    <Document
      title={report.title}
      creator={report.createdBy}
    >
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.headerImage}>
            <Image src={report.professional?.logo?.url
              ? { uri: report.professional.logo.url, method: 'GET', body: null, headers: {} }
              : Esculapio
            } />
          </View>

          <View>
            <Text style={styles.medicTitle}>{medicName}</Text>

            <View style={{
              display: 'flex',
              flexDirection: 'row',
            }}>
              <View style={styles.headerMedicContainer}>
                <View style={styles.specializationListContainer}>
                  {medicSpecializations ? (
                    medicSpecializations.map(
                      (chunk, idx) => <Text key={idx} style={styles.specializationList}>
                        {chunk}
                      </Text>,
                    )
                  ) : null}

                  <View style={styles.specializationListContainer}>
                    {
                      headers
                        ? headers.map(
                            (head, idx) => (
                              head && cloneElement(head, { key: idx, style: styles.specializationList })
                            ),
                          )
                        : '-'
                    }
                  </View>
                </View>
              </View>

              <View style={styles.headerPatient}>
                <Text>{formatDate(report.reportDate)}</Text>
                <View style={styles.headerPatientInfo}>
                  <Text>{t('report:pdf.patient')}:</Text>
                  <Text style={styles.bold}>
                    {getPatientFullName(report.patient)} ({report.patient.taxId})
                  </Text>
                  {patientAddressLine1 ? (
                    splitStringIntoChunks(patientAddressLine1, medicHeaderSize).map((chunk, idx) => (
                      <Text key={idx}>{chunk}</Text>
                    ))
                  ) : null}
                  {patientAddressLine2 ? (
                    splitStringIntoChunks(patientAddressLine2, medicHeaderSize).map((chunk, idx) => (
                      <Text key={idx}>{chunk}</Text>
                    ))
                  ) : null}
                </View>
                <View style={styles.headerPatientInfo}>
                  {clinic ? splitStringIntoChunks(clinic, patientHeaderSize).map((chunk, idx) => (
                    <Text style={styles.bold} key={idx}>{chunk}</Text>
                  )) : null}
                  {clinicAddress ? splitStringIntoChunks(clinicAddress, patientHeaderSize).map((chunk, idx) => (
                    <Text key={idx}>{chunk}</Text>
                  )) : null}
                  {(!clinic && !clinicAddress) ? '-' : null}
                </View>
              </View>
            </View>
          </View>
        </View>
        <View style={styles.titleSection}>
          <Text>{report.title}</Text>
        </View>
        <View style={styles.section}>
          <View style={styles.descriptionSection}>
            {htmlToPdf(report.description || '-')}
          </View>
          <View style={styles.footerMedic}>
            <Text>{medicName}</Text>
            {address ? (
              <View style={styles.medicAddress}>
                <Text>{address}</Text>
              </View>
            ) : null}
          </View>
          <View style={styles.sign}>
            <Text>{t('report:pdf.sign-here')}_______________________________________</Text>
          </View>
        </View>
        <View style={styles.footer}>
          <View style={styles.footerContacts}>
            <Text>{contacts || '-'}</Text>
          </View>

          {footer ? (
            <View style={styles.footerContacts}>
              {footer?.map((foot, idx) => (
                typeof foot === 'string'
                  ? <Text key={idx}>{foot}</Text>
                  : foot ? cloneElement(foot, { key: idx }) : null
              ))}
            </View>
          ) : null}
        </View>
      </Page>
    </Document>
  )
}
