import {
  DeleteReportDocument,
  DeleteReportMutation,
  DeleteReportMutationVariables, ReportBasicDataFragment,
} from '../../generated/graphql';
import { useMutation } from '@apollo/client';
import { useCallback, useMemo } from 'react';
import { useHandleError } from '../useHandleError';
import { useTranslation } from 'react-i18next';
import { useToast } from '@hu-care/react-ui-store';
import { useConfirmDialog } from '../useConfirmDialog';

export const useDeleteReport = (report?: ReportBasicDataFragment) => {
  const handleError = useHandleError(true);
  const { t } = useTranslation();
  const toast = useToast();
  const dialog = useConfirmDialog();
  const [
    deleteMutation,
    { loading },
  ] = useMutation<DeleteReportMutation, DeleteReportMutationVariables>(DeleteReportDocument, {
    update: (cache, mutationResult) => {
      if (!mutationResult.data?.deleteReportAsProfessional || !report) {
        return;
      }
      cache.evict({
        id: cache.identify(report),
        broadcast: true,
      });
    },
  });

  const deleteReport = useCallback(async () => {
    if (!report) {
      return;
    }
    try {
      const result = await deleteMutation({
        variables: {
          id: report.id,
        },
      });
      toast.success(t('report:delete-report-success'));
      return result.data?.deleteReportAsProfessional;
    } catch (e) {
      handleError(e, 'errors:cannot-delete-report');
    }
  }, [deleteMutation, handleError, t, toast, report]);

  const deleteReportWithConfirm = useCallback(() => {
    if (!report) {
      return Promise.resolve();
    }
    return new Promise<void>((resolve, reject) => {
      dialog.confirm({
        title: t('report:confirm-delete', { name: report.title }),
        onConfirm: () => {
          deleteReport()
          .then(() => {
            resolve();
          })
          .catch(() => {
            reject();
          })
          .finally(() => {
            dialog.close();
          })
        },
      });
    });
  }, [deleteReport, dialog, t, report]);

  return useMemo(() => ({
    deleteReportWithConfirm,
    deleteReport,
    loading,
  }), [
    deleteReportWithConfirm,
    deleteReport,
    loading,
  ]);
}
