import { forwardRef } from 'react';

import {
  Button,
  FormGroup,
  FormControlLabel,
  Badge, Theme,
} from '@mui/material';

import { useToast } from '@hu-care/react-ui-store';

import { convertBytesToMbsOrKbs, MAX_FILE_SIZE, ACCEPTED_FILES } from '../../../utils/upload';
import { createStyles, makeStyles } from '@mui/styles';
import { AttachFile as AttachFileIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface HiddenFileInputProps {
  multiple?: boolean;
  accept?: string;
  onSelect: any;
  maxFilesNumber?: number;
  maxFileSize?: number;
}

interface FileInputIconProps extends HiddenFileInputProps {
  value: File[];
  icon?: any;
}

interface FileInputProps extends HiddenFileInputProps {
  title: string;
  value: File[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginRight: theme.spacing(1),
    },
    icon: {
      marginRight: theme.spacing(1),
    },
  }),
);

export const HiddenFileInput = forwardRef<any, HiddenFileInputProps>(({ accept, onSelect, maxFilesNumber, maxFileSize }, ref) => {
  const toast = useToast();
  const multiple = maxFilesNumber !== 1;
  const { t } = useTranslation();

  return <input
    multiple={multiple}
    ref={ref}
    onChange={e => {
      const files = Array.from(e?.target?.files || []);

      if (maxFilesNumber !== undefined && files.length > maxFilesNumber) {
        toast.error(t('errors:too-many-files', {
          maxFiles: maxFilesNumber,
        }));
        return onSelect([])
      }

      const maxSize = maxFileSize || MAX_FILE_SIZE;
      for (const file of files) {
        if (file.size > maxSize) {
          toast.error(t('errors:file-too-large', {
            filename: file.name,
            maxFileSize: convertBytesToMbsOrKbs(maxSize),
          }));
          return onSelect([])
        }
      }

      onSelect(files, e)
    }}
    style={{ display: 'none' }}
    type="file"
    accept={accept || ACCEPTED_FILES.join(',')}
  />
});

export const FileInput = forwardRef<any, FileInputProps>(({ title, value, ...rest }, ref) => {
  const classes = useStyles();
  const filesSelected = value.length

  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <HiddenFileInput ref={ref} {...rest}/>
        }
        label={
          <Button variant="outlined" color="primary" component="span">
            <AttachFileIcon className={classes.icon} /> { title } {filesSelected ? `(${filesSelected})` : ''}
          </Button>
        }
      />
    </FormGroup>
  )
});

export const FileInputIcon = forwardRef<any, FileInputIconProps>(({ value, icon, ...rest }, ref) => {
  return (
    <FormGroup row>
      <FormControlLabel
        control={
          <HiddenFileInput ref={ref} {...rest}/>
        }
        label={
          <Badge badgeContent={value.length} color="secondary">
            { icon || <AttachFileIcon /> }
          </Badge>
        }
      />
    </FormGroup>
  )
});
