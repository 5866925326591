import { PlatformAlertDataFragment } from '../generated/graphql';
import { AlertMessage } from '../contexts/alerts.context';
import { AlertColor } from '@mui/material';

export function platformAlertToAlertMessage(platformAlert: PlatformAlertDataFragment): AlertMessage {
  return {
    id: platformAlert.id,
    severity: platformAlert.severity.toLowerCase() as AlertColor,
    title: platformAlert.title || undefined,
    text: platformAlert.message,
    canClose: true,
  }
}
