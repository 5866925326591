import { FC, MouseEvent, useCallback, useState } from 'react';
import { Box, Button, IconButton, ListItemIcon, Menu, MenuItem, Theme, Typography } from '@mui/material';
import { ExitToApp, Person, Policy, VpnKey } from '@mui/icons-material';
import { useAuth } from '../../../contexts/auth.context';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { assetUrl } from '../../../utils/assets';
import { useProfile } from '../../../contexts/profile.context';
import { ProfessionalRole } from '../../../generated/graphql';
import { UserAvatar } from './user-avatar';

const useStyles = makeStyles((theme: Theme) => ({
  avatarSmall: {
    height: 32,
    width: 32,
  },
  avatar: {
    height: 52,
    width: 52,
    marginRight: 'auto',
    marginLeft: 'auto',
  },
  profileIcon: {
    color: theme.palette.background.paper,
  },
  listItemIcon: {
    minWidth: theme.spacing(5),
  },
  username: {
    paddingRight: theme.spacing(1),
    textTransform: 'capitalize',
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 16,
    color: 'white',
  },
  box: {
    '&:focus': {
      outline: 'none',
    },
  },
}));

export const UserMenu: FC = () => {
  const classes = useStyles();
  const { logout, getUser, settingsUrl } = useAuth();
  const user = getUser();
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const { selectedProfile } = useProfile();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = useCallback((e: MouseEvent) => {
    e.preventDefault();
    logout();
  }, [logout]);

  const privacyLink = selectedProfile && (selectedProfile.role !== ProfessionalRole.Assistant
    ? assetUrl('/static/T_C_Medici_Enti_Pazienti.pdf')
    : assetUrl('/static/T_C_Segretarie.pdf')
  );

  return (
    <>
      { isMobile
        ? <IconButton color="inherit" className={classes.profileIcon} onClick={handleClick}>
          <UserAvatar picture={user?.picture} name={user?.name} surname={user?.surname} size="small"/>
        </IconButton>
        : user && (
          <Button
            variant="text"
            color="inherit"
            onClick={handleClick}
            startIcon={<UserAvatar picture={user?.picture} name={user?.name} surname={user?.surname} size="small" />}
            >
            <Typography id="user_fullName" className={classes.username}>
              {user.name[0]}{'. '}{user.surname}
            </Typography>
        </Button>
        )
      }

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        autoFocus={false}
      >
        <Box
          className={classes.box}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          minWidth={100}
          p={2}
        >
          <Box pb={1}>
            <UserAvatar picture={user?.picture} name={user?.name} surname={user?.surname} size="large" />
          </Box>
          <Typography variant="h6" color="textSecondary">
            {user?.name}{' '}{user?.surname}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            {user?.email}
          </Typography>
        </Box>
        <MenuItem component={'a'} href={settingsUrl}>
          <ListItemIcon className={classes.listItemIcon}>
            <Person/>
          </ListItemIcon>
          <Typography variant="body2">{t('menu:user-profile')}</Typography>
        </MenuItem>
        <MenuItem component="a" href={assetUrl('/static/Info_privacy.pdf')} target="_blank">
          <ListItemIcon className={classes.listItemIcon}>
            <Policy/>
          </ListItemIcon>
          <Typography variant="body2">{t('privacy-policy')}</Typography>
        </MenuItem>
        {privacyLink && (
          <MenuItem
            component={'a'}
            href={privacyLink}
            target="_blank"
            onClick={handleClose}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <VpnKey/>
            </ListItemIcon>
            <Typography variant="body2">{t('privacy:tc')}</Typography>
          </MenuItem>
        )}
        <MenuItem onClick={onLogout}>
          <ListItemIcon className={classes.listItemIcon}>
            <ExitToApp/>
          </ListItemIcon>
          <Typography variant="body2">{t('menu:logout')}</Typography>
        </MenuItem>
      </Menu>
    </>
  )
}
