import { useMutation } from '@apollo/client';
import {
  ReadRequestMessageDocument,
  ReadRequestMessageMutation,
  ReadRequestMessageMutationVariables,
} from '../../generated/graphql';
import { useCallback, useMemo } from 'react';
import { useProfile } from '../../contexts/profile.context';

export const useReadRequestMessage = () => {
  const { actingAs } = useProfile();
  const [
    readMutation,
  ] = useMutation<ReadRequestMessageMutation, ReadRequestMessageMutationVariables>(ReadRequestMessageDocument);

  const readMessage = useCallback(async (id: string) => {
    if (!actingAs) {
      return;
    }
    try {
      const result = await readMutation({
        variables: {
          id,
          medicId: actingAs.id,
          readAt: new Date(),
        },
      });
      return result.data?.markRequestMessageAsReadAsProfessional;
    } catch (e) {
      console.error(e);
    }
  }, [readMutation, actingAs]);

  return useMemo(() => ({
    readMessage,
  }), [readMessage]);
}
