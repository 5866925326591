import { FC } from 'react';
import { Loading } from '@hu-care/react-layout';
import { Box } from '@mui/material';

export const CenteredLoading: FC = () => {
  return (
    <Box flex={1}>
      <Loading/>
    </Box>
  );
}
