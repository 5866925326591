import {
  MedicDataFragment,
  PatientDataFragment,
  PatientProfessionalDataFragment,
  ProfessionalBasicDataFragment,
  ProfessionalSharesType,
  UserDataFragment,
} from '../generated/graphql';
import { isPatientProfessionalShared } from '../hooks/patients/usePatientProfessionalOwner';
import { isMedic } from './profiles';

export const getFirstPatientUser = (patient?: PatientDataFragment): UserDataFragment | null => {
  return patient?.users?.length ? patient.users[0].user : null;
}

export const getPatientUsers = (patient?: PatientDataFragment): UserDataFragment[] | null => {
  return patient?.users?.length ? patient.users.map(pu => pu.user) : null;
}

export const getEmailablePatientUsers = (patient?: PatientDataFragment): UserDataFragment[] | null => {
  return getPatientUsers(patient)?.filter(u => !!u.traits.email) || null;
}

export const isSsnOrLp = (patientProfessional: PatientProfessionalDataFragment, actingAs: ProfessionalBasicDataFragment) => {
  const isPersonalMedic = patientProfessional.patient.personalMedicId === actingAs.id;

  if (!isPersonalMedic && isPatientProfessionalShared(patientProfessional, actingAs)) {
    const pShares = isMedic(actingAs) && (actingAs as MedicDataFragment).professionalsSharedWithMe;
    if (pShares) {
      const isSharedByGroupMedicine = pShares.find(s => (
        s.professional.id === patientProfessional.professionalId
          && s.type === ProfessionalSharesType.GroupMedicine
        ),
      )
      if (isSharedByGroupMedicine) return 'MDG'
    }
  }
  return (!isMedic(actingAs) || !actingAs.role)
    ? null
    : isPersonalMedic
      ? 'SSN'
      : 'LP';
}