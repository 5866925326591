import { useNetworkState } from 'react-use';
import { useSnackbar } from 'notistack';
import { useEffect, useState } from 'react';

const OFFLINE_KEY = 'OFFLINE';

export const useOfflineSnackbar = () => {
  const { online } = useNetworkState();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isOffline, setIsOffline] = useState(!online);

  useEffect(() => {
    // Show a snackbar indicating that the user is offline, if set so
    if (isOffline) {
      enqueueSnackbar('Sei offline', {
        key: OFFLINE_KEY,
        persist: true,
      });
    } else {
      // Close the snackbar if present
      closeSnackbar(OFFLINE_KEY);
    }
  }, [isOffline, enqueueSnackbar, closeSnackbar]);

  useEffect(() => {
    // If user is online, close the snackbar if present
    if (online) {
      closeSnackbar(OFFLINE_KEY);
      setIsOffline(false);
    } else {
      // Else, set the snackbar to be shown
      setIsOffline(true);
    }
  }, [online, closeSnackbar, setIsOffline]);

  return {
    isOffline,
    setIsOffline,
  }
}
