import {
  PatientProfessionalDataFragment,
  PatientProfessionalStatus,
  UpdatablePatientProfessionalStatus,
} from '../../../generated/graphql';
import { useTranslation } from 'react-i18next';
import { useSharePatientWithProfessional } from '../useSharePatientWithProfessional';
import { useChatPopup, useProfile } from '../../../contexts';
import { getFirstPatientUser } from '../../../utils/patients';
import { useUpdatePatientProfessionalStatus } from '../useUpdatePatientProfessionalStatus';
import { Action } from '../../../views/components/actions-dial/actions-dial';
import { Done, Share } from '@mui/icons-material';
import { ChatIcon, InvitedPatientIcon, MailIcon, UnarchiveIcon } from '../../../utils/icons';
import { useOpenSendMail } from './useOpenSendMail';
import { useOpenInvitePatient } from './useOpenInvitePatient';
import { canChat } from '../../../utils/profiles';
import { useUnarchivePatient } from '../useArchivePatient';
import { usePersonalMedicTransfers, useReplyToPersonalMedicTransfer } from '../../usePersonalMedicTransfers';
import { useMemo } from 'react';

export const contactableStatuses = [
  PatientProfessionalStatus.Approved,
  PatientProfessionalStatus.RequestedByPatient,
];

export const usePatientActions = (patientProfessional: PatientProfessionalDataFragment, withConfirm?: boolean) => {
  const { t } = useTranslation();

  const shareWithProfessional = useSharePatientWithProfessional(patientProfessional);

  const unarchiveConnection = useUnarchivePatient(patientProfessional);

  const openSendMail = useOpenSendMail(patientProfessional);
  const openInvitePatient = useOpenInvitePatient(patientProfessional);
  const { open: openChat } = useChatPopup();
  const { isImpersonating, actingAs } = useProfile();

  const chatEnabled = canChat(actingAs);

  const userForChat = getFirstPatientUser(patientProfessional.patient);

  const { onUpdateStatus } = useUpdatePatientProfessionalStatus(patientProfessional);
  const { pendingTransfers: personalMedicTransfers } = usePersonalMedicTransfers(
    patientProfessional?.patient.id,
  );

  const personalMedicTransfer = useMemo(() => {
    return personalMedicTransfers.length
      ? personalMedicTransfers[0]
      : null
  }, [personalMedicTransfers]);

  const {
    onAccept: onAcceptPersonalMedicTransfer,
  } = useReplyToPersonalMedicTransfer(false);

  const canUpdateStatus = patientProfessional.status === PatientProfessionalStatus.RequestedByPatient;

  const canContact = contactableStatuses.includes(patientProfessional.status);

  const actions: Action[] = [
    ...(canUpdateStatus ? [
      {
        icon: <Done/>,
        name: t('approve'),
        callback: async () => {
          await onUpdateStatus(UpdatablePatientProfessionalStatus.Approved);
          if (personalMedicTransfer) {
            onAcceptPersonalMedicTransfer(personalMedicTransfer.id);
          }
        },
      },
    ] : []),
    ...(openSendMail !== null && canContact
        ? [
          {
            icon: <MailIcon />,
            name: t('menu:mail'),
            callback: openSendMail,
          },
        ]
        : []
    ),
    ...(userForChat && !isImpersonating && canContact
        ? [{
          disabled: !chatEnabled,
          icon: <ChatIcon />,
          name: chatEnabled ? t('menu:chat') : t('chat-disabled'),
          callback: () => openChat(userForChat),
        }]
        : []
    ),
    ...(shareWithProfessional
        ? [{
          icon: <Share />,
          name: t('menu:share'),
          callback: shareWithProfessional,
        }]
        : []
    ),
    ...(openInvitePatient !== null
        ? [{
          icon: <InvitedPatientIcon/>,
          name: t('invite'),
          callback: openInvitePatient,
        }]
        : []
    ),
    ...(patientProfessional.status === PatientProfessionalStatus.Archived
      ? [{
        icon: <UnarchiveIcon />,
        name: t('patient:unarchive-connection'),
        callback: unarchiveConnection,
      }]
      : []
    ),
  ].filter(Boolean);

  return actions;
}
